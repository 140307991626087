/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from "../AppDispatcher";
import * as AppConstants from "../AppConstants";
import ProductoREST from "../api/ProductoREST";
import ErrorHandler from "../utilities/ErrorHandler";

class ProductoActions {
  listarProductos = ({ page, limit, search }, componentUID) => {
    ProductoREST.listarProductos({ page, limit, search })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.producto.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  listarAllProductos = (componentUID) => {
    ProductoREST.listarAllProductos()
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.producto.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearProducto = ({ descripcion, precio }) => {
    ProductoREST.crearProducto({ descripcion, precio })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.producto.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  actualizarProducto = ({ id, descripcion, precio }) => {
    ProductoREST.actualizarProducto({ id, descripcion, precio })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.producto.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarProducto = (id) => {
    ProductoREST.eliminarProducto(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.producto.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new ProductoActions();
