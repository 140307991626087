import GlobalContext from '../flux/GlobalContext';

const ALL_ROLES = {
  ADMIN: '9a354d12-09fe-4870-a1cc-4543d596e979',
};

const PERMISSIONS = {
  user: {
    READ: [ALL_ROLES.ADMIN],
    CREATE: [ALL_ROLES.ADMIN],
    EDIT: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
    ROLE: [ALL_ROLES.ADMIN],
    ASSIGN_ROLE: [ALL_ROLES.ADMIN],
  },
  producto: {
    READ: [ALL_ROLES.ADMIN],
    CREATE: [ALL_ROLES.ADMIN],
    EDIT: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  ubicacion: {
    READ: [ALL_ROLES.ADMIN],
  },
  provincia: {
    READ: [ALL_ROLES.ADMIN],
    CREATE: [ALL_ROLES.ADMIN],
    EDIT: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  canton: {
    READ: [ALL_ROLES.ADMIN],
    CREATE: [ALL_ROLES.ADMIN],
    EDIT: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  parroquia: {
    READ: [ALL_ROLES.ADMIN],
    CREATE: [ALL_ROLES.ADMIN],
    EDIT: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  cliente: {
    READ: [ALL_ROLES.ADMIN],
    CREATE: [ALL_ROLES.ADMIN],
    EDIT: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  empleado: {
    READ: [ALL_ROLES.ADMIN],
    CREATE: [ALL_ROLES.ADMIN],
    EDIT: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  vehiculo: {
    READ: [ALL_ROLES.ADMIN],
    CREATE: [ALL_ROLES.ADMIN],
    EDIT: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  'crear-guia': {
    READ: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  'listar-guia': {
    READ: [ALL_ROLES.ADMIN],
    EDIT: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  hruta: {
    READ: [ALL_ROLES.ADMIN],
  },
  'crear-hruta': {
    READ: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  'listar-hruta': {
    READ: [ALL_ROLES.ADMIN],
    EDIT: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  ruta: {
    READ: [ALL_ROLES.ADMIN],
    CREATE: [ALL_ROLES.ADMIN],
    EDIT: [ALL_ROLES.ADMIN],
    DELETE: [ALL_ROLES.ADMIN],
  },
  reporte: {
    READ: [ALL_ROLES.ADMIN],
  },
  'reporte-remitente': {
    READ: [ALL_ROLES.ADMIN],
  },
  'reporte-destino': {
    READ: [ALL_ROLES.ADMIN],
  },
};

export const verifyPermissionByAction = (module, _action) => {
  const permissionModule = PERMISSIONS[module] || [];
  const action = _action ? permissionModule[_action.toUpperCase()] : null;
  return action ? action.includes(GlobalContext.ROLE_ID) : false;
};
export const verifyPermissionRead = (module) => {
  const permissionModule = PERMISSIONS[module] || [];
  const action = permissionModule.READ;
  return action ? action.includes(GlobalContext.ROLE_ID) : false;
};
