/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 27th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 27th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from 'react';
import { Column } from 'primereact/components/column/Column';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Button } from 'primereact/components/button/Button';
import { RadioButton } from 'primereact/components/radiobutton/RadioButton';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import $ from 'jquery';
import _ from 'lodash';
import { Calendar } from 'primereact/components/calendar/Calendar';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { getUUID, localeString, FORMAT_DATE_CALENDAR, FORMAT_DATE_MOMENT } from '../../../components/functions/globals';
import DataTableCrudComponent from '../../../components/commons/table/DataTableCrudComponent';
import ClienteREST from '../../../flux/api/ClienteREST';
import RutaStore from '../../../flux/stores/RutaStore';
import RutaActions from '../../../flux/actions/RutaActions';
import ProductoStore from '../../../flux/stores/ProductoStore';
import ProductoActions from '../../../flux/actions/ProductoActions';
import GuiaStore from '../../../flux/stores/GuiaStore';
import GuiaActions from '../../../flux/actions/GuiaActions';
import conf, { URL_SERVER } from '../../../conf/conf';

import ErrorStore from '../../../flux/stores/ErrorStore';

export default class CrearGuiaComponent extends DataTableCrudComponent {
  uuidRuta = getUUID();

  uuidProducto = getUUID();

  sectionName = 'crear-guia';

  labelList = 'Crear Guía';

  labelNew = '';

  labelTotal = 'Guía detalle';

  classNameContentTable = 'ui-xl-10 ui-lg-12 ui-g-12 ui-md-12 ui-sm-12';

  hasPagination = true;

  hasEdit = false;

  hasSearch = false;

  hasNew = false;

  initialState() {
    let data = {
      id: null,
      fecha: moment().format(FORMAT_DATE_MOMENT),
      remitente: null,
      remitentes: [],
      destinatario: null,
      detinatarios: [],
      direccionOrigen: '',
      direccionDestino: '',
      recibeNombre: '',
      recibeCedula: '',
      recibeFecha: moment().toDate(),
      recibeTemperatura: null,
      recibeHumedad: null,

      origen: null,
      origenes: [],

      destino: null,
      destinos: [],

      producto: null,
      productos: [],

      cantidad: 0,
      valorUnitario: 0,

      isVisibleDialogGuardar: false,
      guiaId: '',
      guiaNumero: null,
      observacion: '',

      imprimirPrecio: true,
      hasDelete: true, // no funciona esa hvda de variable en el this, siempre lee los permisos de conf/security.js

      isCredito: false,
    };

    const guiaEditar = GuiaStore.getGuiaSet();
    if (guiaEditar) {
      const {
        id,
        guiaNumero,
        fecha,
        remitente,
        destinatario,
        direccionDestino,
        direccionOrigen,
        rutaDestino,
        rutaOrigen,
        guiasDetalles,
        observacion,
        recibeNombre,
        recibeCedula,
        recibeFecha,
        recibeHumedad,
        recibeTemperatura,
        credito,
      } = guiaEditar;
      this.labelList = 'Editar Guía';

      const guiasDetallesTMP = _.map(guiasDetalles, (detalleObj) => {
        const {
          // eslint-disable-next-line no-shadow
          id,
          cantidad,
          valorUnitario,
          valorUnitarioTotal,
          producto,
        } = detalleObj;
        const value = {
          ...detalleObj,
          ...{
            id,
            cantidad,
            valorUnitario,
            valorTotal: valorUnitarioTotal,
            detalle: producto.descripcion,
          },
        };
        return value;
      });

      data = {
        ...data,
        ...{
          id,
          guiaNumero,
          fecha: moment(fecha, FORMAT_DATE_MOMENT).format(FORMAT_DATE_MOMENT),
          remitente: {
            value: remitente.id,
            label: `${remitente.nombres} ${remitente.apellidos}`,
          },
          destinatario: {
            value: destinatario.id,
            label: `${destinatario.nombres} ${destinatario.apellidos}`,
          },
          direccionDestino,
          direccionOrigen,
          origen: { value: rutaOrigen.id, label: rutaOrigen.descripcion },
          destino: { value: rutaDestino.id, label: rutaDestino.descripcion },
          list: guiasDetallesTMP,
          totalRecords: guiasDetallesTMP.length > 20 ? 20 : guiasDetallesTMP.length,
          hasDelete: false,
          observacion,
          isCredito: credito,
          recibeNombre,
          recibeCedula,
          recibeHumedad,
          recibeTemperatura,
          recibeFecha: recibeFecha ? moment.unix(recibeFecha).toDate() : new Date(),
        },
      };
    }
    return data;
  }

  /**
   * no funcia la variable global de hasEdit, hasDelete, simpre toma la validacion de conf/security.js por lo cual se sobrescribio este metodo
   */
  createColumnAction() {
    const actionTemplate = (rowData) => {
      return (
        <div>
          {this.hasEdit ? (
            <Button type="button" icon="fa fa-pencil-alt" title="Editar" className="ui-button-success" onClick={() => this._onEditColumnClick(rowData)} style={{ marginRight: '5px' }} />
          ) : (
            ''
          )}
          {this.state.hasDelete ? <Button type="button" icon="fa fa-trash" title="Eliminar" className="ui-button-danger" onClick={() => this._onShowHideDialogDelete(true, rowData)} /> : ''}
        </div>
      );
    };
    return (
      <Column
        body={actionTemplate}
        style={{
          ...{ textAlign: 'center', width: '120px' },
          ...this.styleColumnAction,
        }}
      />
    );
  }

  componentDidMount() {
    super.componentDidMount();
    RutaStore.addGetRutasListener(this._onGetRutasListener, this.uuidRuta);
    ProductoStore.addGetProductosListener(this._onGetProductosListener, this.uuidProducto);
    GuiaStore.addCreateGuiaListener(this._onCreateGuiaListener);
    ErrorStore.addErrorListener(this._onErrorListerner);
    GuiaStore.addUpdateGuiaListener(this._onUpdateGuiaListener);

    RutaActions.listarAllRutas(this.uuidRuta);
    ProductoActions.listarAllProductos(this.uuidProducto);
  }

  componentWillUnmount() {
    RutaStore.removeGetRutasListener(this._onGetRutasListener, this.uuidRuta);
    ProductoStore.removeGetProductosListener(this._onGetProductosListener, this.uuidProducto);
    GuiaStore.removeCreateGuiaListener(this._onCreateGuiaListener);
    ErrorStore.removeErrorListener(this._onErrorListerner);
    GuiaStore.removeUpdateGuiaListener(this._onUpdateGuiaListener);
  }

  textQuestionDelete() {
    return `¿Estas seguro de eliminar un cantón: ${this.state.rowDelete ? `${this.state.rowDelete.cantidad} ${this.state.rowDelete.detalle}` : ''}?`;
  }

  sendDeleteOk() {
    this.setState((prevState) => {
      const newList = _.remove(prevState.list, (detalle) => {
        return detalle.id !== prevState.rowDelete.id;
      });
      return {
        list: newList,
        totalRecords: newList.length,
        search: null,
        isLoading: false,
      };
    });
    this.onSuccessDelete();
  }

  // ----------------------------------- SELECT CLIENTE REMITENTE, DESTINATARIO

  onChangeSelectClienteRemitente = (remitente) => {
    this.setState({ remitente, direccionOrigen: remitente.direccion });
  };

  loadOptionsRemitente = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      ClienteREST.listarClientes({ search: inputValue })
        .then((respuesta) => {
          if (respuesta.data) {
            const dataList = respuesta.data.rows.map((item) => ({
              value: item.id,
              label: `${item.nombres} ${item.apellidos}`,
              direccion: `${item.callePrincipal} ${item.calleSecundaria ? item.calleSecundaria : ''} / ${item.referencia ? item.referencia : ''}`,
            }));
            callback(dataList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  onChangeSelectClienteDestinatario = (destinatario) => {
    this.setState({ destinatario, direccionDestino: destinatario.direccion });
  };

  loadOptionsDestinatario = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      ClienteREST.listarClientes({ search: inputValue })
        .then((respuesta) => {
          if (respuesta.data) {
            const dataList = respuesta.data.rows.map((item) => ({
              value: item.id,
              label: `${item.nombres} ${item.apellidos}`,
              direccion: `${item.callePrincipal} ${item.calleSecundaria ? item.calleSecundaria : ''} / ${item.referencia ? item.referencia : ''}`,
            }));
            callback(dataList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // ----------------------------------- SELECT ORIGEN, DESTINO
  _onGetRutasListener = () => {
    const data = RutaStore.getRutas(this.uuidRuta);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: `${item.descripcion} - ${item.via}`,
    }));
    this.setState({ origenes: dataList, destinos: dataList });
  };

  onChangeSelectRutaOrigen = (origen) => {
    this.setState({ origen });
  };

  onChangeSelectRutaDestino = (destino) => {
    this.setState({ destino });
  };

  // ----------------------------------- SELECT PRODUCTO
  _onGetProductosListener = () => {
    const data = ProductoStore.getProductos(this.uuidProducto);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: item.descripcion,
      precio: item.precio,
    }));
    this.setState({ productos: dataList });
  };

  onChangeSelectProducto = (producto) => {
    this.setState({ producto, valorUnitario: producto.precio });
  };

  _onErrorListerner = () => {
    window.growlAdmin.show({
      severity: 'error',
      life: 15000,
      detail: 'Problemas en la comunicación con el servidor, comuníquese con el administrador.',
    });
    this.onCloseDialogForm && this.onCloseDialogForm(true);
    this.setState({ isLoading: false });
  };

  // para manejar el handle event de los textinput
  handleInputChange = (e) => {
    const { target } = e;
    let value = $.trim(target.value).length === 0 ? null : target.value;
    const { name } = target;
    this.inputChange = { [name]: value };
    if (name.lastIndexOf('.') !== -1) {
      const nameAttr = name.substring(name.indexOf('.') + 1);
      const nameParent = name.substring(0, name.indexOf('.'));
      value = { ...this.state[nameParent], ...{ [nameAttr]: value } };
    }
    this.setStateChange(name, value, target);
  };

  setStateChange = (name, value) => {
    if (name.lastIndexOf('.') !== -1) {
      name = name.substring(0, name.indexOf('.'));
    }
    this.setState({ [name]: value }, () => {});
  };

  // agregar un detalle
  onClickAgregarDetalle = () => {
    const { producto, cantidad, valorUnitario } = this.state;
    if (producto && cantidad && valorUnitario) {
      const valorTotal = ((cantidad && parseInt(cantidad)) * (valorUnitario && parseFloat(valorUnitario))).toFixed(2); // eslint-disable-next-line radix
      const data = {
        id: getUUID(),
        productoId: producto.value,
        cantidad,
        detalle: producto.label,
        valorUnitario,
        valorTotal,
      };
      const dataList = [...this.state.list, data];
      this.setState({
        list: dataList,
        totalRecords: dataList.length,
        search: null,
        isLoading: false,
        producto: null,
        cantidad: '',
        valorUnitario: '',
      });
      this.selectProducto && this.selectProducto.focus();
    } else {
      window.growlAdmin.show({
        severity: 'warn',
        life: 15000,
        detail: 'Ingrese el producto, cantidad y valor unitario.',
      });
    }
  };

  // guardar y enviar al servidor
  onClickGuardar = () => {
    if (this.state.id === null) {
      const { remitente, destinatario, origen, destino, direccionOrigen, direccionDestino, observacion, isCredito } = this.state;
      if (!remitente || !destinatario || !origen || !destino || !direccionOrigen || !direccionDestino) {
        window.growlAdmin.show({
          severity: 'warn',
          life: 15000,
          detail: 'Ingrese el remitente, destinatario, dirección origen, dirección destino, origien, destino.',
        });
        return;
      }

      if (this.state.list.length < 1) {
        window.growlAdmin.show({
          severity: 'warn',
          life: 15000,
          detail: 'Ingrese el detalle de la guía.',
        });
        return;
      }

      const guiasDetalles = _.map(this.state.list, (item) => {
        const { productoId, cantidad, valorUnitario } = item;
        return { productoId, cantidad, valorUnitario };
      });

      const data = {
        remitenteId: remitente.value,
        destinatarioId: destinatario.value,
        rutaOrigenId: origen.value,
        rutaDestinoId: destino.value,
        direccionOrigen,
        direccionDestino,
        observacion,
        isCredito,
        guiasDetalles,
      };

      this.setState({ isLoading: true }, () => {
        GuiaActions.crearGuia(data);
      });
    } else {
      const { recibeNombre, recibeCedula, recibeFecha, recibeHumedad, recibeTemperatura, observacion, isCredito } = this.state;
      this.setState({ isLoading: true }, () => {
        GuiaActions.actualizarGuia({
          id: this.state.id,
          recibeNombre,
          recibeCedula,
          recibeFecha: recibeFecha && moment(recibeFecha).unix(),
          recibeHumedad,
          recibeTemperatura,
          observacion,
          isCredito,
        });
      });
    }
  };

  _onCreateGuiaListener = () => {
    const guia = GuiaStore.getGuia();
    this.setState({
      guiaId: guia.id,
      guiaNumero: guia.guiaNumero,
      isLoading: false,
    });
    this._onVisibleDialogGuardar(true);
  };

  _onUpdateGuiaListener = () => {
    const guia = GuiaStore.getGuia();
    let that = this;
    this.setState(
      {
        guiaId: guia.id,
        guiaNumero: guia.guiaNumero,
        isLoading: false,
      },
      () => {
        window.growlAdmin.show({
          severity: 'success',
          life: 15000,
          detail: 'Guía actualizada correctamente',
        });
        that.props.history.push('/admin/listar-guia');
      },
    );
  };

  _onClickOkImprimir = () => {
    this.setState({ isLoading: true, isVisibleDialogGuardar: false });
    fetch(`${URL_SERVER}/api/guias/${this.state.guiaId}/imprimir?imprimirPrecio=${this.state.imprimirPrecio}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Credentials': '*',
        'Content-type': 'application/pdf',
        'Content-Disposition': 'attachment; filename="downloaded.pdf',
        Authorization: `Bearer ${localStorage.getItem(conf.TOKEN_KEY)}`,
      },
      responseType: 'blob',
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
        window.open(url);
        window.URL.revokeObjectURL(url);
        // const a = document.createElement('a');
        // a.download = `guia_${this.state.guiaNumero}.pdf`;
        // a.target = '_blank';
        // a.href = url;
        // a.click();
        this.setState({ isLoading: false });
        this._onClickLimpiar();
      });
  };

  _onVisibleDialogGuardar = (estado) => {
    this.setState({ isVisibleDialogGuardar: estado });
  };

  createPopupGuardar = () => {
    const footerDialog = (
      <div>
        <Button label="SI" icon="fa fa-check" onClick={() => this._onClickOkImprimir()} disabled={this.state.disableButtonPopupDelete} />
        <Button
          label="NO"
          className="ui-button-warning"
          icon="fa fa-times"
          onClick={() => {
            this._onVisibleDialogGuardar(false);
            this._onClickLimpiar();
          }}
        />
      </div>
    );
    return (
      <Dialog header="Imprimir" footer={footerDialog} visible={this.state.isVisibleDialogGuardar} style={{ width: '400px' }} modal closable={false} onHide={() => this._onVisibleDialogGuardar(false)}>
        Guía de transporte creada correctamente, desea imprimir?
        <div style={{ margin: 10 }}>
          <RadioButton
            inputId="cb1"
            onChange={(e) => this.setState({ imprimirPrecio: e.checked })}
            checked={this.state.imprimirPrecio}
            // eslint-disable-next-line react/jsx-one-expression-per-line
          />{' '}
          Imprimir con el precio
        </div>
      </Dialog>
    );
  };
  // si el origen es tru, quiere decir que llega del unmount, lo cual no es necesario que genere la accion
  _onClickLimpiar = () => {
    GuiaActions.setGuia(null);

    this.setState(
      {
        id: null,
        guiaNumero: null,
        fecha: moment().format(FORMAT_DATE_MOMENT),
        list: [],
        totalRecords: 0,
        remitente: null,
        destinatario: null,
        direccionOrigen: '',
        direccionDestino: '',
        origen: null,
        destino: null,
        observacion: '',
        producto: null,
        cantidad: 0,
        valorUnitario: 0,
        isVisibleDialogGuardar: false,
        guiaId: '',
        imprimirPrecio: true,
        hasDelete: true,
      },
      () => {
        this.props.history.push('/admin/listar-guia');
      },
    );
  };

  createFilter() {
    const disabled = this.state.id !== null;
    return (
      <div>
        <div
          className="ui-fluid"
          style={{
            border: ' 1px solid #D3D3D3',
            borderRadius: '5px',
            margin: '8px',
          }}
        >
          <div className="ui-grid ui-fluid padding-corto">
            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Fecha:</label>
              </div>
              <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                <InputText id="fecha" name="fecha" type="text" value={this.state.fecha || ''} maxLength="50" readOnly disabled />
              </div>
            </div>

            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Nro de guía:</label>
              </div>
              <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                <InputText id="guiaNumero" name="guiaNumero" type="text" value={this.state.guiaNumero || ''} maxLength="50" readOnly disabled />
              </div>
            </div>

            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Remitente:</label>
              </div>
              <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                <AsyncSelect
                  cacheOptions
                  value={this.state.remitente}
                  onChange={this.onChangeSelectClienteRemitente}
                  loadOptions={this.loadOptionsRemitente}
                  className="select-custom"
                  classNamePrefix="select-react"
                  noOptionsMessage={() => 'No hay remitentes'}
                  placeholder="Buscar remitente..."
                  isDisabled={disabled}
                />
              </div>
            </div>
            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Destinatario:</label>
              </div>
              <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                <AsyncSelect
                  cacheOptions
                  value={this.state.destinatario}
                  onChange={this.onChangeSelectClienteDestinatario}
                  loadOptions={this.loadOptionsDestinatario}
                  className="select-custom"
                  classNamePrefix="select-react"
                  noOptionsMessage={() => 'No hay destinatarios'}
                  placeholder="Buscar destinatario..."
                  isDisabled={disabled}
                />
              </div>
            </div>

            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label htmlFor="direccionOrigen">Dirección Orig:</label>
              </div>
              <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                <InputText id="direccionOrigen" name="direccionOrigen" type="text" value={this.state.direccionOrigen || ''} maxLength="50" onChange={this.handleInputChange} disabled={disabled} />
              </div>
            </div>

            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Dirección Dest:</label>
              </div>
              <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                <InputText id="direccionDestino" name="direccionDestino" type="text" value={this.state.direccionDestino || ''} maxLength="50" onChange={this.handleInputChange} disabled={disabled} />
              </div>
            </div>

            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Origen:</label>
              </div>
              <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                <Select
                  cacheOptions
                  defaultOptions
                  value={this.state.origen}
                  options={this.state.origenes}
                  onChange={(option) => this.onChangeSelectRutaOrigen(option)}
                  className="select-custom"
                  classNamePrefix="select-react"
                  noOptionsMessage={() => 'No hay rutas origen'}
                  placeholder="Buscar rutas origen..."
                  isDisabled={disabled}
                />
              </div>
            </div>

            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Destino:</label>
              </div>
              <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                <Select
                  cacheOptions
                  defaultOptions
                  value={this.state.destino}
                  options={this.state.destinos}
                  onChange={(option) => this.onChangeSelectRutaDestino(option)}
                  className="select-custom"
                  classNamePrefix="select-react"
                  noOptionsMessage={() => 'No hay rutas destino'}
                  placeholder="Buscar rutas destino..."
                  isDisabled={disabled}
                />
              </div>
            </div>

            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Observaciones:</label>
              </div>
              <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                <InputText id="observacion" name="observacion" type="text" value={this.state.observacion || ''} maxLength="64" onChange={this.handleInputChange} style={{ marginLeft: '8px' }} />
              </div>
            </div>
            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Credito:</label>
              </div>
              <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                <input name="isGoing" type="checkbox" checked={this.state.isCredito} onChange={() => this.setState({ isCredito: !this.state.isCredito })} />
              </div>
            </div>

            {this.state.id !== null && (
              <div>
                <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                  <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                    <label>Recibe Nombre:</label>
                  </div>
                  <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                    <InputText
                      id="recibeNombre"
                      name="recibeNombre"
                      type="text"
                      value={this.state.recibeNombre || ''}
                      maxLength="100"
                      onChange={this.handleInputChange}
                      style={{ marginLeft: '8px' }}
                    />
                  </div>
                </div>
                <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                  <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                    <label>CI:</label>
                  </div>
                  <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                    <InputText
                      id="recibeCedula"
                      name="recibeCedula"
                      type="text"
                      value={this.state.recibeCedula || ''}
                      maxLength="50"
                      onChange={this.handleInputChange}
                      keyfilter={/(\d)+/g}
                      style={{ marginLeft: '8px' }}
                    />
                  </div>
                </div>
                <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                  <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                    <label>Fecha:</label>
                  </div>
                  <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                    <Calendar
                      value={this.state.recibeFecha}
                      onChange={(e) => this.setState({ recibeFecha: e.value })}
                      showIcon
                      locale={localeString('es')}
                      showTime
                      showSeconds
                      icon="fa fa-calendar"
                      hourFormat="24"
                      touchUI
                      monthNavigator
                      yearNavigator
                      yearRange={`2010:${moment().get('year')}`}
                      dateFormat={FORMAT_DATE_CALENDAR}
                      placeholder={FORMAT_DATE_MOMENT}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                  <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                    <label>Temperatura:</label>
                  </div>
                  <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                    <InputText
                      id="recibeTemperatura"
                      name="recibeTemperatura"
                      type="text"
                      value={this.state.recibeTemperatura || ''}
                      maxLength="10"
                      onChange={this.handleInputChange}
                      keyfilter={/(\d)+/g}
                      style={{ marginLeft: '8px' }}
                    />
                  </div>
                </div>
                <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                  <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                    <label>Humedad:</label>
                  </div>
                  <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                    <InputText
                      id="recibeHumedad"
                      name="recibeHumedad"
                      type="text"
                      value={this.state.recibeHumedad || ''}
                      maxLength="10"
                      onChange={this.handleInputChange}
                      keyfilter={/(\d)+/g}
                      style={{ marginLeft: '8px' }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <label style={{ margin: '10px' }}>Detalle</label>
        {this.state.id === null && (
          <div
            className="ui-fluid"
            style={{
              border: '0.5px solid #D3D3D3',
              borderRadius: '5px',
              margin: '8px',
            }}
          >
            <div className="ui-grid ui-fluid padding-corto">
              <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Producto:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <Select
                    ref={(select) => {
                      this.selectProducto = select;
                    }}
                    cacheOptions
                    defaultOptions
                    value={this.state.producto}
                    options={this.state.productos}
                    onChange={(option) => this.onChangeSelectProducto(option)}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay producto'}
                    placeholder="Buscar producto..."
                    isDisabled={disabled}
                  />
                </div>
              </div>
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Valor:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <InputText
                    id="valorUnitario"
                    name="valorUnitario"
                    type="text"
                    value={this.state.valorUnitario || ''}
                    maxLength="10"
                    onChange={this.handleInputChange}
                    keyfilter={/[(\d)+.]/g}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Cantidad:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <InputText id="cantidad" name="cantidad" type="text" value={this.state.cantidad || ''} maxLength="3" onChange={this.handleInputChange} keyfilter={/\d+/g} disabled={disabled} />
                </div>
              </div>
              <div className="ui-lg-2 ui-g-2 ui-md-2 ui-sm-12 padding-corto">
                <div className="ui-lg-12 ui-g-12 ui-md-12 ui-sm-12 padding-corto">
                  <Button label="Agregar" onClick={this.onClickAgregarDetalle} disabled={disabled} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  createColumns() {
    return [
      <Column key="0" field="cantidad" header="Cantidad" />,
      <Column key="1" field="detalle" header="Detalle" />,
      <Column key="2" field="valorUnitario" header="Valor Unitario" />,
      <Column key="3" field="valorTotal" header="Valor Total" />,
    ];
  }

  createAdditionalAfterTable = () => {
    return (
      <div>
        <Button label={this.state.id === null ? 'Guardar' : 'Actualizar'} onClick={this.onClickGuardar} />
        <Button label="Cancelar" onClick={this._onClickLimpiar} />
        {this.createPopupGuardar()}
      </div>
    );
  };
}
