/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let tipoEmpleado = null;
const tiposEmpleados = {};

const GET_TIPOSEMPLEADOS_LIST_EVENT = "GET_TIPOSEMPLEADOS_LIST_EVENT";
const CREATE_TIPOEMPLEADO_EVENT = "CREATE_TIPOEMPLEADO_EVENT";
const UPDATE_TIPOEMPLEADO_EVENT = "UPDATE_TIPOEMPLEADO_EVENT";
const DELETE_TIPOEMPLEADO_EVENT = "DELETE_TIPOEMPLEADO_EVENT";

class TipoEmpleadoStore extends EventEmitter {
  getTipoEmpleado = () => tipoEmpleado;

  setTipoEmpleado = (_tipoEmpleado) => {
    tipoEmpleado = _tipoEmpleado;
  };

  getTiposEmpleados = (componentUID) => {
    const datos = tiposEmpleados[componentUID];
    delete tiposEmpleados[componentUID];
    return datos;
  };

  setTiposEmpleados = (componentUID, _tiposEmpleados) => {
    tiposEmpleados[componentUID] = _tiposEmpleados;
  };

  emitGetTiposEmpleados(componentUID) {
    this.emit(`${GET_TIPOSEMPLEADOS_LIST_EVENT}_${componentUID}`);
  }

  addGetTiposEmpleadosListener(callback, componentUID) {
    this.on(`${GET_TIPOSEMPLEADOS_LIST_EVENT}_${componentUID}`, callback);
  }

  removeGetTiposEmpleadosListener(callback, componentUID) {
    this.removeListener(
      `${GET_TIPOSEMPLEADOS_LIST_EVENT}_${componentUID}`,
      callback
    );
  }

  emitCreateTipoEmpleado() {
    this.emit(CREATE_TIPOEMPLEADO_EVENT);
  }

  addCreateTipoEmpleadoListener(callback) {
    this.on(CREATE_TIPOEMPLEADO_EVENT, callback);
  }

  removeCreateTipoEmpleadoListener(callback) {
    this.removeListener(CREATE_TIPOEMPLEADO_EVENT, callback);
  }

  emitUpdateTipoEmpleado() {
    this.emit(UPDATE_TIPOEMPLEADO_EVENT);
  }

  addUpdateTipoEmpleadoListener(callback) {
    this.on(UPDATE_TIPOEMPLEADO_EVENT, callback);
  }

  removeUpdateTipoEmpleadoListener(callback) {
    this.removeListener(UPDATE_TIPOEMPLEADO_EVENT, callback);
  }

  emitDeleteTipoEmpleado() {
    this.emit(DELETE_TIPOEMPLEADO_EVENT);
  }

  addDeleteTipoEmpleadoListener(callback) {
    this.on(DELETE_TIPOEMPLEADO_EVENT, callback);
  }

  removeDeleteTipoEmpleadoListener(callback) {
    this.removeListener(DELETE_TIPOEMPLEADO_EVENT, callback);
  }
}

const empleadoStore = new TipoEmpleadoStore();

empleadoStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.tipoempleado.GET_LIST:
      const { componentUID } = action.payload;
      if (componentUID) {
        empleadoStore.setTiposEmpleados(componentUID, action.payload.data);
        empleadoStore.emitGetTiposEmpleados(componentUID);
      }
      break;
    case AppConstants.tipoempleado.CREATE:
      empleadoStore.setTipoEmpleado(action.payload);
      empleadoStore.emitCreateTipoEmpleado();
      break;
    case AppConstants.tipoempleado.UPDATE:
      empleadoStore.setTipoEmpleado(action.payload);
      empleadoStore.emitUpdateTipoEmpleado();
      break;
    case AppConstants.tipoempleado.DELETE:
      empleadoStore.setTipoEmpleado(action.payload);
      empleadoStore.emitDeleteTipoEmpleado();
      break;
    default:
  }
});

export default empleadoStore;
