/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable class-methods-use-this */
/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from 'react';
import Select from 'react-select';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Message } from 'primereact/components/message/Message';
import EmpleadoStore from '../../../flux/stores/EmpleadoStore';
import EmpleadoActions from '../../../flux/actions/EmpleadoActions';
import TipoEmpleadoStore from '../../../flux/stores/TipoEmpleadoStore';
import TipoEmpleadoActions from '../../../flux/actions/TipoEmpleadoActions';
import FormDialogComponent from '../../../components/commons/form/FormDialogComponent';
import { getUUID } from '../../../components/functions/globals';

export default class EmpleadoFormComponent extends FormDialogComponent {
  urlList = '/admin/empleado';

  uuidTipoEmpleado = getUUID();

  setInitialState(props) {
    let data = {
      id: null,
      nombres: null,
      apellidos: null,
      documento: null,
      telefono: null,
      direccion: null,
      correo: null,
      tipoempId: null,
      tipoEmpleado: null,
      tiposEmpleados: [],
      isSearchTipoEmpleado: false,
      validation: {
        nombres: {
          required: true,
          msg: 'Ingresar los nombres',
        },
        apellidos: {
          required: true,
          msg: 'Ingresar los apellidos',
        },
        documento: {
          required: true,
          msg: 'Ingresar la cédula',
        },
      },
    };
    if (props.editData) {
      const { id, nombres, apellidos, documento, telefono, direccion, correo, tipoempId, tipoEmpleado } = props.editData;
      data = {
        ...{},
        ...data,
        ...{
          id,
          nombres,
          apellidos,
          documento,
          telefono,
          direccion,
          correo,
          tipoempId,
          tipoEmpleado: {
            value: tipoEmpleado.id,
            label: tipoEmpleado.descripcion,
          },
        },
      };
      data.titleDialog = 'Editar Empleado';
    } else {
      data.titleDialog = 'Nuevo Empleado';
    }
    return data;
  }

  handlesBind() {}

  componentDidMount() {
    EmpleadoStore.addUpdateEmpleadoListener(this._onUpdateEmpleadoListener);
    EmpleadoStore.addCreateEmpleadoListener(this._onCreateEmpleadoListener);
    TipoEmpleadoStore.addGetTiposEmpleadosListener(this._onGetTiposEmpleadosListener, this.uuidTipoEmpleado);
    TipoEmpleadoActions.listarAllTiposEmpleados(this.uuidTipoEmpleado);
  }

  componentWillUnmount() {
    EmpleadoStore.removeCreateEmpleadoListener(this._onCreateEmpleadoListener);
    EmpleadoStore.removeUpdateEmpleadoListener(this._onUpdateEmpleadoListener);
    TipoEmpleadoStore.removeGetTiposEmpleadosListener(this._onGetTiposEmpleadosListener, this.uuidTipoEmpleado);
  }

  _onUpdateEmpleadoListener = () => {
    const data = EmpleadoStore.getEmpleado();
    this.setState({ disableButton: false });
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: 'success',
        detail: 'Empleado actualizado satisfactoriamente.',
      });
      this._onCancelReturnClick(true);
    } else {
      window.growlAdmin.show({
        severity: 'error',
        detail: 'Error al actualizar el Empleado',
      });
    }
  };

  _onGetTiposEmpleadosListener = () => {
    const data = TipoEmpleadoStore.getTiposEmpleados(this.uuidTipoEmpleado);
    const tiposEmpleados = data.rows.map((item) => ({
      value: item.id,
      label: `${item.descripcion}`,
    }));
    this.setState({ tiposEmpleados, isSearchTipoEmpleado: false });
  };

  onChangeSelectTipoEmpleado = (tipoEmpleado) => {
    this.setState({ tipoEmpleado, tipoempId: tipoEmpleado.value }, () => {
      this.isValid('tipoEmpleado');
    });
  };

  _onCreateEmpleadoListener = () => {
    const data = EmpleadoStore.getEmpleado();
    this.setState({ disableButton: false });
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: 'success',
        detail: 'Empleado creado satisfactoriamente.',
      });
      this._onCancelReturnClick(true);
    } else {
      window.growlAdmin.show({
        severity: 'error',
        detail: 'Error al crear el Empleado',
      });
    }
  };

  sendSaveUpdate() {
    const data = this.getAttributes(['tipoEmpleado', 'tiposEmpleados', 'titleDialog', 'isSearchTipoEmpleado']);
    console.log(data);
    this.state[this.attrId] ? EmpleadoActions.actualizarEmpleado(data) : EmpleadoActions.crearEmpleado(data);
  }

  createForm() {
    const { error } = this.state;
    return (
      <div className="ui-fluid">
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Nombres</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="nombres" name="nombres" type="text" value={this.state.nombres || ''} maxLength="64" onChange={this.handleInputChange} />
            {error.nombres ? <Message severity="error" text={error.nombres} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="via">Apellidos</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="apellidos" name="apellidos" type="text" value={this.state.apellidos || ''} maxLength="64" onChange={this.handleInputChange} />
            {error.apellidos ? <Message severity="error" text={error.apellidos} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="via">Cédula</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="documento" name="documento" type="text" value={this.state.documento || ''} maxLength="13" onChange={this.handleInputChange} />
            {error.documento ? <Message severity="error" text={error.documento} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Teléfono</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="telefono" name="telefono" type="text" value={this.state.telefono || ''} maxLength="25" onChange={this.handleInputChange} keyfilter={/(\+|-|\d| |\.)+/g} />
            {error.telefono ? <Message severity="error" text={error.telefono} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Dirección</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="direccion" name="direccion" type="text" value={this.state.direccion || ''} maxLength="128" onChange={this.handleInputChange} />
            {error.direccion ? <Message severity="error" text={error.direccion} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="correo">Correo electrónico</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="correo" name="correo" type="text" value={this.state.correo || ''} maxLength="150" onChange={this.handleInputChange} keyfilter="email" />
            {error.correo ? <Message severity="error" text={error.correo} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label>Tipo Empleado</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <Select
              isLoading={this.state.isSearchTipoEmpleado}
              cacheOptions
              defaultOptions
              value={this.state.tipoEmpleado}
              options={this.state.tiposEmpleados}
              onChange={(option) => this.onChangeSelectTipoEmpleado(option)}
              className="select-custom"
              classNamePrefix="select-react"
              noOptionsMessage={() => 'No hay Tipos Empleados'}
              placeholder="Buscar Tipos Empleados..."
            />
            {error.tipoEmpleado ? <Message severity="error" text={error.tipoEmpleado} /> : ''}
          </div>
        </div>
      </div>
    );
  }
}
