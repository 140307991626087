/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let tipoCliente = null;
let tiposClientes = [];

const GET_TIPOSCLIENTES_LIST_EVENT = "GET_TIPOSCLIENTES_LIST_EVENT";
const CREATE_TIPOCLIENTE_EVENT = "CREATE_TIPOCLIENTE_EVENT";
const UPDATE_TIPOCLIENTE_EVENT = "UPDATE_TIPOCLIENTE_EVENT";
const DELETE_TIPOCLIENTE_EVENT = "DELETE_TIPOCLIENTE_EVENT";

class TipoClienteStore extends EventEmitter {
  getTipoCliente = () => tipoCliente;

  setTipoCliente = (_tipoCliente) => {
    tipoCliente = _tipoCliente;
  };

  getTiposClientes = () => tiposClientes;

  setTiposClientes = (_tiposClientes) => {
    tiposClientes = _tiposClientes;
  };

  emitGetTiposClientes() {
    this.emit(GET_TIPOSCLIENTES_LIST_EVENT);
  }

  addGetTiposClientesListener(callback) {
    this.on(GET_TIPOSCLIENTES_LIST_EVENT, callback);
  }

  removeGetTiposClientesListener(callback) {
    this.removeListener(GET_TIPOSCLIENTES_LIST_EVENT, callback);
  }

  emitCreateTipoCliente() {
    this.emit(CREATE_TIPOCLIENTE_EVENT);
  }

  addCreateTipoClienteListener(callback) {
    this.on(CREATE_TIPOCLIENTE_EVENT, callback);
  }

  removeCreateTipoClienteListener(callback) {
    this.removeListener(CREATE_TIPOCLIENTE_EVENT, callback);
  }

  emitUpdateTipoCliente() {
    this.emit(UPDATE_TIPOCLIENTE_EVENT);
  }

  addUpdateTipoClienteListener(callback) {
    this.on(UPDATE_TIPOCLIENTE_EVENT, callback);
  }

  removeUpdateTipoClienteListener(callback) {
    this.removeListener(UPDATE_TIPOCLIENTE_EVENT, callback);
  }

  emitDeleteTipoCliente() {
    this.emit(DELETE_TIPOCLIENTE_EVENT);
  }

  addDeleteTipoClienteListener(callback) {
    this.on(DELETE_TIPOCLIENTE_EVENT, callback);
  }

  removeDeleteTipoClienteListener(callback) {
    this.removeListener(DELETE_TIPOCLIENTE_EVENT, callback);
  }
}

const tipoClienteStore = new TipoClienteStore();

tipoClienteStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.tipocliente.GET_LIST:
      tipoClienteStore.setTiposClientes(action.payload);
      tipoClienteStore.emitGetTiposClientes();
      break;
    case AppConstants.tipocliente.CREATE:
      tipoClienteStore.setTipoCliente(action.payload);
      tipoClienteStore.emitCreateTipoCliente();
      break;
    case AppConstants.tipocliente.UPDATE:
      tipoClienteStore.setTipoCliente(action.payload);
      tipoClienteStore.emitUpdateTipoCliente();
      break;
    case AppConstants.tipocliente.DELETE:
      tipoClienteStore.setTipoCliente(action.payload);
      tipoClienteStore.emitDeleteTipoCliente();
      break;
    default:
  }
});

export default tipoClienteStore;
