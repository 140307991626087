module.exports = {
  error: {
    BAD_REQUEST: "ERROR_BAD_REQUEST",
    SERVER_ERROR: "ERROR_SERVER_ERROR",
    AUTHORIZATION_ERROR: "ERROR_AUTHORIZATION_ERROR",
    NOT_FOUND: "ERROR_NOT_FOUND",
    CROSS_DOMAIN: "ERROR_CROSS_DOMAIN",
    EXPIRED_SESSION: "ERROR_CROSS_DOMAIN",
  },
  user: {
    GET_LIST: "GET_USER_LIST",
    CREATE: "CREATE_USER",
    UPDATE: "UPDATE_USER",
    DELETE: "DELETE_USER",
    GET_ROLES: "GET_USER_ROLES",
    ACTIVATE: "ACTIVATE_USER",
  },
  security: {
    LOGIN: "SECURITY_LOGIN",
    LOGOUT: "SECURITY_LOGOUT",
    LOGIN_ERROR: "SECURITY_LOGIN_ERROR",
    ACCOUNT: "SECURITY_ACCOUNT",
    CHANGE_PASSWORD: "SECURITY_CHANGE_PASSWORD",
  },
  provincia: {
    GET_LIST: "PROVINCIA_GET_LIST",
    CREATE: "PROVINCIA_CREATE",
    UPDATE: "PROVINCIA_UPDATE",
    DELETE: "PROVINCIA_DELETE",
  },
  canton: {
    GET_LIST: "CANTON_GET_LIST",
    CREATE: "CANTON_CREATE",
    UPDATE: "PROVINCIA_UPDATE",
    DELETE: "PROVINCIA_DELETE",
  },
  parroquia: {
    GET_LIST: "PARROQUIA_GET_LIST",
    CREATE: "PARROQUIA_CREATE",
    UPDATE: "PARROQUIA_UPDATE",
    DELETE: "PARROQUIA_DELETE",
  },
  cliente: {
    GET_LIST: "CLIENTE_GET_LIST",
    CREATE: "CLIENTE_CREATE",
    UPDATE: "CLIENTE_UPDATE",
    DELETE: "CLIENTE_DELETE",
  },
  tipocliente: {
    GET_LIST: "TIPO_CLIENTE_GET_LIST",
    CREATE: "TIPO_CLIENTE_CREATE",
    UPDATE: "TIPO_CLIENTE_UPDATE",
    DELETE: "TIPO_CLIENTE_DELETE",
  },
  ruta: {
    GET_LIST: "RUTA_GET_LIST",
    CREATE: "RUTA_CREATE",
    UPDATE: "RUTA_UPDATE",
    DELETE: "RUTA_DELETE",
  },
  producto: {
    GET_LIST: "PRODUCTO_GET_LIST",
    CREATE: "PRODUCTO_CREATE",
    UPDATE: "PRODUCTO_UPDATE",
    DELETE: "PRODUCTO_DELETE",
  },
  guia: {
    GET_LIST: "GUIA_GET_LIST",
    CREATE: "GUIA_CREATE",
    UPDATE: "GUIA_UPDATE",
    DELETE: "GUIA_DELETE",
    SET: "GUIA_SET",
  },
  vehiculo: {
    GET_LIST: "GET_VEHICLE_LIST",
    CREATE: "CREATE_VEHICLE",
    UPDATE: "UPDATE_VEHICLE",
    DELETE: "DELETE_VEHICLE",
  },
  empleado: {
    GET_LIST: "GET_EMPLEADO_LIST",
    CREATE: "CREATE_EMPLEADO",
    UPDATE: "UPDATE_EMPLEADO",
    DELETE: "DELETE_EMPLEADO",
  },
  tipoempleado: {
    GET_LIST: "GET_TIPOEMPLEADO_LIST",
    CREATE: "CREATE_TIPOEMPLEADO",
    UPDATE: "UPDATE_TIPOEMPLEADO",
    DELETE: "DELETE_TIPOEMPLEADO",
  },
  hojaruta: {
    GET_LIST: "HRUTA_GET_LIST",
    CREATE: "HRUTA_CREATE",
    UPDATE: "HRUTA_UPDATE",
    DELETE: "HRUTA_DELETE",
    SET: "HRUTA_SET",
  },
};
