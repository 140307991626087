/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable class-methods-use-this */
/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from 'react';
import Select from 'react-select';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Message } from 'primereact/components/message/Message';
import ClienteStore from '../../../flux/stores/ClienteStore';
import ClienteActions from '../../../flux/actions/ClienteActions';
import ProvinciaStore from '../../../flux/stores/ProvinciaStore';
import ProvinciaActions from '../../../flux/actions/ProvinciaActions';
import CantonStore from '../../../flux/stores/CantonStore';
import CantonActions from '../../../flux/actions/CantonActions';
import ParroquiaStore from '../../../flux/stores/ParroquiaStore';
import ParroquiaActions from '../../../flux/actions/ParroquiaActions';
import TipoClienteActions from '../../../flux/actions/TipoClienteActions';
import TipoClienteStore from '../../../flux/stores/TipoClienteStore';
import FormDialogComponent from '../../../components/commons/form/FormDialogComponent';

export default class ClienteFormComponent extends FormDialogComponent {
  urlList = '/admin/cliente';

  setInitialState(props) {
    let data = {
      id: null,
      nombres: null,
      apellidos: null,
      rucCi: null,
      callePrincipal: null,
      calleSecundaria: null,
      numeroCasa: null,
      telefono: null,
      correo: null,
      referencia: null,
      provinciaId: null,
      provincia: null,
      provincias: [],
      isSearchProvincia: false,
      tipoCliente: null,
      tiposClientes: [],
      isSearchTipoCliente: false,
      cantonId: null,
      canton: null,
      cantones: [],
      isSearchCanton: false,
      parroquiaId: null,
      parroquia: null,
      parroquias: [],
      isSearchParroquia: false,
      validation: {
        rucCi: {
          required: true,
          msg: 'Ingresar el RUC o Número de cédula',
        },
        nombres: {
          required: true,
          msg: 'Ingresar el nombre',
        },
        apellidos: {
          required: true,
          msg: 'Ingresar los apellidos',
        },
        callePrincipal: {
          required: true,
          msg: 'Ingresar la calle principal',
        },
        correo: [
          {
            required: true,
            msg: 'Ingresar el correo electrónico',
          },
          {
            pattern: 'email',
            msg: 'Formato del correo electrónico es incorrecto',
          },
        ],
      },
    };
    if (props.editData) {
      const {
        id,
        nombres,
        apellidos,
        rucCi,
        callePrincipal,
        calleSecundaria,
        numeroCasa,
        telefono,
        correo,
        referencia,
        provinciaId,
        provincia,
        cantonId,
        canton,
        parroquiaId,
        parroquia,
      } = props.editData;
      data = {
        ...{},
        ...data,
        ...{
          id,
          nombres,
          apellidos,
          rucCi,
          callePrincipal,
          calleSecundaria,
          numeroCasa,
          telefono,
          correo,
          referencia,
          provinciaId,
          cantonId,
          parroquiaId,
          provincia: provincia && {
            value: provincia.id,
            label: provincia.descripcion,
          },
          canton: canton && { value: canton.id, label: canton.descripcion },
          parroquia: parroquia && {
            value: parroquia.id,
            label: parroquia.descripcion,
          },
        },
      };
      data.titleDialog = 'Editar Cliente';
    } else {
      data.titleDialog = 'Nuevo Cliente';
    }
    return data;
  }

  handlesBind() {}

  componentDidMount() {
    ClienteStore.addCreateClienteListener(this._onCreateClienteListener);
    ClienteStore.addUpdateClienteListener(this._onUpdateClienteListener);
    ProvinciaStore.addGetProvinciasListener(this._onGetProvinciasListener);
    CantonStore.addGetCantonesListener(this._onGetCantonesListener);
    ParroquiaStore.addGetParroquiasListener(this._onGetParroquiasListener);
    TipoClienteStore.addGetTiposClientesListener(this._onGetTiposClientesListener);
    ProvinciaActions.listarProvincias({ page: 0, limit: 100, search: '' });
    TipoClienteActions.listarAllTiposClientes();
  }

  componentWillUnmount() {
    ClienteStore.removeCreateClienteListener(this._onCreateClienteListener);
    ClienteStore.removeUpdateClienteListener(this._onUpdateClienteListener);
    ProvinciaStore.removeGetProvinciasListener(this._onGetProvinciasListener);
    CantonStore.removeGetCantonesListener(this._onGetCantonesListener);
    ParroquiaStore.removeGetParroquiasListener(this._onGetParroquiasListener);
    TipoClienteStore.removeGetTiposClientesListener(this._onGetTiposClientesListener);
  }

  _onUpdateClienteListener = () => {
    const data = ClienteStore.getCliente();
    this.setState({ disableButton: false });
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: 'success',
        detail: 'Cliente actualizado satisfactoriamente.',
      });
      this._onCancelReturnClick(true);
    } else {
      window.growlAdmin.show({
        severity: 'error',
        detail: 'Error al actualizar el Cliente',
      });
    }
  };

  _onCreateClienteListener = () => {
    const data = ClienteStore.getCliente();
    this.setState({ disableButton: false });
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: 'success',
        detail: 'Cliente creado satisfactoriamente.',
      });
      this._onCancelReturnClick(true);
    } else {
      window.growlAdmin.show({
        severity: 'error',
        detail: 'Error al crear el Cliente',
      });
    }
  };

  _onGetProvinciasListener = () => {
    const data = ProvinciaStore.getProvincias();
    const provincias = data.rows.map((item) => ({
      value: item.id,
      label: `${item.descripcion}`,
    }));
    this.setState({ provincias, isSearchProvincia: false });
  };

  handleInputProvinciaChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    if (!this.state.isSearchProvincia && inputValue && inputValue.length > 2) {
      this.setState({ isSearchProvincia: true }, () => {
        ProvinciaActions.listarProvincias({
          page: 0,
          limit: 100,
          search: inputValue,
        });
      });
    }
    return inputValue;
  };

  onChangeSelectProvincia = (provincia) => {
    this.setState(
      {
        provincia,
        provinciaId: provincia.value,
        canton: null,
        parroquia: null,
        cantonId: null,
        parroquiaId: null,
      },
      () => {
        this.isValid('provincia');
        CantonActions.listarCantonesPorProvincia({
          provinciaId: provincia.value,
          page: 0,
          limit: 100,
          search: '',
        });
      },
    );
  };

  _onGetCantonesListener = () => {
    const data = CantonStore.getCantones();
    const cantones = data.rows.map((item) => ({
      value: item.id,
      label: `${item.descripcion}`,
    }));
    this.setState({ cantones, isSearchCanton: false });
  };

  handleInputCantonChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    if (!this.state.isSearchCanton && inputValue && inputValue.length > 2) {
      this.setState({ isSearchCanton: true }, () => {
        this.state.provincia &&
          CantonActions.listarCantonesPorProvincia({
            provinciaId: this.state.provincia.value,
            page: 0,
            limit: 100,
            search: inputValue,
          });
      });
    }
    return inputValue;
  };

  onChangeSelectCanton = (canton) => {
    this.setState({ canton, cantonId: canton.value, parroquia: null, parroquiaId: null }, () => {
      this.isValid('canton');
      ParroquiaActions.listarParroquiasPorCanton({
        cantonId: canton.value,
        page: 0,
        limit: 100,
        search: '',
      });
    });
  };

  _onGetParroquiasListener = () => {
    const data = ParroquiaStore.getParroquias();
    const parroquias = data.rows.map((item) => ({
      value: item.id,
      label: `${item.descripcion}`,
    }));
    this.setState({ parroquias, isSearchParroquia: false });
  };

  handleInputParroquiaChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    if (!this.state.isSearchParroquia && inputValue && inputValue.length > 2) {
      this.setState({ isSearchParroquia: true }, () => {
        this.state.canton &&
          ParroquiaActions.listarParroquiasPorCanton({
            cantonId: this.state.canton.value,
            page: 0,
            limit: 100,
            search: inputValue,
          });
      });
    }
    return inputValue;
  };

  onChangeSelectParroquia = (parroquia) => {
    this.setState({ parroquia, parroquiaId: parroquia.value }, () => {
      this.isValid('parroquia');
    });
  };

  _onGetTiposClientesListener = () => {
    const data = TipoClienteStore.getTiposClientes();
    const tiposClientes = data.rows.map((item) => ({
      value: item.id,
      label: `${item.descripcion}`,
    }));
    this.setState({ tiposClientes, isSearchTipoCliente: false });
  };

  // handleInputTipoClienteChange = (newValue) => {
  //   const inputValue = newValue.replace(/\W/g, '');
  //   if (!this.state.isSearchTipoCliente && inputValue && inputValue.length > 2) {
  //       this.setState({ isSearchTipoCliente: true }, () => {
  //           TipoClienteActions.listarTiposClientes({ page: 0, limit: 100, search: inputValue });
  //       });
  //   }
  //   return inputValue;
  // }
  onChangeSelectTipoCliente = (tipoCliente) => {
    this.setState({ tipoCliente }, () => {
      this.isValid('tipoCliente');
    });
  };

  sendSaveUpdate() {
    const data = this.getAttributes(['provincia', 'provincias', 'isSearchProvincia', 'canton', 'cantones', 'isSearchCanton', 'parroquia', 'parroquias', 'isSearchParroquia']);
    // !data.provinciaId && _.unset(data, 'provinciaId');
    // !data.cantonId && _.unset(data, 'cantonId');
    // !data.parroquiaId && _.unset(data, 'parroquiaId');
    // !data.callePrincipal && _.unset(data, 'callePrincipal');
    // !data.calleSecundaria && _.unset(data, 'calleSecundaria');
    // !data.numeroCasa && _.unset(data, 'numeroCasa');
    // !data.telefono && _.unset(data, 'telefono');
    // !data.referencia && _.unset(data, 'referencia');
    this.state[this.attrId] ? ClienteActions.actualizarCliente(data) : ClienteActions.crearCliente(data);
  }

  createForm() {
    const { error } = this.state;
    return (
      <div className="ui-fluid">
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">RUC/CI</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="rucCi" name="rucCi" type="text" value={this.state.rucCi || ''} maxLength="13" onChange={this.handleInputChange} keyfilter={/(\d|-)+/g} />
            {error.rucCi ? <Message severity="error" text={error.rucCi} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Nombres</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="nombres" name="nombres" type="text" value={this.state.nombres || ''} maxLength="64" onChange={this.handleInputChange} />
            {error.nombres ? <Message severity="error" text={error.nombres} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Apellidos</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="apellidos" name="apellidos" type="text" value={this.state.apellidos || ''} maxLength="64" onChange={this.handleInputChange} />
            {error.apellidos ? <Message severity="error" text={error.apellidos} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="correo">Correo electrónico</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="correo" name="correo" type="text" value={this.state.correo || ''} maxLength="150" onChange={this.handleInputChange} keyfilter="email" />
            {error.correo ? <Message severity="error" text={error.correo} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Teléfono</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="telefono" name="telefono" type="text" value={this.state.telefono || ''} maxLength="64" onChange={this.handleInputChange} keyfilter={/(\+|-|\d| |\.)+/g} />
            {error.telefono ? <Message severity="error" text={error.telefono} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Calle principal</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="callePrincipal" name="callePrincipal" type="text" value={this.state.callePrincipal || ''} maxLength="64" onChange={this.handleInputChange} />
            {error.callePrincipal ? <Message severity="error" text={error.callePrincipal} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Calle secundaria</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="calleSecundaria" name="calleSecundaria" type="text" value={this.state.calleSecundaria || ''} maxLength="64" onChange={this.handleInputChange} />
            {error.calleSecundaria ? <Message severity="error" text={error.calleSecundaria} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Número de casa</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="numeroCasa" name="numeroCasa" type="text" value={this.state.numeroCasa || ''} maxLength="15" onChange={this.handleInputChange} />
            {error.numeroCasa ? <Message severity="error" text={error.numeroCasa} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Referencia</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="referencia" name="referencia" type="text" value={this.state.referencia || ''} maxLength="128" onChange={this.handleInputChange} />
            {error.referencia ? <Message severity="error" text={error.referencia} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label>Tipo Cliente</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <Select
              isLoading={this.state.isSearchTipoCliente}
              cacheOptions
              defaultOptions
              value={this.state.tipoCliente}
              options={this.state.tiposClientes}
              onChange={(option) => this.onChangeSelectTipoCliente(option)}
              className="select-custom"
              classNamePrefix="select-react"
              noOptionsMessage={() => 'No hay Tipos Clientes'}
              placeholder="Buscar Tipos Clientes..."
            />
            {error.tipoCliente ? <Message severity="error" text={error.tipoCliente} /> : ''}
          </div>
        </div>
        {/* <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label>Provincia</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <Select
              isLoading={this.state.isSearchProvincia}
              cacheOptions
              defaultOptions
              value={this.state.provincia}
              options={this.state.provincias}
              // onInputChange={this.handleInputProvinciaChange}
              onChange={(option) => this.onChangeSelectProvincia(option)}
              className="select-custom"
              classNamePrefix="select-react"
              noOptionsMessage={() => "No hay provincias"}
              placeholder="Buscar Provincias..."
            />
            {error.provincia ? (
              <Message severity="error" text={error.provincia} />
            ) : (
              ""
            )}
          </div>
        </div> */}
        {/* <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label>Canton</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <Select
              isLoading={this.state.isSearchCanton}
              isDisabled={!this.state.provincia}
              cacheOptions
              defaultOptions
              value={this.state.canton}
              options={this.state.cantones}
              onInputChange={this.handleInputCantonChange}
              onChange={(option) => this.onChangeSelectCanton(option)}
              className="select-custom"
              classNamePrefix="select-react"
              noOptionsMessage={() => "No hay cantones"}
              placeholder="Buscar Cantones..."
            />
            {error.canton ? (
              <Message severity="error" text={error.canton} />
            ) : (
              ""
            )}
          </div>
        </div> */}
        {/* <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label>Parroquia</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <Select
              isLoading={this.state.isSearchParroquia}
              isDisabled={!this.state.canton}
              cacheOptions
              defaultOptions
              value={this.state.parroquia}
              options={this.state.parroquias}
              onInputChange={this.handleInputParroquiaChange}
              onChange={(option) => this.onChangeSelectParroquia(option)}
              className="select-custom"
              classNamePrefix="select-react"
              noOptionsMessage={() => "No hay parroquias"}
              placeholder="Buscar Parroquias..."
            />
            {error.parroquia ? (
              <Message severity="error" text={error.parroquia} />
            ) : (
              ""
            )}
          </div>
        </div> */}
      </div>
    );
  }
}
