/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import {
  getSuperagent,
  postSuperagent,
  putSuperagent,
  deleteSuperagent,
} from "./SuperagentConfig";

class CantonREST {
  listarCantonesPorProvincia = ({ provinciaId, page, limit, search }) => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent(
        `/api/provincias/${provinciaId}/cantones?page=${page}&limit=${limit}&search=${search}`
      ).end((error, response) => {
        // eslint-disable-next-line no-unused-expressions
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  crearCanton = ({ descripcion, provinciaId }) => {
    const promesa = new Promise((resolve, reject) => {
      postSuperagent("/api/cantones", { descripcion, provinciaId }).end(
        (error, response) => {
          // eslint-disable-next-line no-unused-expressions
          error ? reject(error) : resolve(response.body);
        }
      );
    });
    return promesa;
  };

  actualizarCanton = ({ id, descripcion, provinciaId }) => {
    const promesa = new Promise((resolve, reject) => {
      putSuperagent(`/api/cantones/${id}`, { descripcion, provinciaId }).end(
        (error, response) => {
          // eslint-disable-next-line no-unused-expressions
          error ? reject(error) : resolve(response.body);
        }
      );
    });
    return promesa;
  };

  eliminarCanton = (id) => {
    const promesa = new Promise((resolve, reject) => {
      deleteSuperagent(`/api/cantones/${id}`).end((error, response) => {
        // eslint-disable-next-line no-unused-expressions
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };
}

export default new CantonREST();
