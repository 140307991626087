/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint jsx-a11y/anchor-is-valid: 0 */
/**
 * @file Componente de Navegacion
 * @author Wilson Guiñanzaca <wilson_g18@hotmail.com>
 * 13-Mar-2019
 */
import React, { Component } from "react";
import classNames from "classnames";
import { Menu } from "primereact/components/menu/Menu";
import { Growl } from "primereact/components/growl/Growl";
import { Button } from "primereact/components/button/Button";
import { Dialog } from "primereact/components/dialog/Dialog";
import GlobalContext from "../../flux/GlobalContext";
import conf from "../../conf/conf";
import ErrorStore from "../../flux/stores/ErrorStore";

// eslint-disable-next-line import/prefer-default-export
export class NavigationComponent extends Component {
  /**
   * Inicializar state
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenuProfile: false,
      isVisiblePopupAccount: false,
      isVisiblePopupSession: false,
    };
    this._onErrorListerner = this._onErrorListerner.bind(this);
  }

  componentDidMount() {
    ErrorStore.addErrorListener(this._onErrorListerner);
  }

  componentWillUnmount() {
    ErrorStore.removeErrorListener(this._onErrorListerner);
  }

  _onErrorListerner() {
    const error = ErrorStore.getError();
    console.log(error);
    switch (error.code) {
      case 401:
      case 403: {
        if (error.message && error.message.status === "UNAUTHORIZED") {
          this.setState({ isVisiblePopupSession: true });
        } else {
          this._onLogout();
        }
        break;
      }
      default:
        break;
    }
  }

  /**
   * Mostrar u ocultar popup de formulario
   */
  _onShowHideDialogForm = (isShow) => {
    this.setState({
      isVisiblePopupAccount: isShow,
    });
  };

  _onLogout() {
    localStorage.removeItem(conf.TOKEN_KEY);
    window.location = "/";
  }

  createPopupSession() {
    const footerDialog = (
      <div>
        <Button label="ACEPTAR" onClick={() => this._onLogout()} />
      </div>
    );
    return (
      <Dialog
        header="Aviso"
        footer={footerDialog}
        visible={this.state.isVisiblePopupSession}
        style={{ width: "400px" }}
        modal
        closable={false}
        onHide={() => this.setState({ isVisiblePopupSession: false })}
      >
        Expiro la sesión actual.
      </Dialog>
    );
  }

  /**
   * Crear componente de navegacion
   */
  render() {
    const items = [
      // {
      //     label: 'Mi Cuenta',
      //     icon: 'fa fa-fw fa-user',
      //     command : () => {
      //         this.setState({ isVisiblePopupAccount: true });
      //     }
      // },
      {
        label: "Salir",
        icon: "fa fa-fw fa-sign-out-alt",
        command: () => {
          this._onLogout();
        },
      },
    ];
    const account = GlobalContext.SESSION;
    const nameUser = account
      ? `${account.nombres || ""} ${account.apellidos || ""}`
      : "";
    // let iconProfileClassName = classNames('fa fa-fw', { 'fa-caret-up': this.state.isOpenMenuProfile, 'fa-caret-down': !this.state.isOpenMenuProfile});
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} />
        <div className="topbar clearfix">
          <div className="topbar-left">
            <img
              alt="Transportes Brito"
              src="/assets/layout/images/logo2.png"
              className="topbar-logo"
            />
          </div>

          <div className="topbar-right">
            <span className="tit-right">Administración</span>
            <a id="menu-button" onClick={this.props.onMenuButtonClick}>
              <i className="fa fa-angle-left" />
            </a>
            <a
              id="topbar-menu-button"
              onClick={(e) => {
                this.menu.toggle(e);
              }}
            >
              <i className="fa fa-bars" />
            </a>
            <ul className="topbar-items fadeInDown">
              <li
                className={classNames("profile-item", {
                  "active-top-menu": false,
                })}
              >
                <a
                  // onClick={(e) => {this.menu.toggle(e);}}
                  style={{ textAlign: "right", cursor: "default" }}
                >
                  {/* <span className="profile-image">
                                    <i className="fa fa-user-circle"></i>
                                </span> */}
                  <span
                    className="topbar-item-name"
                    style={{ paddingTop: "10px" }}
                  >
                    {nameUser}
                    <i
                      className="fa fa-fw fa-sign-out-alt"
                      style={{
                        display: "inline",
                        paddingLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => this._onLogout()}
                    />
                    {/* <i className={iconProfileClassName} style={{ display: 'inline', paddingLeft: '5px' }}></i> */}
                  </span>
                </a>
              </li>
            </ul>
            <Menu
              model={items}
              popup
              ref={(el) => (this.menu = el)}
              className="top-submenu"
              onHide={() => {
                this.setState({ isOpenMenuProfile: false });
              }}
              onShow={() => {
                this.setState({ isOpenMenuProfile: true });
              }}
            />
          </div>
        </div>
        {this.createPopupSession()}
      </div>
    );
  }
}
