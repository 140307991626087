/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let provincia = null;
let provincias = [];

const GET_PROVINCIAS_LIST_EVENT = "GET_PROVINCIAS_LIST_EVENT";
const CREATE_PROVINCIA_EVENT = "CREATE_PROVINCIA_EVENT";
const UPDATE_PROVINCIA_EVENT = "UPDATE_PROVINCIA_EVENT";
const DELETE_PROVINCIA_EVENT = "DELETE_PROVINCIA_EVENT";

class ProvinciaStore extends EventEmitter {
  getProvincia = () => provincia;

  setProvincia = (_provincia) => {
    provincia = _provincia;
  };

  getProvincias = () => provincias;

  setProvincias = (_provincias) => {
    provincias = _provincias;
  };

  emitGetProvincias() {
    this.emit(GET_PROVINCIAS_LIST_EVENT);
  }

  addGetProvinciasListener(callback) {
    this.on(GET_PROVINCIAS_LIST_EVENT, callback);
  }

  removeGetProvinciasListener(callback) {
    this.removeListener(GET_PROVINCIAS_LIST_EVENT, callback);
  }

  emitCreateProvincia() {
    this.emit(CREATE_PROVINCIA_EVENT);
  }

  addCreateProvinciaListener(callback) {
    this.on(CREATE_PROVINCIA_EVENT, callback);
  }

  removeCreateProvinciaListener(callback) {
    this.removeListener(CREATE_PROVINCIA_EVENT, callback);
  }

  emitUpdateProvincia() {
    this.emit(UPDATE_PROVINCIA_EVENT);
  }

  addUpdateProvinciaListener(callback) {
    this.on(UPDATE_PROVINCIA_EVENT, callback);
  }

  removeUpdateProvinciaListener(callback) {
    this.removeListener(UPDATE_PROVINCIA_EVENT, callback);
  }

  emitDeleteProvincia() {
    this.emit(DELETE_PROVINCIA_EVENT);
  }

  addDeleteProvinciaListener(callback) {
    this.on(DELETE_PROVINCIA_EVENT, callback);
  }

  removeDeleteProvinciaListener(callback) {
    this.removeListener(DELETE_PROVINCIA_EVENT, callback);
  }
}

const provinciaStore = new ProvinciaStore();

provinciaStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.provincia.GET_LIST:
      provinciaStore.setProvincias(action.payload);
      provinciaStore.emitGetProvincias();
      break;
    case AppConstants.provincia.CREATE:
      provinciaStore.setProvincia(action.payload);
      provinciaStore.emitCreateProvincia();
      break;
    case AppConstants.provincia.UPDATE:
      provinciaStore.setProvincia(action.payload);
      provinciaStore.emitUpdateProvincia();
      break;
    case AppConstants.provincia.DELETE:
      provinciaStore.setProvincia(action.payload);
      provinciaStore.emitDeleteProvincia();
      break;
    default:
  }
});

export default provinciaStore;
