/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from "../AppDispatcher";
import * as AppConstants from "../AppConstants";
import RutaREST from "../api/RutaREST";
import ErrorHandler from "../utilities/ErrorHandler";

class RutaActions {
  listarRutas = ({ page, limit, search }, componentUID) => {
    RutaREST.listarRutas({ page, limit, search })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.ruta.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  listarAllRutas = (componentUID) => {
    RutaREST.listarAllRutas()
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.ruta.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearRuta = ({ descripcion, via }) => {
    RutaREST.crearRuta({ descripcion, via })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.ruta.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  actualizarRuta = ({ id, descripcion, via }) => {
    RutaREST.actualizarRuta({ id, descripcion, via })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.ruta.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarRuta = (id) => {
    RutaREST.eliminarRuta(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.ruta.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new RutaActions();
