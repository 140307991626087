/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from 'react';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Message } from 'primereact/components/message/Message';
import ProductoStore from '../../../flux/stores/ProductoStore';
import ProductoActions from '../../../flux/actions/ProductoActions';
import FormDialogComponent from '../../../components/commons/form/FormDialogComponent';

export default class ProductoFormComponent extends FormDialogComponent {
  urlList = '/admin/producto';

  setInitialState(props) {
    let data = {
      id: null,
      descripcion: null,
      precio: 0,
      validation: {
        descripcion: {
          required: true,
          msg: 'Ingresar la descripción',
        },
        precio: {
          required: true,
          msg: 'Ingresar el precio',
        },
      },
    };
    if (props.editData) {
      const { id, descripcion, precio } = props.editData;
      data = { ...{}, ...data, ...{ id, descripcion, precio } };
      data.titleDialog = 'Editar Producto';
    } else {
      data.titleDialog = 'Nueva Producto';
    }
    return data;
  }

  handlesBind() {}

  componentDidMount() {
    ProductoStore.addCreateProductoListener(this._onCreateProductoListener);
    ProductoStore.addUpdateProductoListener(this._onUpdateProductoListener);
  }

  componentWillUnmount() {
    ProductoStore.removeCreateProductoListener(this._onCreateProductoListener);
    ProductoStore.removeUpdateProductoListener(this._onUpdateProductoListener);
  }

  _onUpdateProductoListener = () => {
    let data = ProductoStore.getProducto();
    this.setState({ disableButton: false });
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: 'success',
        detail: 'Producto actualizado satisfactoriamente.',
      });
      this._onCancelReturnClick(true);
    } else {
      window.growlAdmin.show({
        severity: 'error',
        detail: 'Error al actualizar el Producto',
      });
    }
  };

  _onCreateProductoListener = () => {
    let data = ProductoStore.getProducto();
    this.setState({ disableButton: false });
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: 'success',
        detail: 'Producto creada satisfactoriamente.',
      });
      this._onCancelReturnClick(true);
    } else {
      window.growlAdmin.show({
        severity: 'error',
        detail: 'Error al crear el Cantón',
      });
    }
  };

  sendSaveUpdate() {
    let data = this.getAttributes();
    this.state[this.attrId] ? ProductoActions.actualizarProducto(data) : ProductoActions.crearProducto(data);
  }

  createForm() {
    const { error } = this.state;
    return (
      <div className="ui-fluid">
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Descripción</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="descripcion" name="descripcion" type="text" value={this.state.descripcion || ''} maxLength="64" onChange={this.handleInputChange} />
            {error.descripcion ? <Message severity="error" text={error.descripcion} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="precio">Precio</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="precio" name="precio" type="text" value={this.state.precio || ''} maxLength="10" keyfilter={/[(\d)+.]/g} onChange={this.handleInputChange} />
            {error.precio ? <Message severity="error" text={error.precio} /> : ''}
          </div>
        </div>
      </div>
    );
  }
}
