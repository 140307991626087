/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
/* eslint no-restricted-globals: 0 */
/**
 * @file Contenedor de la parte de administracion
 * @author Wilson Guiñanzaca <wilson_g18@hotmail.com>
 * 13-Mar-2019
 */
import $ from 'jquery';
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { PanelMenu } from 'primereact/components/panelmenu/PanelMenu';
import { Growl } from 'primereact/components/growl/Growl';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'nanoscroller/bin/css/nanoscroller.css';
import 'font-awesome/css/font-awesome.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '../../styles/modules/app.scss';
// import '../../styles/modules/menubar.scss';
import 'nanoscroller';

import { NavigationComponent } from '../navigation/NavigationComponent';
import { verifyPermissionRead } from '../../conf/security';
import ProvinciaContainer from './provincia/ProvinciaContainer';
import CantonContainer from './canton/CantonContainer';
import ParroquiaContainer from './parroquia/ParroquiaContainer';
import ClienteContainer from './cliente/ClienteContainer';
import GuiaCrearContainer from './guia/GuiaCrearContainer';
import RutaContainer from './ruta/RutaContainer';
import ProductoContainer from './producto/ProductoContainer';
import GuiaListarContainer from './guia/GuiaListarContainer';
import VehiculoContainer from './vehiculo/VehiculoContainer';
import EmpleadoContainer from './empleado/EmpleadoContainer';
import HojaRutaCrearContainer from './hojaRuta/HojaRutaCrearContainer';
import HojaRutaListarContainer from './hojaRuta/HojaRutaListarContainer';
import ReporteContainer from './reporte/ReporteContainer';
import ReporteDestinoContainer from './reporte/ReporteDestinoContainer';

const NotMenuContainer = () => <div>No tiene autorización</div>;

export class AdminComponent extends Component {
  /**
   * Contrunctor del componente
   * @param {*} props
   */
  constructor(props) {
    super(props);
    const itemMenuSelect = props.location.pathname.split('/', 3)[2];
    this.state = {
      itemMenuSelect: itemMenuSelect || null,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
    };
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
  }

  componentDidMount = () => {
    $(this.layoutMenuScroller).nanoScroller({ flash: true });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const menuSelect = nextProps.location.pathname.split('/', 3)[2];
    if (menuSelect !== this.state.itemMenuSelect) {
      this.selectItemMenu(menuSelect);
    }
  };

  /**
   * Crear ruta
   * @param {*} route
   */
  createRoute(route, containerComponent) {
    const { match } = this.props;
    let element = '';
    if (verifyPermissionRead(route)) {
      element = <Route path={`${`${match.path}/${route}`}`} component={containerComponent} />;
    }
    return element;
  }

  /**
   * Click en el documento
   * @param {*} event
   */
  onDocumentClick() {
    if ($('.layout-mask').css('display') === 'block' && !this.menuClick) {
      this.setState({ staticMenuMobileActive: false });
    }
    this.menuClick = false;
  }

  onMenuClick() {
    this.menuClick = true;
  }

  /**
   * Selecionar una opcion del menu
   */
  selectItemMenu(itemMenu) {
    if (this.state.itemMenuSelect !== itemMenu) {
      this.setState({ itemMenuSelect: itemMenu }, () => {
        $('.ui-panelmenu.custom-panel').find('.item-active').removeClass('item-active');
        $('.ui-panelmenu.custom-panel').find(`.item-${itemMenu}`).addClass('item-active');
        this.props.history.push(`/admin/${itemMenu}`);
      });
    }
  }

  /** Listado Dinamico del menu por roles */
  getMenuItem(itemMenuSelect) {
    const createItem = (label, icon, _module) => {
      return {
        module: `/admin/${_module}`,
        label,
        icon: `fa fa-fw fa-${icon}`,
        command: () => {
          this.selectItemMenu(_module);
        },
        className: `item-${_module} ${itemMenuSelect === _module ? 'item-active' : ''}`,
      };
    };
    const items = [];

    // if (verifyPermissionRead('find-vehicle')) {
    //     items.push(createItem('Buscar Vehiculo', 'search', 'find-vehicle'));
    // }

    if (verifyPermissionRead('crear-guia')) {
      items.push({
        label: 'Guía',
        icon: 'fa fa-fw fa-file',
        items: [createItem('Nueva Guía', 'file-o', 'crear-guia'), createItem('Lista Guía', 'file-o', 'listar-guia')],
      });
    }

    if (verifyPermissionRead('hruta')) {
      //
      items.push({
        label: 'Hoja de Ruta',
        icon: 'fa fa-fw fa-file',
        items: [createItem('Nueva Hoja de Ruta', 'file-o', 'crear-hruta'), createItem('Lista Hoja de Ruta', 'file-o', 'listar-hruta')],
      });
    }

    if (verifyPermissionRead('reporte')) {
      items.push({
        label: 'Reportes',
        icon: 'fa fa-fw fa-file',
        items: [createItem('Hoja de Ruta Filtro Destino', 'file-o', 'reporte-remitente'), createItem('Hoja de Ruta Filtro Remitente', 'file-o', 'reporte-destino')],
      });
    }

    if (verifyPermissionRead('cliente')) {
      items.push(createItem('Cliente', 'user', 'cliente'));
    }

    if (verifyPermissionRead('ruta')) {
      items.push(createItem('Ruta', 'street-view', 'ruta'));
    }

    if (verifyPermissionRead('producto')) {
      items.push(createItem('Producto', 'box', 'producto'));
    }

    if (verifyPermissionRead('vehiculo')) {
      items.push(createItem('Vehículo', 'car', 'vehiculo'));
    }

    if (verifyPermissionRead('empleado')) {
      items.push(createItem('Empleado', 'users', 'empleado'));
    }

    // if (verifyPermissionRead("ubicacion")) {
    //   items.push({
    //     label: "Ubicación",
    //     icon: "fa fa-map",
    //     items: [
    //       createItem("Provincia", "map-marker", "provincia"),
    //       createItem("Cantón", "map-marker", "canton"),
    //       createItem("Parroquia", "map-marker", "parroquia"),
    //     ],
    //   });
    // }

    return items;
  }

  onMenuButtonClick(event) {
    event.preventDefault();
    this.menuClick = true;
    this.setState(
      this.isDesktop()
        ? {
            staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive,
          }
        : {
            staticMenuMobileActive: !this.state.staticMenuMobileActive,
          },
    );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  /**
   * Crear submenu
   */
  render() {
    const layoutClassName = classNames('layout-wrapper menu-layout-static', {
      'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
      'layout-menu-static-active': this.state.staticMenuMobileActive,
    });
    const menu = this.getMenuItem(this.state.itemMenuSelect);
    return (
      <div style={{ minHeight: '100%' }}>
        <Growl ref={(el) => (window.growlAdmin = el)} />
        <div className={layoutClassName} onClick={this.onDocumentClick} style={{ minHeight: '100%' }}>
          <div>
            <NavigationComponent active="admin" onMenuButtonClick={this.onMenuButtonClick} />
            <div className="layout-menu-container" onClick={this.onMenuClick}>
              <div ref={(el) => (this.layoutMenuScroller = el)} className="nano">
                <div className="nano-content menu-scroll-content">
                  <PanelMenu model={menu} className="custom-panel" />
                </div>
              </div>
            </div>
            <div className="layout-main">
              <Switch>
                {this.createRoute('crear-guia', GuiaCrearContainer)}
                {this.createRoute('listar-guia', GuiaListarContainer)}
                {this.createRoute('cliente', ClienteContainer)}
                {/* {this.createRoute('provincia', ProvinciaContainer)} */}
                {/* {this.createRoute('canton', CantonContainer)} */}
                {/* {this.createRoute('parroquia', ParroquiaContainer)} */}
                {this.createRoute('ruta', RutaContainer)}
                {this.createRoute('producto', ProductoContainer)}
                {this.createRoute('vehiculo', VehiculoContainer)}
                {this.createRoute('empleado', EmpleadoContainer)}
                {this.createRoute('crear-hruta', HojaRutaCrearContainer)}
                {this.createRoute('listar-hruta', HojaRutaListarContainer)}
                {this.createRoute('reporte-remitente', ReporteContainer)}
                {this.createRoute('reporte-destino', ReporteDestinoContainer)}
                {menu.length === 0 ? <Route path="/admin/not-permissions" component={NotMenuContainer} /> : ''}
                <Redirect to={menu[0] && menu[0].module ? menu[0].module : '/admin/not-permissions'} />
              </Switch>
            </div>
            <div className="layout-mask" />
          </div>
          {/* < AppFooter /> */}
        </div>
      </div>
    );
  }
}
