/* eslint no-useless-escape: 0 */
/**
 * @file Archivo con funciones de uso global y general
 * @author Wilson Guiñanzaca <wilson_g18@hotmail.com>
 * 12-Mar-2019
 */
import moment from "moment";

/**
 * validateInputValue - return validation for input value
 * @returns {String} - validateInputValue
 */
export const validateInputValue = (e, mode) => {
  switch (mode) {
    case "amount": {
      const am = /[0-9.]+/g;
      if (!am.test(e.key)) {
        e.preventDefault();
      }
      break;
    }
    case "letters": {
      const le = /([A-Z]|[a-z])+/g;
      if (!le.test(e.key)) {
        e.preventDefault();
      }
      break;
    }
    case "numbers": {
      const nu = /[0-9]+/g;
      if (!nu.test(e.key)) {
        e.preventDefault();
      }
      break;
    }
    case "phone": {
      const ph = /(\+|\-|\d| |\.)+/g;
      if (!ph.test(e.key)) {
        e.preventDefault();
      }
      break;
    }
    default:
  }
};

export const localeString = (localeString) => {
  const locales = {
    es: {
      firstDayOfWeek: 1,
      dayNames: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      monthNamesShort: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
    },
  };
  return locales[localeString] || null;
};

export const FORMAT_DATE_CALENDAR = "dd/mm/yy";
export const FORMAT_DATE_MOMENT = "DD/MM/YYYY";
export const FORMAT_DATE_TIME_MOMENT = "DD/MM/YYYY HH:mm:ss";

export const getYearRange = (maxYear, minYear) => {
  const currentYear = moment().get("year");
  const initialYear = minYear ? currentYear - minYear : 2000;
  const endYear = currentYear + (maxYear || 0);
  return `${initialYear}:${endYear}`;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getPeriodos = (_anio) => {
  const anio = _anio || new Date().getFullYear();
  const periodos = [];
  for (let index = anio - 2; index < anio + 2; index++) {
    periodos.push({
      label: `${index}-${index + 1}`,
      value: `${index}-${index + 1}`,
    });
  }
  return periodos;
};

export const getUUID = () => {
  let d = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // eslint-disable-next-line no-mixed-operators
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};
