/* eslint-disable react/prefer-stateless-function */
/**
 * @description Componente input - recibe el tipo de input
 * @author Jana Acuna - jacuna@lynxtrade.com
 */
import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LoginComponent } from "./LoginComponent";

export default class AuthenticationComponent extends Component {
  render() {
    return (
      <div className="layout-wrapper menu-layout-overlay">
        <div className="layout-main">
          <Switch>
            <Route path="/login" exact component={LoginComponent} />
            <Redirect to="/" />
          </Switch>
        </div>
      </div>
    );
  }
}
