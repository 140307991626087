/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 24th June 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 24th June 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from 'react';
import CalendarComponent from '../../../components/commons/form/CalendarComponent';
import { Button } from 'primereact/components/button/Button';
import Select from 'react-select';
import moment from 'moment';
import { SpinnerLoadingComponent } from '../../../components/commons/spinner/SpinnerLoadingComponent';
import { localeString, FORMAT_DATE_CALENDAR, FORMAT_DATE_MOMENT, getUUID } from '../../../components/functions/globals';
import { URL_SERVER, TOKEN_KEY } from '../../../conf/conf';
import RutaActions from '../../../flux/actions/RutaActions';
import RutaStore from '../../../flux/stores/RutaStore';
export default class ReporteRemitenteComponent extends React.Component {
  uuiRuta = getUUID();
  constructor(props) {
    super(props);
    this.state = {
      fechaInicio: moment().subtract(30, 'd').toDate(),
      fechaFin: new Date(),
      isLoading: false,
      rutaDestino: null,
      rutasDestinos: [],
      isRemitente: false,
    };
  }

  componentDidMount() {
    RutaStore.addGetRutasListener(this._onGetRutasListener, this.uuiRuta);
    RutaActions.listarAllRutas(this.uuiRuta);
  }

  componentWillUnmount() {
    RutaStore.removeGetRutasListener(this._onGetRutasListener, this.uuiRuta);
  }

  onClickGenerarReporte = () => {
    const { fechaInicio, fechaFin, rutaDestino, isRemitente } = this.state;
    const fechaInicioUNIX = moment(fechaInicio).startOf('day').unix();
    const fechaFinUNIX = moment(fechaFin).startOf('day').unix();
    const fechaInicioNombre = moment(fechaInicio).format(FORMAT_DATE_MOMENT).replaceAll('/', '');
    const fechaFinNombre = moment(fechaFin).format(FORMAT_DATE_MOMENT).replaceAll('/', '');

    this.setState({ isLoading: true });
    fetch(`${URL_SERVER}/api/hrutas/reporte/uno?fechaInicio=${fechaInicioUNIX}&fechaFin=${fechaFinUNIX}&destino=${rutaDestino ? rutaDestino.value : null}&remitente=${isRemitente}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Credentials': '*',
        'Content-type': 'application/application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Content-Disposition': 'attachment; filename="downloaded.xlsx',
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
      responseType: 'blob',
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const a = document.createElement('a');
        a.download = `reporte_${fechaInicioNombre}_${fechaFinNombre}.xlsx`;
        a.target = '_blank';
        a.href = url;
        a.click();
        this.setState({ isLoading: false });
      });
  };

  _onGetRutasListener = () => {
    const data = RutaStore.getRutas(this.uuiRuta);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: `${item.descripcion} - ${item.via}`,
    }));
    this.setState({ rutaDestinos: dataList });
  };

  render() {
    return (
      <div>
        <div
          className="ui-fluid"
          style={{
            border: ' 1px solid #D3D3D3',
            borderRadius: '5px',
            margin: '10px',
          }}
        >
          <div className="ui-grid ui-fluid">
            <h3 style={{ textAlign: 'center' }}>REPORTE FILTRO DESTINO</h3>
            <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Fecha Inicio:</label>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <CalendarComponent
                  value={this.state.fechaInicio}
                  onChange={(e) => this.setState({ fechaInicio: e.value })}
                  showIcon={true}
                  locale={localeString('es')}
                  monthNavigator={true}
                  yearNavigator={true}
                  icon="fa fa-calendar"
                  yearRange={`2010:${moment().get('year')}`}
                  dateFormat={FORMAT_DATE_CALENDAR}
                  placeholder={FORMAT_DATE_MOMENT}
                  readOnlyInput
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Fecha Fin:</label>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <CalendarComponent
                  value={this.state.fechaFin}
                  onChange={(e) => this.setState({ fechaFin: e.value })}
                  showIcon={true}
                  locale={localeString('es')}
                  monthNavigator={true}
                  yearNavigator={true}
                  icon="fa fa-calendar"
                  yearRange={`2010:${moment().get('year')}`}
                  dateFormat={FORMAT_DATE_CALENDAR}
                  placeholder={FORMAT_DATE_MOMENT}
                  readOnlyInput
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="ui-lg-12 ui-g-12 ui-md-12 ui-sm-12 padding-corto">
              <div className="ui-lg-1 ui-g-1 ui-md-1 ui-sm-12 padding-corto">
                <label>Destino:</label>
              </div>
              <div className="ui-lg-10 ui-g-10 ui-md-10 ui-sm-12 padding-corto">
                <Select
                  cacheOptions
                  defaultOptions
                  value={this.state.rutaDestino}
                  options={this.state.rutaDestinos}
                  onChange={(option) => this.setState({ rutaDestino: option })}
                  className="select-custom"
                  classNamePrefix="select-react"
                  noOptionsMessage={() => 'No hay destinos'}
                  placeholder="Buscar destino..."
                />
              </div>
            </div>
            <div className="ui-lg-12 ui-g-12 ui-md-12 ui-sm-12 padding-corto">
              <div className="ui-lg-2 ui-g-2 ui-md-2 ui-sm-12 padding-corto">
                <label>Incluir remitente:</label>
              </div>
              <div className="ui-lg-10 ui-g-10 ui-md-10 ui-sm-12 padding-corto">
                <input name="isGoing" type="checkbox" checked={this.state.isRemitente} onChange={() => this.setState({ isRemitente: !this.state.isRemitente })} />
              </div>
            </div>
            <div className="ui-lg-2 ui-g-2 ui-md-2 ui-sm-12">
              <div className="ui-lg-12 ui-g-12 ui-md-12 ui-sm-12 padding-corto">
                <Button label="Generar" onClick={this.onClickGenerarReporte} />
              </div>
            </div>
          </div>
        </div>
        <SpinnerLoadingComponent isDisplay={this.state.isLoading} />
      </div>
    );
  }
}
