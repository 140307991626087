/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import { getSuperagent, postSuperagent, putSuperagent, deleteSuperagent } from './SuperagentConfig';

class ClienteREST {
  listarClientes = ({ page = 0, limit = 100, search }) => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent(`/api/clientes?page=${page}&limit=${limit}&search=${search}`).end((error, response) => {
        // eslint-disable-next-line no-unused-expressions
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  listarAllClientes = () => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent(`/api/clientes`).end((error, response) => {
        // eslint-disable-next-line no-unused-expressions
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  crearCliente = ({ nombres, apellidos, rucCi, callePrincipal, calleSecundaria, numeroCasa, telefono, correo, referencia, provinciaId, cantonId, parroquiaId }) => {
    const promesa = new Promise((resolve, reject) => {
      postSuperagent('/api/clientes', {
        nombres,
        apellidos,
        rucCi,
        callePrincipal,
        calleSecundaria,
        numeroCasa,
        telefono,
        correo,
        referencia,
        provinciaId,
        cantonId,
        parroquiaId,
      }).end((error, response) => {
        // eslint-disable-next-line no-unused-expressions
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  actualizarCliente = ({ id, nombres, apellidos, rucCi, callePrincipal, calleSecundaria, numeroCasa, telefono, correo, referencia, provinciaId, cantonId, parroquiaId }) => {
    const promesa = new Promise((resolve, reject) => {
      putSuperagent(`/api/clientes/${id}`, {
        nombres,
        apellidos,
        rucCi,
        callePrincipal,
        calleSecundaria,
        numeroCasa,
        telefono,
        correo,
        referencia,
        provinciaId,
        cantonId,
        parroquiaId,
      }).end((error, response) => {
        // eslint-disable-next-line no-unused-expressions
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  eliminarCliente = (id) => {
    const promesa = new Promise((resolve, reject) => {
      deleteSuperagent(`/api/clientes/${id}`).end((error, response) => {
        // eslint-disable-next-line no-unused-expressions
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };
}

export default new ClienteREST();
