/* eslint-disable class-methods-use-this */
/**
 * @author Wilson Guiñanzaca <wilson_g18@hotmail.com>
 * 16-Oct-2019
 */
import React from "react";
import _ from "lodash";
import { Button } from "primereact/components/button/Button";
import { InputText } from "primereact/components/inputtext/InputText";
import DataTableCrudComponent from "./DataTableCrudComponent";

export default class DataTableCrudEditComponent extends DataTableCrudComponent {
  initialState() {
    return {
      isShowForm: false,
      editData: null,
    };
  }

  onCloseDialogForm = (isReloadList) => {
    this.setState({ isShowForm: false, editData: null }, () => {
      isReloadList && this.getDataList();
    });
  };

  _onClickNew() {
    this.setState({ isShowForm: true });
  }

  _onEditColumnClick(rowData) {
    this.setState({ isShowForm: true, editData: rowData });
  }

  onSearchData = _.debounce((value) => {
    this.setState({ searchServer: value }, () => {
      this.getDataList();
    });
  }, 500);

  /**
   * Crear buscador
   */
  createSearchNew() {
    return (
      <div className="ui-grid ui-fluid">
        <div className="ui-lg-7 ui-g-7 ui-md-6 ui-sm-12">
          {this.hasSearch ? (
            <div className="ui-inputgroup">
              <InputText
                type="search"
                onInput={(e) => this.onSearchData(e.target.value)}
                placeholder="Buscar"
                size="50"
              />
              <Button icon="fa fa-search" />
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className="ui-lg-5 ui-g-5 ui-md-6 ui-sm-12"
          style={{ textAlign: "right" }}
        >
          {this.hasNew ? (
            <Button
              label={this.labelNew}
              icon="fa fa-plus"
              className="ui-button-success"
              onClick={(e) => this._onClickNew()}
              style={{ width: "auto" }}
              disabled={this.getDisabledButtonCreate()}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
