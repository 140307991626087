/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from "../AppDispatcher";
import * as AppConstants from "../AppConstants";
import CantonREST from "../api/CantonREST";
import ErrorHandler from "../utilities/ErrorHandler";

class CantonActions {
  listarCantonesPorProvincia = ({ provinciaId, page, limit, search }) => {
    CantonREST.listarCantonesPorProvincia({ provinciaId, page, limit, search })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.canton.GET_LIST,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearCanton = ({ descripcion, provinciaId }) => {
    CantonREST.crearCanton({ descripcion, provinciaId })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.canton.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  actualizarCanton = ({ id, descripcion, provinciaId }) => {
    CantonREST.actualizarCanton({ id, descripcion, provinciaId })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.canton.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarCanton = (id) => {
    CantonREST.eliminarCanton(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.canton.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new CantonActions();
