/* eslint no-control-regex: 0 */
/* eslint no-useless-escape: 0 */
/**
 * @file Validaciones de atributos de un formulario
 * @author Wilson Guiñanzaca <wilson_g18@hotmail.com>
 * 13-Mar-2019
 */
import _ from "lodash";

export const defaultOptions = {
  forceUpdate: false,
  selector: "name",
  labelFormatter: "sentenceCase",
  valid: Function.prototype,
  invalid: Function.prototype,
};

const defaultPatterns = {
  // Matches any digit(s) (i.e. 0-9)
  digits: /^\d+$/,

  // Matched any number (e.g. 100.000)
  number: /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,

  // Matches a valid email address (e.g. mail@example.com)
  email: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,

  // Mathes any valid url (e.g. http://www.xample.com)
  url: /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
};

const defaultMessages = {
  required: "{0} is required",
  acceptance: "{0} must be accepted",
  min: "{0} must be greater than or equal to {1}",
  max: "{0} must be less than or equal to {1}",
  range: "{0} must be between {1} and {2}",
  length: "{0} must be {1} characters",
  isEmpty: "{0} must be at least {1} characters",
  minLength: "{0} must be at least {1} characters",
  maxLength: "{0} must be at most {1} characters",
  rangeLength: "{0} must be between {1} and {2} characters",
  oneOf: "{0} must be one of: {1}",
  equalTo: "{0} must be the same as {1}",
  pattern: "{0} must be a valid {1}",
};
// Use native trim when defined
const trim = String.prototype.trim
  ? function (text) {
      return text === null ? "" : String.prototype.trim.call(text);
    }
  : function (text) {
      const trimLeft = /^\s+/;
      const trimRight = /\s+$/;

      return text === null
        ? ""
        : text.toString().replace(trimLeft, "").replace(trimRight, "");
    };

// Determines whether or not a value is a number
const isNumber = (value) => {
  return (
    _.isNumber(value) ||
    (_.isString(value) && value.match(defaultPatterns.number))
  );
};

// Determines whether or not a value is empty
const hasValue = (value) => {
  return !(
    _.isNull(value) ||
    _.isUndefined(value) ||
    (_.isString(value) && trim(value) === "") ||
    (_.isArray(value) && _.isEmpty(value))
  );
};

const formatLabel = (attrName, state) => {
  return defaultLabelFormatters[defaultOptions.labelFormatter](attrName, state);
};

const format = function () {
  const args = Array.prototype.slice.call(arguments);
  const text = args.shift();
  return text.replace(/\{(\d+)\}/g, function (match, number) {
    return typeof args[number] !== "undefined" ? args[number] : match;
  });
};

export const defaultValidators = {
  // Function validator
  // Lets you implement a custom function used for validation
  /* {
        fn: function (value, attr, attrs) {
            if (true){
                return 'error msg';
            }
        }
    } */
  fn(value, attr, fn, state, computed) {
    if (_.isString(fn)) {
      fn = state[fn];
    }
    return fn.call(state, value, attr, computed);
  },

  // Required validator
  // Validates if the attribute is required or not
  required(value, attr, required, state, computed) {
    const isRequired = _.isFunction(required)
      ? required.call(state, value, attr, computed)
      : required;
    if (!isRequired && !hasValue(value)) {
      return false; // overrides all other validators
    }
    if (isRequired && !hasValue(value)) {
      return format(defaultMessages.required, formatLabel(attr, state));
    }
  },

  // Acceptance validator
  // Validates that something has to be accepted, e.g. terms of use
  // `true` or 'true' are valid
  acceptance(value, attr, accept, state) {
    if (value !== "true" && (!_.isBoolean(value) || value === false)) {
      return format(defaultMessages.acceptance, formatLabel(attr, state));
    }
  },

  // Min validator
  // Validates that the value has to be a number and equal to or greater than
  // the min value specified
  min(value, attr, minValue, state) {
    if (!isNumber(value) || value < minValue) {
      return format(defaultMessages.min, formatLabel(attr, state), minValue);
    }
  },

  // Max validator
  // Validates that the value has to be a number and equal to or less than
  // the max value specified
  max(value, attr, maxValue, state) {
    if (!isNumber(value) || value > maxValue) {
      return format(defaultMessages.max, formatLabel(attr, state), maxValue);
    }
  },

  // Range validator
  // Validates that the value has to be a number and equal to or between
  // the two numbers specified
  range(value, attr, range, state) {
    if (!isNumber(value) || value < range[0] || value > range[1]) {
      return format(
        defaultMessages.range,
        formatLabel(attr, state),
        range[0],
        range[1]
      );
    }
  },

  // Length validator
  // Validates that the value has to be a string with length equal to
  // the length value specified
  length(value, attr, length, state) {
    if (!hasValue(value) || trim(value).length !== length) {
      return format(defaultMessages.length, formatLabel(attr, state), length);
    }
  },
  // isEmpty validator
  // Valida si el array esta vacio y de no estarlo valida que el atributo isChecked al menos uno este true
  // the length value specified
  isEmpty(value, attr, length, state) {
    if (_.isEmpty(value)) {
      return format(defaultMessages.isEmpty, formatLabel(attr, state), 0);
    }
    let atLeast = false;
    value.map((item) => {
      if (item.isChecked) atLeast = true;
      return atLeast;
    });
    return !atLeast
      ? format(defaultMessages.isEmpty, formatLabel(attr, state), 0)
      : false;
  },

  // Min length validator
  // Validates that the value has to be a string with length equal to or greater than
  // the min length value specified
  minLength(value, attr, minLength, state) {
    if (!hasValue(value) || trim(value).length < minLength) {
      return format(
        defaultMessages.minLength,
        formatLabel(attr, state),
        minLength
      );
    }
  },

  // Max length validator
  // Validates that the value has to be a string with length equal to or less than
  // the max length value specified
  maxLength(value, attr, maxLength, state) {
    if (!hasValue(value) || trim(value).length > maxLength) {
      return format(
        defaultMessages.maxLength,
        formatLabel(attr, state),
        maxLength
      );
    }
  },

  // Range length validator
  // Validates that the value has to be a string and equal to or between
  // the two numbers specified
  rangeLength(value, attr, range, state) {
    if (
      !hasValue(value) ||
      trim(value).length < range[0] ||
      trim(value).length > range[1]
    ) {
      return format(
        defaultMessages.rangeLength,
        formatLabel(attr, state),
        range[0],
        range[1]
      );
    }
  },

  // One of validator
  // Validates that the value has to be equal to one of the elements in
  // the specified array. Case sensitive matching
  oneOf(value, attr, values, state) {
    if (!_.include(values, value)) {
      return format(
        defaultMessages.oneOf,
        formatLabel(attr, state),
        values.join(", ")
      );
    }
  },

  // Equal to validator
  // Validates that the value has to be equal to the value of the attribute
  // with the name specified
  equalTo(value, attr, equalTo, state, computed) {
    if (value !== computed[equalTo]) {
      return format(
        defaultMessages.equalTo,
        formatLabel(attr, state),
        formatLabel(equalTo, state)
      );
    }
  },

  // Pattern validator
  // Validates that the value has to match the pattern specified.
  // Can be a regular expression or the name of one of the built in patterns
  pattern(value, attr, pattern, state) {
    if (
      !hasValue(value) ||
      !value.toString().match(defaultPatterns[pattern] || pattern)
    ) {
      return format(defaultMessages.pattern, formatLabel(attr, state), pattern);
    }
  },
};

// Label formatters
// ----------------

// Label formatters are used to convert the attribute name
// to a more human friendly label when using the built in
// error messages.
const defaultLabelFormatters = {
  // Returns the attribute name with applying any formatting
  none(attrName) {
    return attrName;
  },

  // Converts attributeName or attribute_name to Attribute name
  sentenceCase(attrName) {
    return attrName
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (match, index) {
        return index === 0 ? match.toUpperCase() : ` ${match.toLowerCase()}`;
      })
      .replace(/_/g, " ");
  },

  // Looks for a label configured on the model and returns it
  //
  //      var Model = Backbone.Model.extend({
  //        validation: {
  //          someAttribute: {
  //            required: true
  //          }
  //        },
  //
  //        labels: {
  //          someAttribute: 'Custom label'
  //        }
  //      });
  label(attrName, model) {
    return (
      (model.labels && model.labels[attrName]) ||
      defaultLabelFormatters.sentenceCase(attrName, model)
    );
  },
};

// Flattens an object
// eg:
//
//     var o = {
//       address: {
//         street: 'Street',
//         zip: 1234
//       }
//     };
//
// becomes:
//
//     var o = {
//       'address.street': 'Street',
//       'address.zip': 1234
//     };
export const flatten = (obj, into, prefix) => {
  into = into || {};
  prefix = prefix || "";

  _.each(obj, function (val, key) {
    if (obj.hasOwnProperty(key)) {
      if (
        val &&
        typeof val === "object" &&
        !(val instanceof Array || val instanceof Date || val instanceof RegExp)
      ) {
        flatten(val, into, `${prefix + key}.`);
      } else {
        into[prefix + key] = val;
      }
    }
  });
  return into;
};

/**
 * Returns an object with undefined properties for all
 * attributes on the state that has defined one or more
 * validation rules.
 * @param  {object} validations
 * @return {object} attributes
 */
export const getValidatedAttrs = (validation) => {
  return _.reduce(
    _.keys(validation || {}),
    function (memo, key) {
      memo[key] = void 0;
      return memo;
    },
    {}
  );
};

/**
 * Looks on the state for validations for a specified
 * attribute. Returns an array of any validators defined,
 * or an empty array if none is defined.
 * @param  {object} validation
 * @param  {string} attr
 * @return {array} validations
 */
export const getValidators = (validation, attr) => {
  let attrValidationSet = validation ? validation[attr] || {} : {};

  // If the validator is a function or a string, wrap it in a function validator
  if (_.isFunction(attrValidationSet) || _.isString(attrValidationSet)) {
    attrValidationSet = {
      fn: attrValidationSet,
    };
  }

  // Stick the validator object into an array
  if (!_.isArray(attrValidationSet)) {
    attrValidationSet = [attrValidationSet];
  }

  // Reduces the array of validators into a new array with objects
  // with a validation method to call, the value to validate against
  // and the specified error message, if any
  return _.reduce(
    attrValidationSet,
    function (memo, attrValidation) {
      _.each(_.without(_.keys(attrValidation), "msg"), function (validator) {
        memo.push({
          fn: defaultValidators[validator],
          val: attrValidation[validator],
          msg: attrValidation.msg,
        });
      });
      return memo;
    },
    []
  );
};
