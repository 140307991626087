/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from "react";
import { Column } from "primereact/components/column/Column";
import { getUUID } from "../../../components/functions/globals";
import DataTableCrudEditComponent from "../../../components/commons/table/DataTableCrudEditComponent";
import ProductoActions from "../../../flux/actions/ProductoActions";
import ProductoStore from "../../../flux/stores/ProductoStore";
import ProductoFormComponent from "./ProductoFormComponent";
import ErrorStore from "../../../flux/stores/ErrorStore";

export default class ProductoListComponent extends DataTableCrudEditComponent {
  sectionName = "producto";
  labelList = "Listado de Productos";
  labelNew = "Nueva Producto";
  labelTotal = "Producto";
  classNameContentTable = "ui-xl-10 ui-lg-12 ui-g-12 ui-md-12 ui-sm-12";
  hasPagination = true;
  uuidProducto = getUUID();

  handlesBind() {}

  componentDidMount() {
    super.componentDidMount();
    ProductoStore.addGetProductosListener(
      this._onGetProductosListener,
      this.uuidProducto
    );
    ProductoStore.addDeleteProductoListener(this._onDeleteProductoListener);
    ErrorStore.addErrorListener(this._onErrorListerner);
    this.getDataList();
  }

  componentWillUnmount() {
    ProductoStore.removeGetProductosListener(
      this._onGetProductosListener,
      this.uuidProducto
    );
    ProductoStore.removeDeleteProductoListener(this._onDeleteProductoListener);
    ErrorStore.removeErrorListener(this._onErrorListerner);
  }

  _onGetProductosListener = () => {
    const data = ProductoStore.getProductos(this.uuidProducto);
    this.setState({
      list: data.rows,
      totalRecords: data.count,
      search: null,
      isLoading: false,
    });
  };

  getDataList() {
    const { page, rows, searchServer } = this.state;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        ProductoActions.listarProductos(
          { page, limit: rows, search: searchServer },
          this.uuidProducto
        );
      }
    );
  }

  textQuestionDelete() {
    return (
      "¿Estas seguro de eliminar un producto: " +
      (this.state.rowDelete ? this.state.rowDelete.descripcion : "") +
      "?"
    );
  }
  sendDeleteOk() {
    ProductoActions.eliminarProducto(this.state.rowDelete[this.attrId]);
  }

  _onDeleteProductoListener = () => {
    const data = ProductoStore.getProducto();
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: "success",
        detail: "Producto eliminada",
      });
      this.onSuccessDelete();
    } else {
      this.setState({ disableButtonPopupDelete: false });
      window.growlAdmin.show({
        severity: "error",
        detail: "Error al eliminar la producto",
      });
    }
  };

  _onErrorListerner = () => {
    window.growlAdmin.show({
      severity: "error",
      life: 15000,
      detail:
        "Problemas en la comunicación con el servidor, comuníquese con el administrador.",
    });
    this.setState({ isLoading: false });
    this.onCloseDialogForm &&
      this.state.isShowForm === true &&
      this.onCloseDialogForm(true);
  };

  createColumns() {
    return [
      <Column key="0" field="descripcion" header="Descripción" />,
      <Column key="1" field="precio" header="Precio" />,
    ];
  }

  createAdditionalDialog() {
    return this.state.isShowForm ? (
      <ProductoFormComponent
        editData={this.state.editData}
        onCloseDialogForm={this.onCloseDialogForm}
      />
    ) : (
      ""
    );
  }
}
