/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from "../AppDispatcher";
import * as AppConstants from "../AppConstants";
import ClienteREST from "../api/ClienteREST";
import ErrorHandler from "../utilities/ErrorHandler";

class ClienteActions {
  listarClientes = ({ page, limit, search }, componentUID) => {
    ClienteREST.listarClientes({ page, limit, search })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.cliente.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  listarAllClientes = (componentUID) => {
    ClienteREST.listarAllClientes()
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.cliente.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearCliente = ({
    nombres,
    apellidos,
    rucCi,
    callePrincipal,
    calleSecundaria,
    numeroCasa,
    telefono,
    correo,
    referencia,
    provinciaId,
    cantonId,
    parroquiaId,
  }) => {
    ClienteREST.crearCliente({
      nombres,
      apellidos,
      rucCi,
      callePrincipal,
      calleSecundaria,
      numeroCasa,
      telefono,
      correo,
      referencia,
      provinciaId,
      cantonId,
      parroquiaId,
    })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.cliente.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  actualizarCliente = ({
    id,
    nombres,
    apellidos,
    rucCi,
    callePrincipal,
    calleSecundaria,
    numeroCasa,
    telefono,
    correo,
    referencia,
    provinciaId,
    cantonId,
    parroquiaId,
  }) => {
    ClienteREST.actualizarCliente({
      id,
      nombres,
      apellidos,
      rucCi,
      callePrincipal,
      calleSecundaria,
      numeroCasa,
      telefono,
      correo,
      referencia,
      provinciaId,
      cantonId,
      parroquiaId,
    })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.cliente.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarCliente = (id) => {
    ClienteREST.eliminarCliente(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.cliente.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new ClienteActions();
