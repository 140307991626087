/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let producto = null;
const productos = {};

const GET_PRODUCTOS_LIST_EVENT = "GET_PRODUCTOS_LIST_EVENT";
const CREATE_PRODUCTO_EVENT = "CREATE_PRODUCTO_EVENT";
const UPDATE_PRODUCTO_EVENT = "UPDATE_PRODUCTO_EVENT";
const DELETE_PRODUCTO_EVENT = "DELETE_PRODUCTO_EVENT";

class ProductoStore extends EventEmitter {
  getProducto = () => producto;

  setProducto = (_producto) => {
    producto = _producto;
  };

  getProductos = (componentUID) => {
    const datos = productos[componentUID];
    delete productos[componentUID];
    return datos;
  };

  setProductos = (componentUID, _productos) => {
    productos[componentUID] = _productos;
  };

  emitGetProductos(componentUID) {
    this.emit(`${GET_PRODUCTOS_LIST_EVENT}_${componentUID}`);
  }

  addGetProductosListener(callback, componentUID) {
    this.on(`${GET_PRODUCTOS_LIST_EVENT}_${componentUID}`, callback);
  }

  removeGetProductosListener(callback, componentUID) {
    this.removeListener(
      `${GET_PRODUCTOS_LIST_EVENT}_${componentUID}`,
      callback
    );
  }

  emitCreateProducto() {
    this.emit(CREATE_PRODUCTO_EVENT);
  }

  addCreateProductoListener(callback) {
    this.on(CREATE_PRODUCTO_EVENT, callback);
  }

  removeCreateProductoListener(callback) {
    this.removeListener(CREATE_PRODUCTO_EVENT, callback);
  }

  emitUpdateProducto() {
    this.emit(UPDATE_PRODUCTO_EVENT);
  }

  addUpdateProductoListener(callback) {
    this.on(UPDATE_PRODUCTO_EVENT, callback);
  }

  removeUpdateProductoListener(callback) {
    this.removeListener(UPDATE_PRODUCTO_EVENT, callback);
  }

  emitDeleteProducto() {
    this.emit(DELETE_PRODUCTO_EVENT);
  }

  addDeleteProductoListener(callback) {
    this.on(DELETE_PRODUCTO_EVENT, callback);
  }

  removeDeleteProductoListener(callback) {
    this.removeListener(DELETE_PRODUCTO_EVENT, callback);
  }
}

const productoStore = new ProductoStore();

productoStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.producto.GET_LIST:
      const { componentUID } = action.payload;
      if (componentUID) {
        productoStore.setProductos(componentUID, action.payload.data);
        productoStore.emitGetProductos(componentUID);
      }
      break;
    case AppConstants.producto.CREATE:
      productoStore.setProducto(action.payload);
      productoStore.emitCreateProducto();
      break;
    case AppConstants.producto.UPDATE:
      productoStore.setProducto(action.payload);
      productoStore.emitUpdateProducto();
      break;
    case AppConstants.producto.DELETE:
      productoStore.setProducto(action.payload);
      productoStore.emitDeleteProducto();
      break;
    default:
  }
});

export default productoStore;
