/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/**
 * @file Componente de listado de datos para cruds
 * @author Wilson Guiñanzaca <wilson_g18@hotmail.com>
 * 19-Mar-2019
 */
import queryString from 'query-string';
import React, { Component } from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/components/button/Button';
import { Dialog } from 'primereact/components/dialog/Dialog';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Growl } from 'primereact/components/growl/Growl';
import { Paginator } from 'primereact/components/paginator/Paginator';
import { SpinnerLoadingComponent } from '../spinner/SpinnerLoadingComponent';
import { verifyPermissionByAction } from '../../../conf/security';

export default class DataTableCrudComponent extends Component {
  sectionName = null;

  sortField = null;

  countListener = 0;

  totalListener = 0;

  labelList = null;

  attrId = 'id';

  hasDelete = false;

  hasEdit = false;

  hasSearch = true;

  hasNew = true;

  labelNew = '';

  hasActionButton = true;

  hasPagination = false;

  styleColumnAction = {};

  classNameContentTable = 'ui-xl-9 ui-lg-11 ui-g-12 ui-md-12 ui-sm-12';

  widthContent = 'auto';

  /**
   * Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      ...{
        list: [],
        first: 0,
        page: 0,
        rows: 20,
        totalRecords: 0,
        search: null,
        searchServer: null,
        isLoading: false,
        isVisiblePopupDelete: false,
        disableButtonPopupDelete: false,
      },
      ...this.initialState(props),
    };
    this._onRedirectClick = this._onRedirectClick.bind(this);
    this._onEditColumnClick = this._onEditColumnClick.bind(this);
    this._onShowHideDialogDelete = this._onShowHideDialogDelete.bind(this);
    this._onClickOkDelete = this._onClickOkDelete.bind(this);
    this._onClickNew = this._onClickNew.bind(this);
    this.handlesBind && this.handlesBind();
  }

  initialState() {
    return {};
  }

  componentDidMount() {
    // document.title = this.labelList;
    if (this.sectionName) {
      this.hasNew = verifyPermissionByAction(this.sectionName, 'CREATE');
      this.hasEdit = verifyPermissionByAction(this.sectionName, 'EDIT');
      this.hasDelete = verifyPermissionByAction(this.sectionName, 'DELETE');
    }
  }

  _onEditColumnClick(rowData) {
    this._onRedirectClick(`/edit?id=${rowData[this.attrId]}`);
  }

  /**
   * Redireccionar a otra ruta
   */
  _onRedirectClick(path) {
    this.props.history.push(this.props.location.pathname + path);
  }

  /**
   * Ejecutar eliminar
   */
  _onClickOkDelete() {
    this.setState({ disableButtonPopupDelete: true }, () => {
      this.sendDeleteOk && this.sendDeleteOk();
    });
  }

  sendDeleteOk() {
    this._onShowHideDialogDelete(false);
  }

  onSuccessDelete() {
    this.setState(
      {
        isVisiblePopupDelete: false,
      },
      () => {
        this.getDataList && this.getDataList();
      },
    );
  }

  /**
   * Mostrar u ocultar popup
   */
  _onShowHideDialogDelete(isShow, data) {
    this.setState({
      isVisiblePopupDelete: isShow,
      rowDelete: data || null,
      disableButtonPopupDelete: false,
    });
  }

  textQuestionDelete() {
    return '¿Estas seguro de eliminar?';
  }

  createPopupDelete() {
    const footerDialog = (
      <div>
        <Button label="SI" icon="fa fa-check" onClick={() => this._onClickOkDelete()} disabled={this.state.disableButtonPopupDelete} />
        <Button label="NO" className="ui-button-warning" icon="fa fa-times" onClick={() => this._onShowHideDialogDelete(false)} disabled={this.state.disableButtonPopupDelete} />
      </div>
    );
    return (
      <Dialog header="Confirmar" footer={footerDialog} visible={this.state.isVisiblePopupDelete} style={{ width: '400px' }} modal onHide={() => this._onShowHideDialogDelete(false)} closable={false}>
        {this.textQuestionDelete()}
      </Dialog>
    );
  }

  getParamsCreateUrl() {
    return null;
  }

  /**
   * Redireccionar para crear
   */
  _onClickNew() {
    const params = this.getParamsCreateUrl();
    this._onRedirectClick(`/create${params ? `?${queryString.stringify(params)}` : ''}`);
  }

  /**
   * Habilitar o Deshabilitar el boton de crear
   */
  getDisabledButtonCreate() {
    return false;
  }

  onPage(event) {
    // console.log('onPage ', event);
    if (this.state.first !== event.first) {
      this.setState(
        {
          loading: true,
          first: event.first,
          page: event.page,
        },
        () => {
          this.getDataList();
        },
      );
    }
  }

  /**
   * Botones por listado
   * @param {*} rowData
   */
  createColumnAction() {
    const actionTemplate = (rowData) => {
      return (
        <div>
          {this.hasEdit ? (
            <Button type="button" icon="fa fa-pencil-alt" title="Editar" className="ui-button-success" onClick={() => this._onEditColumnClick(rowData)} style={{ marginRight: '5px' }} />
          ) : (
            ''
          )}
          {this.hasDelete ? <Button type="button" icon="fa fa-trash" title="Eliminar" className="ui-button-danger" onClick={() => this._onShowHideDialogDelete(true, rowData)} /> : ''}
        </div>
      );
    };
    return (
      <Column
        body={actionTemplate}
        style={{
          ...{ textAlign: 'center', width: '120px' },
          ...this.styleColumnAction,
        }}
      />
    );
  }

  /**
   * Crear buscador
   */
  createSearchNew() {
    const enableSearch = !((this.state.search && this.state.search.length > 0) || (this.state.list && this.state.list.length > 0));
    return (
      <div className="ui-grid ui-fluid">
        <div className="ui-lg-7 ui-g-7 ui-md-6 ui-sm-12">
          {this.hasSearch ? (
            <div className="ui-inputgroup">
              <InputText type="search" onInput={(e) => this.setState({ search: e.target.value })} placeholder="Buscar" size="50" disabled={enableSearch} />
              <Button icon="fa fa-search" disabled={enableSearch} />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="ui-lg-5 ui-g-5 ui-md-6 ui-sm-12" style={{ textAlign: 'right' }}>
          {this.hasNew ? <Button label={this.labelNew} icon="fa fa-plus" onClick={() => this._onClickNew()} style={{ width: 'auto' }} disabled={this.getDisabledButtonCreate()} /> : ''}
        </div>
      </div>
    );
  }

  createFooterTable() {
    if (this.hasPagination) {
      return this.state.totalRecords ? `Total ${this.state.totalRecords} ${this.labelTotal}` : `Sin ${this.labelTotal}`;
    }
    const totalRow = this.state.list ? this.state.list.length : 0;
    return totalRow ? `Total ${totalRow} ${this.labelTotal}` : `Sin ${this.labelTotal}`;
  }

  hideSpinnerLoading() {
    this.countListener >= this.totalListener && this.setState({ isLoading: false });
  }

  headerGroupTable() {
    return null;
  }

  createPagination() {
    return this.state.totalRecords ? <Paginator first={this.state.first} rows={this.state.rows} totalRecords={this.state.totalRecords} onPageChange={(e) => this.onPage(e)} /> : '';
  }

  /**
   * Crear componente html
   * @return {object} Componente html
   */
  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div className="ui-g">
          <Growl ref={(el) => (this.growl = el)} />
          <div className={this.classNameContentTable}>
            <div className="content-section">
              <h1>{this.labelList}</h1>
            </div>
            {this.createFilter ? this.createFilter() : ''}
            {this.hasSearch || this.hasNew ? this.createSearchNew() : ''}
            <div className="content-table-crud" style={{ width: this.widthContent }}>
              <div className="ui-grid">
                <div className="ui-lg-12 ui-g-12 ui-md-12 ui-sm-12">
                  {this.hasActionButton ? (
                    <DataTable
                      style={{ fontSize: 'smaller' }}
                      dataKey={this.attrId}
                      value={this.state.list}
                      footer={this.createFooterTable()}
                      responsive
                      globalFilter={this.state.search}
                      emptyMessage="No se han encontrado datos."
                      sortField={this.sortField}
                      headerColumnGroup={this.headerGroupTable()}
                    >
                      {this.createColumns()}
                      {this.createColumnAction()}
                    </DataTable>
                  ) : (
                    <DataTable
                      style={{ fontSize: 'smaller' }}
                      dataKey={this.attrId}
                      value={this.state.list}
                      footer={this.createFooterTable()}
                      responsive
                      globalFilter={this.state.search}
                      emptyMessage="No se han encontrado datos."
                      headerColumnGroup={this.headerGroupTable()}
                    >
                      {this.createColumns()}
                    </DataTable>
                  )}
                  {this.hasPagination ? this.createPagination() : null}
                </div>
              </div>
            </div>
            {this.createAdditionalAfterTable ? this.createAdditionalAfterTable() : ''}
          </div>
        </div>
        {this.hasDelete ? this.createPopupDelete() : ''}
        {this.createAdditionalDialog ? this.createAdditionalDialog() : ''}
        <SpinnerLoadingComponent isDisplay={this.state.isLoading} />
      </div>
    );
  }
}
