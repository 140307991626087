/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let hojaRuta = null;
let hojasRutas = [];
let hojaRutaSet = null;

const GET_HOJASRUTAS_LIST_EVENT = "GET_HOJASRUTAS_LIST_EVENT";
const CREATE_HOJARUTA_EVENT = "CREATE_HOJARUTA_EVENT";
const UPDATE_HOJARUTA_EVENT = "UPDATE_HOJARUTA_EVENT";
const DELETE_HOJARUTA_EVENT = "DELETE_HOJARUTA_EVENT";
const SET_HOJARUTA_EVENT = "SET_HOJARUTA_EVENT";

class HojaRutaStore extends EventEmitter {
  getHojaRuta = () => hojaRuta;

  setHojaRuta = (_hojaRuta) => {
    hojaRuta = _hojaRuta;
  };

  getHojaRutaSet = () => hojaRutaSet;

  setHojaRutaSet = (_hojaRutaSet) => {
    hojaRutaSet = _hojaRutaSet;
  };

  getHojasRutas = () => hojasRutas;

  setHojasRutas = (_hojasRutas) => {
    hojasRutas = _hojasRutas;
  };

  emitGetHojasRutas() {
    this.emit(GET_HOJASRUTAS_LIST_EVENT);
  }

  addGetHojasRutasListener(callback) {
    this.on(GET_HOJASRUTAS_LIST_EVENT, callback);
  }

  removeGetHojasRutasListener(callback) {
    this.removeListener(GET_HOJASRUTAS_LIST_EVENT, callback);
  }

  emitCreateHojaRuta() {
    this.emit(CREATE_HOJARUTA_EVENT);
  }

  addCreateHojaRutaListener(callback) {
    this.on(CREATE_HOJARUTA_EVENT, callback);
  }

  removeCreateHojaRutaListener(callback) {
    this.removeListener(CREATE_HOJARUTA_EVENT, callback);
  }

  emitSetHojaRuta() {
    this.emit(SET_HOJARUTA_EVENT);
  }

  addSetHojaRutaListener(callback) {
    this.on(SET_HOJARUTA_EVENT, callback);
  }

  removeSetHojaRutaListener(callback) {
    this.removeListener(SET_HOJARUTA_EVENT, callback);
  }

  emitUpdateHojaRuta() {
    this.emit(UPDATE_HOJARUTA_EVENT);
  }

  addUpdateHojaRutaListener(callback) {
    this.on(UPDATE_HOJARUTA_EVENT, callback);
  }

  removeUpdateHojaRutaListener(callback) {
    this.removeListener(UPDATE_HOJARUTA_EVENT, callback);
  }

  emitDeleteHojaRuta() {
    this.emit(DELETE_HOJARUTA_EVENT);
  }

  addDeleteHojaRutaListener(callback) {
    this.on(DELETE_HOJARUTA_EVENT, callback);
  }

  removeDeleteHojaRutaListener(callback) {
    this.removeListener(DELETE_HOJARUTA_EVENT, callback);
  }
}

const hojaRutaStore = new HojaRutaStore();

hojaRutaStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.hojaruta.GET_LIST:
      hojaRutaStore.setHojasRutas(action.payload);
      hojaRutaStore.emitGetHojasRutas();
      break;
    case AppConstants.hojaruta.CREATE:
      hojaRutaStore.setHojaRuta(action.payload);
      hojaRutaStore.emitCreateHojaRuta();
      break;
    case AppConstants.hojaruta.SET:
      hojaRutaStore.setHojaRutaSet(action.payload);
      hojaRutaStore.emitSetHojaRuta();
      break;
    case AppConstants.hojaruta.UPDATE:
      hojaRutaStore.setHojaRuta(action.payload);
      hojaRutaStore.emitUpdateHojaRuta();
      break;
    case AppConstants.hojaruta.DELETE:
      hojaRutaStore.setHojaRuta(action.payload);
      hojaRutaStore.emitDeleteHojaRuta();
      break;
    default:
  }
});

export default hojaRutaStore;
