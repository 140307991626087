/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from '../AppDispatcher';
import * as AppConstants from '../AppConstants';
import GuiaREST from '../api/GuiaREST';
import ErrorHandler from '../utilities/ErrorHandler';

class GuiaActions {
  listarGuias = ({ guiaNumero, remitenteId, destinatarioId, rutaOrigenId, rutaDestinoId, fechaInicio, fechaFin, page, limit }) => {
    GuiaREST.listarGuias({
      guiaNumero,
      remitenteId,
      destinatarioId,
      rutaOrigenId,
      rutaDestinoId,
      fechaInicio,
      fechaFin,
      page,
      limit,
    })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.guia.GET_LIST,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearGuia = (data) => {
    GuiaREST.crearGuia(data)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.guia.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  setGuia = (data) => {
    AppDispatcher.dispatch({
      actionType: AppConstants.guia.SET,
      payload: data,
    });
  };

  actualizarGuia = ({ id, recibeNombre, recibeCedula, recibeFecha, recibeHumedad, recibeTemperatura, observacion, isCredito }) => {
    GuiaREST.actualizarGuia({
      id,
      recibeNombre,
      recibeCedula,
      recibeFecha,
      recibeHumedad,
      recibeTemperatura,
      observacion,
      isCredito,
    })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.guia.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarGuia = (id) => {
    GuiaREST.eliminarGuia(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.guia.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new GuiaActions();
