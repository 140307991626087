/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from "../AppDispatcher";
import * as AppConstants from "../AppConstants";
import ProvinciaREST from "../api/ProvinciaREST";
import ErrorHandler from "../utilities/ErrorHandler";

class ProvinciaActions {
  listarProvincias = ({ page, limit, search }) => {
    ProvinciaREST.listarProvincias({ page, limit, search })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.provincia.GET_LIST,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearProvincia = ({ descripcion }) => {
    ProvinciaREST.crearProvincia({ descripcion })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.provincia.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  actualizarProvincia = ({ id, descripcion }) => {
    ProvinciaREST.actualizarProvincia({ id, descripcion })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.provincia.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarProvincia = (id) => {
    ProvinciaREST.eliminarProvincia(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.provincia.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new ProvinciaActions();
