/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from 'react';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Message } from 'primereact/components/message/Message';
import VehiculoStore from '../../../flux/stores/VehiculoStore';
import VehiculoActions from '../../../flux/actions/VehiculoActions';
import FormDialogComponent from '../../../components/commons/form/FormDialogComponent';

export default class VehiculoFormComponent extends FormDialogComponent {
  urlList = '/admin/vehiculo';

  setInitialState(props) {
    let data = {
      id: null,
      descripcion: null,
      placa: null,
      validation: {
        descripcion: {
          required: true,
          msg: 'Ingresar la descripción',
        },
        placa: {
          required: true,
          msg: 'Ingresar la placa',
        },
      },
    };
    if (props.editData) {
      const { id, descripcion, placa } = props.editData;
      data = { ...{}, ...data, ...{ id, descripcion, placa } };
      data.titleDialog = 'Editar Vehículo';
    } else {
      data.titleDialog = 'Nuevo Vehículo';
    }
    return data;
  }

  handlesBind() {}

  componentDidMount() {
    VehiculoStore.addCreateVehiculoListener(this._onCreateVehiculoListener);
    VehiculoStore.addUpdateVehiculoListener(this._onUpdateVehiculoListener);
  }

  componentWillUnmount() {
    VehiculoStore.removeCreateVehiculoListener(this._onCreateVehiculoListener);
    VehiculoStore.removeUpdateVehiculoListener(this._onUpdateVehiculoListener);
  }

  _onUpdateVehiculoListener = () => {
    let data = VehiculoStore.getVehiculo();
    this.setState({ disableButton: false });
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: 'success',
        detail: 'Vehículo actualizado satisfactoriamente.',
      });
      this._onCancelReturnClick(true);
    } else {
      window.growlAdmin.show({
        severity: 'error',
        detail: 'Error al actualizar el Vehículo',
      });
    }
  };

  _onCreateVehiculoListener = () => {
    let data = VehiculoStore.getVehiculo();
    this.setState({ disableButton: false });
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: 'success',
        detail: 'Vehículo creado satisfactoriamente.',
      });
      this._onCancelReturnClick(true);
    } else {
      window.growlAdmin.show({
        severity: 'error',
        detail: 'Error al crear el Vehículo',
      });
    }
  };

  sendSaveUpdate() {
    let data = this.getAttributes();
    this.state[this.attrId] ? VehiculoActions.actualizarVehiculo(data) : VehiculoActions.crearVehiculo(data);
  }

  createForm() {
    const { error } = this.state;
    return (
      <div className="ui-fluid">
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="descripcion">Descripción</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="descripcion" name="descripcion" type="text" value={this.state.descripcion || ''} maxLength="64" onChange={this.handleInputChange} />
            {error.descripcion ? <Message severity="error" text={error.descripcion} /> : ''}
          </div>
        </div>
        <div className="ui-grid ui-fluid">
          <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
            <label htmlFor="via">Placa</label>
          </div>
          <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-12">
            <InputText id="placa" name="placa" type="text" value={this.state.placa || ''} maxLength="7" onChange={this.handleInputChange} />
            {error.placa ? <Message severity="error" text={error.placa} /> : ''}
          </div>
        </div>
      </div>
    );
  }
}
