/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let guia = null;
let guias = [];
let guiaSet = null;

const GET_GUIAS_LIST_EVENT = "GET_GUIAS_LIST_EVENT";
const CREATE_GUIA_EVENT = "CREATE_GUIA_EVENT";
const UPDATE_GUIA_EVENT = "UPDATE_GUIA_EVENT";
const DELETE_GUIA_EVENT = "DELETE_GUIA_EVENT";
const SET_GUIA_EVENT = "SET_GUIA_EVENT";

class GuiaStore extends EventEmitter {
  getGuia = () => guia;

  setGuia = (_guia) => {
    guia = _guia;
  };

  getGuiaSet = () => guiaSet;

  setGuiaSet = (_guiaSet) => {
    guiaSet = _guiaSet;
  };

  getGuias = () => guias;

  setGuias = (_guias) => {
    guias = _guias;
  };

  emitGetGuias() {
    this.emit(GET_GUIAS_LIST_EVENT);
  }

  addGetGuiasListener(callback) {
    this.on(GET_GUIAS_LIST_EVENT, callback);
  }

  removeGetGuiasListener(callback) {
    this.removeListener(GET_GUIAS_LIST_EVENT, callback);
  }

  emitCreateGuia() {
    this.emit(CREATE_GUIA_EVENT);
  }

  addCreateGuiaListener(callback) {
    this.on(CREATE_GUIA_EVENT, callback);
  }

  removeCreateGuiaListener(callback) {
    this.removeListener(CREATE_GUIA_EVENT, callback);
  }

  emitSetGuia() {
    this.emit(SET_GUIA_EVENT);
  }

  addSetGuiaListener(callback) {
    this.on(SET_GUIA_EVENT, callback);
  }

  removeSetGuiaListener(callback) {
    this.removeListener(SET_GUIA_EVENT, callback);
  }

  emitUpdateGuia() {
    this.emit(UPDATE_GUIA_EVENT);
  }

  addUpdateGuiaListener(callback) {
    this.on(UPDATE_GUIA_EVENT, callback);
  }

  removeUpdateGuiaListener(callback) {
    this.removeListener(UPDATE_GUIA_EVENT, callback);
  }

  emitDeleteGuia() {
    this.emit(DELETE_GUIA_EVENT);
  }

  addDeleteGuiaListener(callback) {
    this.on(DELETE_GUIA_EVENT, callback);
  }

  removeDeleteGuiaListener(callback) {
    this.removeListener(DELETE_GUIA_EVENT, callback);
  }
}

const guiaStore = new GuiaStore();

guiaStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.guia.GET_LIST:
      guiaStore.setGuias(action.payload);
      guiaStore.emitGetGuias();
      break;
    case AppConstants.guia.CREATE:
      guiaStore.setGuia(action.payload);
      guiaStore.emitCreateGuia();
      break;
    case AppConstants.guia.SET:
      guiaStore.setGuiaSet(action.payload);
      guiaStore.emitSetGuia();
      break;
    case AppConstants.guia.UPDATE:
      guiaStore.setGuia(action.payload);
      guiaStore.emitUpdateGuia();
      break;
    case AppConstants.guia.DELETE:
      guiaStore.setGuia(action.payload);
      guiaStore.emitDeleteGuia();
      break;
    default:
  }
});

export default guiaStore;
