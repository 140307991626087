import base64 from "base-64";
import { getSuperagent, postSuperagent } from "./SuperagentConfig";

class AuthenticationREST {
  login = ({ username, password }) => {
    const promesa = new Promise((resolve, reject) => {
      postSuperagent("/auth/token", { username, password }, true)
        // .set(
        //   "Authorization",
        //   `Basic ${base64.encode(`${username}:${password}`)}`
        // )
        .end((error, response) => {
          // eslint-disable-next-line no-unused-expressions
          error ? reject(error) : resolve(response.body);
        });
    });
    return promesa;
  };

  // login = ({username, password}) =>{
  //     let promesa = new Promise((resolve, reject) =>{
  //         postSuperagent('/login', { username, password }).end((error, response) => {
  //             error ? reject(error) : resolve(response);
  //         });
  //     });
  //     return promesa;
  // };

  changePassword = ({ currentPassword, newPassword }) => {
    const promesa = new Promise((resolve, reject) => {
      postSuperagent(
        "/account/change-password",
        {
          currentPassword,
          newPassword,
        },
        true
      ).end((error, response) => {
        // eslint-disable-next-line no-unused-expressions
        error ? reject(error) : resolve(response);
      });
    });
    return promesa;
  };

  account = (token) => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent("/auth/account", {}, true)
        .set("Authorization", `Bearer ${token}`)
        .end((error, response) => {
          // eslint-disable-next-line no-unused-expressions
          error ? reject(error) : resolve(response.body);
        });
    });
    return promesa;
  };

  changePassword = ({ currentPassword, newPassword }) => {
    const promesa = new Promise((resolve, reject) => {
      postSuperagent(
        "/account/change-password",
        {
          currentPassword,
          newPassword,
        },
        true
      ).end((error, response) => {
        // eslint-disable-next-line no-unused-expressions
        error ? reject(error) : resolve(response);
      });
    });
    return promesa;
  };
}

export default new AuthenticationREST();
