/* eslint-disable react/sort-comp */
import React from "react";
import $ from "jquery";
import _ from "lodash";
import {
  flatten,
  getValidatedAttrs,
  getValidators,
  defaultValidators,
} from "../../functions/validation";

export default class FormComponent extends React.Component {
  /**
   * Contructor del componente
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = _.extend({}, this.validationAttr, this.setInitialState(props));
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlesBind && this.handlesBind();
  }

  validationAttr = {
    isValid: true,
    error: {},
    validation: {},
    processing: false,
    isLoading: false,
    disableButton: false,
  };

  setInitialState() {
    return {};
  }

  /**
   *handler input change
   * @param {e} e - event
   * @returns {void}
   */
  handleInputChange(e) {
    const { target } = e;
    let value = $.trim(target.value).length === 0 ? null : target.value;
    const { name } = target;
    this.inputChange = { [name]: value };
    if (name.lastIndexOf(".") !== -1) {
      const nameAttr = name.substring(name.indexOf(".") + 1);
      const nameParent = name.substring(0, name.indexOf("."));
      // eslint-disable-next-line react/destructuring-assignment
      value = { ...this.state[nameParent], ...{ [nameAttr]: value } };
    }
    this.setStateChange(name, value, target);
  }

  /**
   * Actualizar el state con combios en los atributos
   * @param {string} name
   * @param {object} value
   */
  setStateChange(name, value, target) {
    const nameComplete = name;
    if (name.lastIndexOf(".") !== -1) {
      // eslint-disable-next-line no-param-reassign
      name = name.substring(0, name.indexOf("."));
    }
    this.setState({ [name]: value }, () => {
      this.isValid(nameComplete);
      this.afterSetState && this.afterSetState(nameComplete, value, target);
    });
  }

  /**
   * Actulizar un item del array del state
   * @param  {string} listAttr Campo del array
   * @param  {number} index indice del array a modificar
   * @param  {object} itemState Nuevo item
   * @param  {Boolean} isInitial Validacion si al crear el componente
   */
  handleOnChangeTable(listAttr, index, itemState, isInitial) {
    // eslint-disable-next-line react/destructuring-assignment
    const list = this.list || this.state[listAttr];
    this.list = list.map((item, i) => {
      if (i === index) {
        return itemState;
      }
      return item;
    });
    if (isInitial) {
      index === this.list.length - 1 &&
        this.setState({ [listAttr]: this.list });
    } else {
      this.setState({ [listAttr]: this.list });
    }
  }

  /**
   * Obtener los atributos del state sin las validaciones
   * @return {object} atributos
   */
  getAttributes(excludeAttr) {
    return _.extend(
      {},
      _.omit(this.state, [
        ..._.keys(this.validationAttr),
        ...(excludeAttr || []),
      ])
    );
  }

  /**
   * Obtener los atributos del state.
   * Para subatributos:
   * {ejemplo :{uno:1, dos:2}}
   * se convierte en ['ejemplo.uno', 'ejemplo.dos']
   * @return {[type]} [description]
   */
  getKeysAttrs() {
    const attrValid = _.omit(this.state, _.keys(this.validationAttr));
    const attrSubAttr = _.pickBy(attrValid, (value) => {
      return !_.isArray(value) && _.isObject(value);
    });
    const attrSubAttrAux = _.flatten(
      _.map(attrSubAttr, (value, key) => {
        return _.map(_.keys(value), (a) => {
          return `${key}.${a}`;
        });
      })
    );
    return [..._.keys(attrValid), ...attrSubAttrAux];
  }

  /**
   * Validar uno o varios atributos
   * @param  {object} attrs
   * @return {Boolean} Si es valido o no.
   */
  // eslint-disable-next-line consistent-return
  isValid(attrs, isWithoutError) {
    if (_.isString(attrs)) {
      return this.validate([attrs], isWithoutError);
    }
    if (_.isArray(attrs)) {
      return this.validate(attrs, isWithoutError);
    }
    if (attrs === true) {
      return this.validate(this.getKeysAttrs(), isWithoutError);
    }
  }

  /**
   * Validar los atributos
   * @param  {array} attrs
   * @return {Boolean} Valido o no
   */
  validate(attrs, isWithoutError) {
    const validatedAttrs = getValidatedAttrs(
      // eslint-disable-next-line react/destructuring-assignment
      _.pick(this.state.validation, attrs)
    );
    // eslint-disable-next-line react/destructuring-assignment
    const result = this.validateModel(_.pick(this.state.validation, attrs));
    const self = this;
    const error = { ...self.state.error }; // creating copy of object
    _.each(validatedAttrs, function (val, attr) {
      const invalid = result.invalidAttrs.hasOwnProperty(attr);
      if (!invalid) {
        delete error[attr];
      } else {
        error[attr] = result.invalidAttrs[attr]; // updating value
      }
    });
    this.errorAttr = { isValid: result.isValid, error };
    // eslint-disable-next-line react/destructuring-assignment
    if (!isWithoutError && !_.isEqual(error, this.state.error)) {
      this.setState({ isValid: result.isValid, error });
    }
    return _.keys(result.invalidAttrs).length === 0;
  }

  /**
   * Validates an attribute against all validators defined
   * for that attribute. If one or more errors are found,
   * the first error message is returned.
   * If the attribute is valid, an empty string is returned.
   * @param  {object} validation
   * @param  {string} attr
   * @param  {object} value
   * @param  {object} allAttrs [description]
   * @return {string} Error or empty string
   */
  validateAttr(validation, attr, value, allAttrs) {
    // Reduces the array of validators to an error message by
    // applying all the validators and returning the first error
    // message, if any.
    const self = this;
    return _.reduce(
      getValidators(validation, attr),
      function (memo, validator) {
        // Pass the format functions plus the default
        // validators as the context to the validator
        const result = validator.fn.call(
          defaultValidators,
          value,
          attr,
          validator.val,
          self.state,
          allAttrs
        );

        if (result === false || memo === false) {
          return false;
        }
        if (result && !memo) {
          return validator.msg || result;
        }
        return memo;
      },
      ""
    );
  }

  /**
   * Loops through the model's attributes and validates them all.
   * Returns object containing names of invalid attributes
   * as well as error messages.
   * @param  {object} validation
   * @return {array} Attrs with errors
   */
  validateModel(validation) {
    let error;
    const invalidAttrs = {};
    let isValid = true;
    // eslint-disable-next-line react/destructuring-assignment
    const validatedAttrs = getValidatedAttrs(this.state.validation);
    const computed = _.extend({}, validatedAttrs, this.getAttributes());
    const flattened = flatten(this.getAttributes());
    const self = this;
    _.each(flattened, function (val, attr) {
      error = self.validateAttr(validation, attr, val, computed);
      if (error) {
        invalidAttrs[attr] = error;
        isValid = false;
      }
    });
    return {
      invalidAttrs,
      isValid,
    };
  }

  /**
   * Validar un array
   * @param {array} array
   */
  // eslint-disable-next-line class-methods-use-this
  isValidArray(array) {
    return !_.some(array, ["isValid", false]);
  }

  /**
   * Agregar validaciones
   * {campo:{
   *       required: true
   *    },
   *    .....
   * }
   * @param {object} validations
   */
  addValidation(validations) {
    this.setState({
      // eslint-disable-next-line react/destructuring-assignment
      // eslint-disable-next-line react/no-access-state-in-setstate
      validation: _.extend({}, { ...this.state.validation }, validations),
    });
  }

  /**
   * Remove validaciones
   * [campo1, campo2, ....]
   * {campo:{
   *       required: true
   *    },
   *    .....
   * }
   * @param  {object} validation
   */
  removeValidation(validations) {
    let arrValidations = [];
    if (_.isString(validations)) {
      arrValidations = [validations];
    }
    if (_.isArray(validations)) {
      arrValidations = validations;
    }
    if (!_.isArray(validations) && _.isObject(validations)) {
      arrValidations = _.keys(validations);
    }
    this.setState({
      // eslint-disable-next-line react/destructuring-assignment
      validation: _.omit({ ...this.state.validation }, arrValidations),
    });
  }

  /**
   * Obtener html del error.
   * @param  {string} error
   * @return {object} contenido html
   */
  // eslint-disable-next-line class-methods-use-this
  getElementError(error) {
    return (
      <div className="error_message">
        {error ? <i className="material-icons">error_outline</i> : ""}
        {error || ""}
      </div>
    );
  }
}
