/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 24th June 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 24th June 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ReporteRemitenteComponent from "./ReporteRemitenteComponent";

const ReporteContainer = ({ match }) => (
  <div>
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        component={ReporteRemitenteComponent}
      />
      <Redirect to={`${match.url}`} />
    </Switch>
  </div>
);

export default ReporteContainer;
