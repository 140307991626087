/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ClienteListComponent from "./ClienteListComponent";

const ClienteContainer = ({ match }) => (
  <div>
    <Switch>
      <Route exact path={`${match.path}`} component={ClienteListComponent} />
      <Redirect to={`${match.url}`} />
    </Switch>
  </div>
);

export default ClienteContainer;
