/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable func-names */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 27th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 27th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from 'react';
import { Column } from 'primereact/components/column/Column';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Button } from 'primereact/components/button/Button';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import $ from 'jquery';
import _ from 'lodash';
import { getUUID, localeString, FORMAT_DATE_CALENDAR, FORMAT_DATE_MOMENT } from '../../../components/functions/globals';
import DataTableCrudComponent from '../../../components/commons/table/DataTableCrudComponent';
import ClienteREST from '../../../flux/api/ClienteREST';
import ClienteStore from '../../../flux/stores/ClienteStore';
import ClienteActions from '../../../flux/actions/ClienteActions';
import RutaStore from '../../../flux/stores/RutaStore';
import RutaActions from '../../../flux/actions/RutaActions';
import ProductoStore from '../../../flux/stores/ProductoStore';
import GuiaStore from '../../../flux/stores/GuiaStore';
import GuiaActions from '../../../flux/actions/GuiaActions';
import CalendarComponent from '../../../components/commons/form/CalendarComponent';
import conf, { URL_SERVER } from '../../../conf/conf';

import ErrorStore from '../../../flux/stores/ErrorStore';

export default class ListarGuiaComponent extends DataTableCrudComponent {
  uuidRuta = getUUID();

  uuidProducto = getUUID();

  sectionName = 'listar-guia';

  labelList = 'Transportes Brito - Lista de Guías';

  labelNew = '';

  labelTotal = 'Guía detalle';

  classNameContentTable = 'ui-xl-10 ui-lg-12 ui-g-12 ui-md-12 ui-sm-12';

  hasPagination = true;

  hasEdit = true;

  hasSearch = false;

  hasNew = false;

  initialState() {
    return {
      fechaInicio: moment().subtract(7, 'd').toDate(),
      fechaFin: new Date(),
      guiaDetalle: [],
      remitente: null,
      remitentes: [],
      destinatario: null,
      detinatarios: [],
      direccionOrigen: '',
      direccionDestino: '',
      guiaNumero: null,

      origen: null,
      origenes: [],

      destino: null,
      destinos: [],

      producto: null,
      productos: [],

      cantidad: 0,
      valorUnitario: 0,

      isVisibleDialogGuardar: false,
      guiaId: '',

      imprimirPrecio: true,
    };
  }

  handlesBind() {}

  componentDidMount() {
    super.componentDidMount();
    RutaStore.addGetRutasListener(this._onGetRutasListener, this.uuidRuta);
    ProductoStore.addGetProductosListener(this._onGetProductosListener, this.uuidProducto);
    GuiaStore.addCreateGuiaListener(this._onCreateGuiaListener);
    GuiaStore.addGetGuiasListener(this._onGetGuiasListener);
    ErrorStore.addErrorListener(this._onErrorListerner);
    GuiaStore.addDeleteGuiaListener(this._onDeleteGuiaListener);
    RutaActions.listarAllRutas(this.uuidRuta);
    this.onClickListar();
  }

  componentWillUnmount() {
    RutaStore.removeGetRutasListener(this._onGetRutasListener, this.uuidRuta);
    ProductoStore.removeGetProductosListener(this._onGetProductosListener, this.uuidProducto);
    GuiaStore.removeCreateGuiaListener(this._onCreateGuiaListener);
    GuiaStore.removeGetGuiasListener(this._onGetGuiasListener);
    ErrorStore.removeErrorListener(this._onErrorListerner);
    GuiaStore.removeDeleteGuiaListener(this._onDeleteGuiaListener);
  }

  getDataList = () => {
    this.onClickListar();
  };

  _onGetGuiasListener = () => {
    const data = GuiaStore.getGuias();
    const rows = _.map(data.rows, (guia) => {
      const value = {
        ...guia,
        fecha: moment.unix(guia.fecha).format(FORMAT_DATE_MOMENT),
        remitenteNombres: `${guia.remitente.nombres} ${guia.remitente.apellidos}`,
        destinatarioNombres: `${guia.destinatario.nombres} ${guia.destinatario.apellidos}`,
        rutaOrigenDescripcion: guia.rutaOrigen.descripcion,
        rutaDestinoDescripcion: guia.rutaDestino.descripcion,
      };
      return value;
    });
    this.setState({
      list: rows,
      totalRecords: data.count,
      search: null,
      isLoading: false,
    });
  };

  textQuestionDelete() {
    return `¿Estas seguro de eliminar la Guía Nro: ${this.state.rowDelete ? this.state.rowDelete.guiaNumero : ''}?`;
  }

  sendDeleteOk() {
    GuiaActions.eliminarGuia(this.state.rowDelete[this.attrId]);
  }

  _onDeleteGuiaListener = () => {
    const data = GuiaStore.getGuia();
    if (data && data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: 'success',
        detail: 'Guía eliminada',
      });
      this.onSuccessDelete();
    } else {
      this.setState({ disableButtonPopupDelete: false });
      window.growlAdmin.show({
        severity: 'error',
        detail: 'Error al eliminar la Guía',
      });
    }
  };

  // ----------------------------------- SELECT CLIENTE REMITENTE, DESTINATARIO

  onChangeSelectClienteRemitente = (remitente) => {
    this.setState({ remitente, direccionOrigen: remitente.direccion });
  };

  loadOptionsRemitente = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      ClienteREST.listarClientes({ search: inputValue })
        .then((respuesta) => {
          if (respuesta.data) {
            const dataList = respuesta.data.rows.map((item) => ({
              value: item.id,
              label: `${item.nombres} ${item.apellidos}`,
              direccion: `${item.callePrincipal} ${item.calleSecundaria ? item.calleSecundaria : ''} / ${item.referencia ? item.referencia : ''}`,
            }));
            callback(dataList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  onChangeSelectClienteDestinatario = (destinatario) => {
    this.setState({ destinatario, direccionDestino: destinatario.direccion });
  };

  loadOptionsDestinatario = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      ClienteREST.listarClientes({ search: inputValue })
        .then((respuesta) => {
          if (respuesta.data) {
            const dataList = respuesta.data.rows.map((item) => ({
              value: item.id,
              label: `${item.nombres} ${item.apellidos}`,
              direccion: `${item.callePrincipal} ${item.calleSecundaria ? item.calleSecundaria : ''} / ${item.referencia ? item.referencia : ''}`,
            }));
            callback(dataList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // ----------------------------------- SELECT ORIGEN, DESTINO
  _onGetRutasListener = () => {
    const data = RutaStore.getRutas(this.uuidRuta);
    const dataList =
      data &&
      data.rows.map((item) => ({
        value: item.id,
        label: `${item.descripcion} - ${item.via}`,
      }));
    this.setState({ origenes: dataList, destinos: dataList });
  };

  onChangeSelectRutaOrigen = (origen) => {
    this.setState({ origen });
  };

  onChangeSelectRutaDestino = (destino) => {
    this.setState({ destino });
  };

  // ----------------------------------- SELECT PRODUCTO
  _onGetProductosListener = () => {
    const data = ProductoStore.getProductos(this.uuidProducto);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: item.descripcion,
      precio: item.precio,
    }));
    this.setState({ productos: dataList });
  };

  onChangeSelectProducto = (producto) => {
    this.setState({ producto, valorUnitario: producto.precio });
  };

  _onErrorListerner = () => {
    window.growlAdmin.show({
      severity: 'error',
      life: 15000,
      detail: 'Problemas en la comunicación con el servidor, comuníquese con el administrador.',
    });
    this.onCloseDialogForm && this.onCloseDialogForm(true);
  };

  // para manejar el handle event de los textinput
  handleInputChange = (e) => {
    const { target } = e;
    let value = $.trim(target.value).length === 0 ? null : target.value;
    const { name } = target;
    this.inputChange = { [name]: value };
    if (name.lastIndexOf('.') !== -1) {
      const nameAttr = name.substring(name.indexOf('.') + 1);
      const nameParent = name.substring(0, name.indexOf('.'));
      value = { ...this.state[nameParent], ...{ [nameAttr]: value } };
    }
    this.setStateChange(name, value, target);
  };

  setStateChange = (name, value) => {
    if (name.lastIndexOf('.') !== -1) {
      name = name.substring(0, name.indexOf('.'));
    }
    this.setState({ [name]: value }, () => {});
  };

  // agregar un detalle
  onClickAgregarDetalle = () => {
    const { producto, cantidad, valorUnitario } = this.state;
    const valorTotal = (cantidad && parseInt(cantidad)) * (valorUnitario && parseFloat(valorUnitario));
    const data = {
      id: getUUID(),
      productoId: producto.value,
      cantidad,
      detalle: producto.label,
      valorUnitario,
      valorTotal,
    };
    const dataList = [...this.state.list, data];
    this.setState({
      list: dataList,
      totalRecords: dataList.length,
      search: null,
      isLoading: false,
      producto: null,
      cantidad: '',
      valorUnitario: '',
    });
    this.selectProducto && this.selectProducto.focus();
  };

  // guardar y enviar al servidor
  onClickGuardar = () => {
    const guiasDetalles = _.map(this.state.list, (item) => {
      const { productoId, cantidad, valorUnitario } = item;
      return { productoId, cantidad, valorUnitario };
    });
    const { remitente, destinatario, origen, destino, direccionOrigen, direccionDestino } = this.state;
    const data = {
      remitenteId: remitente.value,
      destinatarioId: destinatario.value,
      rutaOrigenId: origen.value,
      rutaDestinoId: destino.value,
      direccionOrigen,
      direccionDestino,
      guiasDetalles,
    };
    GuiaActions.crearGuia(data);
  };

  _onCreateGuiaListener = () => {
    const guia = GuiaStore.getGuia();
    // console.log('guia creada', guia.id)
    this.setState({ guiaId: guia.id });
    this._onVisibleDialogGuardar(true);
  };

  _onClickOkImprimir = (rowData) => {
    this.setState({ isLoading: true, isVisibleDialogGuardar: false });
    fetch(`${URL_SERVER}/api/guias/${rowData.id}/imprimir?imprimirPrecio=${this.state.imprimirPrecio}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Credentials': '*',
        'Content-type': 'application/pdf',
        'Content-Disposition': 'attachment; filename="downloaded.pdf',
        Authorization: `Bearer ${localStorage.getItem(conf.TOKEN_KEY)}`,
      },
      responseType: 'blob',
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
        window.open(url);
        window.URL.revokeObjectURL(url);
        // const a = document.createElement("a");
        // a.download = `guia_${rowData.guiaNumero}.pdf`;
        // a.target = "_blank";
        // a.href = url;
        // a.click();
        this.setState({ isLoading: false });
      });
  };

  _onVisibleDialogGuardar = (estado) => {
    this.setState({ isVisibleDialogGuardar: estado });
  };

  onClickListar = () => {
    const { fechaInicio, fechaFin, remitente, destinatario, origen, destino, guiaNumero, page, rows } = this.state;
    // console.log(fechaInicio, fechaFin, remitente, destinatario, origen, destino)
    const fechaInicioUNIX = moment(fechaInicio).startOf('day').unix();
    const fechaFinUNIX = moment(fechaFin).startOf('day').unix();
    const remitenteId = remitente && remitente.value;
    const destinatarioId = destinatario && destinatario.value;
    const rutaOrigenId = origen && origen.value;
    const rutaDestinoId = destino && destino.value;

    if (fechaInicioUNIX && fechaFinUNIX) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          GuiaActions.listarGuias({
            fechaInicio: fechaInicioUNIX,
            fechaFin: fechaFinUNIX,
            remitenteId,
            destinatarioId,
            rutaOrigenId,
            rutaDestinoId,
            guiaNumero,
            page,
            limit: rows,
          });
        },
      );
    } else {
      window.growlAdmin.show({
        severity: 'error',
        life: 15000,
        detail: 'Ingrese la fecha de inicio y la fecha de fin',
      });
    }
  };

  onClickBorrarFiltro = () => {
    this.setState(
      {
        fechaInicio: moment().subtract(7, 'd').toDate(),
        fechaFin: new Date(),
        guiaDetalle: [],
        remitente: null,
        destinatario: null,
        direccionOrigen: '',
        direccionDestino: '',
        guiaNumero: null,
        origen: null,
        destino: null,
        producto: null,
        cantidad: 0,
        valorUnitario: 0,
        isVisibleDialogGuardar: false,
        guiaId: '',
        imprimirPrecio: true,
      },
      () => {
        this.onClickListar();
      },
    );
  };

  _onClickEditGuia = (rowData) => {
    GuiaActions.setGuia(rowData);
    this.props.history.push('/admin/crear-guia');
  };

  createColumnAction() {
    const actionTemplate = (rowData) => {
      return (
        <div>
          <Button type="button" icon="fa fa-print" title="Imprimir" onClick={() => this._onClickOkImprimir(rowData)} style={{ marginRight: '3px' }} />
          <Button type="button" icon="fa fa-pencil-alt" title="Editar" className="ui-button-success" onClick={() => this._onClickEditGuia(rowData)} style={{ marginRight: '3px' }} />
          {this.hasDelete ? <Button type="button" icon="fa fa-trash" title="Eliminar" className="ui-button-danger" onClick={() => this._onShowHideDialogDelete(true, rowData)} /> : ''}
        </div>
      );
    };
    return (
      <Column
        body={actionTemplate}
        style={{
          ...{ textAlign: 'center', width: '160px' },
          ...this.styleColumnAction,
        }}
      />
    );
  }

  createFilter() {
    return (
      <div>
        <div
          className="ui-fluid"
          style={{
            border: ' 1px solid #D3D3D3',
            borderRadius: '5px',
            margin: '10px',
          }}
        >
          <div className="ui-grid ui-fluid">
            <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
              <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                <label>Nro de guía:</label>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <InputText id="guiaNumero" name="guiaNumero" type="text" value={this.state.guiaNumero || ''} maxLength="50" keyfilter={/(\d)+/g} onChange={this.handleInputChange} />
              </div>
            </div>
            <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
              <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                <label>Fecha Inicio:</label>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <CalendarComponent
                  value={this.state.fechaInicio}
                  onChange={(e) => this.setState({ fechaInicio: e.value })}
                  showIcon
                  locale={localeString('es')}
                  monthNavigator
                  yearNavigator
                  icon="fa fa-calendar"
                  yearRange={`2010:${moment().get('year')}`}
                  dateFormat={FORMAT_DATE_CALENDAR}
                  placeholder={FORMAT_DATE_MOMENT}
                  readOnlyInput
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
              <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                <label>Fecha Fin:</label>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <CalendarComponent
                  value={this.state.fechaFin}
                  onChange={(e) => this.setState({ fechaFin: e.value })}
                  showIcon
                  locale={localeString('es')}
                  monthNavigator
                  yearNavigator
                  icon="fa fa-calendar"
                  yearRange={`2010:${moment().get('year')}`}
                  dateFormat={FORMAT_DATE_CALENDAR}
                  placeholder={FORMAT_DATE_MOMENT}
                  readOnlyInput
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="ui-grid">
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Remitente:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <AsyncSelect
                    cacheOptions
                    value={this.state.remitente}
                    onChange={this.onChangeSelectClienteRemitente}
                    loadOptions={this.loadOptionsRemitente}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay remitentes'}
                    placeholder="Buscar remitente..."
                  />
                </div>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Destinatario:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <AsyncSelect
                    cacheOptions
                    value={this.state.destinatario}
                    onChange={this.onChangeSelectClienteDestinatario}
                    loadOptions={this.loadOptionsDestinatario}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay destinatarios'}
                    placeholder="Buscar destinatario..."
                  />
                </div>
              </div>
            </div>
            <div className="ui-grid">
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Origen:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <Select
                    cacheOptions
                    defaultOptions
                    value={this.state.origen}
                    options={this.state.origenes}
                    onInputChange={this.handleInputRutaOrigenChange}
                    onChange={(option) => this.onChangeSelectRutaOrigen(option)}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay rutas origen'}
                    placeholder="Buscar rutas origen..."
                  />
                </div>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Destino:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <Select
                    cacheOptions
                    defaultOptions
                    value={this.state.destino}
                    options={this.state.destinos}
                    onInputChange={this.handleInputRutaDestinoChange}
                    onChange={
                      (option) => this.onChangeSelectRutaDestino(option)
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay rutas destino'}
                    placeholder="Buscar rutas destino..."
                  />
                </div>
              </div>
            </div>
            <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-6 padding-corto">
                <Button label="Buscar" onClick={this.onClickListar} />
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-6 padding-corto">
                <Button label="Borrar filtro" onClick={this.onClickBorrarFiltro} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  createColumns() {
    return [
      <Column key="0" style={{ width: '70px' }} field="guiaNumero" header="Nro Guia" />,
      <Column key="1" style={{ width: '90px' }} field="fecha" header="Fecha" />,
      <Column key="2" field="remitenteNombres" header="Remitente" />,
      <Column key="3" field="destinatarioNombres" header="Destinatario" />,
      <Column key="4" field="rutaOrigenDescripcion" header="Origen" />,
      <Column key="5" field="rutaDestinoDescripcion" header="Destino" />,
      <Column key="6" field="direccionOrigen" header="Dir. Origen" />,
      <Column key="7" field="direccionDestino" header="Dir. Destino" />,
      <Column key="8" field="observacion" header="Observación" />,
      <Column key="9" field="valorTotal" header="Valor Total" />,
    ];
  }
}
