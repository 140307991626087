/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from '../AppDispatcher';
import * as AppConstants from '../AppConstants';
import HojaRutaREST from '../api/HojaRutaREST';
import ErrorHandler from '../utilities/ErrorHandler';

class HojaRutaActions {
  listarHojasRutas = ({ hRutaNumero, choferId, ayudanteId, vehiculoId, remitenteId, fechaInicio, fechaFin, page, limit }) => {
    HojaRutaREST.listarHojasRutas({
      hRutaNumero,
      choferId,
      ayudanteId,
      vehiculoId,
      remitenteId,
      fechaInicio,
      fechaFin,
      page,
      limit,
    })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.hojaruta.GET_LIST,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearHojaRuta = (data) => {
    HojaRutaREST.crearHojaRuta(data)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.hojaruta.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  setHojaRuta = (data) => {
    AppDispatcher.dispatch({
      actionType: AppConstants.hojaruta.SET,
      payload: data,
    });
  };

  actualizarHojaRuta = ({ id, observacion }) => {
    HojaRutaREST.actualizarHojaRuta({ id, observacion })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.hojaruta.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarHojaRuta = (id) => {
    HojaRutaREST.eliminarHojaRuta(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.hojaruta.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new HojaRutaActions();
