import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import "babel-polyfill";
import { Switch, Redirect, BrowserRouter as Router } from "react-router-dom";
import AuthorizationRoute from "./AuthorizationRoute";
import AuthenticationComponent from "./views/authentication/AuthenticationComponent";
import { AdminComponent } from "./views/admin/AdminComponent";
import "./styles/index.scss";

ReactDOM.render(
  <Router>
    <Switch>
      <AuthorizationRoute
        exact
        path="/"
        titlePage="Transportes Brito"
        component={AdminComponent}
      />
      <AuthorizationRoute
        path="/admin"
        titlePage="Transportes Brito - Administración"
        component={AdminComponent}
      />
      <AuthorizationRoute isLogin component={AuthenticationComponent} />
      <Redirect to="/admin" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
