/* eslint-disable class-methods-use-this */
/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from "react";
import { Column } from "primereact/components/column/Column";
import { getUUID } from "../../../components/functions/globals";
import DataTableCrudEditComponent from "../../../components/commons/table/DataTableCrudEditComponent";
import EmpleadoActions from "../../../flux/actions/EmpleadoActions";
import EmpleadoStore from "../../../flux/stores/EmpleadoStore";
import EmpleadoFormComponent from "./EmpleadoFormComponent";
import ErrorStore from "../../../flux/stores/ErrorStore";

export default class EmpleadoListComponent extends DataTableCrudEditComponent {
  sectionName = "empleado";

  labelList = "Listado de Empleados";

  labelNew = "Nuevo Empleado";

  labelTotal = "Empleado";

  classNameContentTable = "ui-xl-10 ui-lg-12 ui-g-12 ui-md-12 ui-sm-12";

  hasPagination = true;

  uuidEmpleado = getUUID();

  handlesBind() {}

  componentDidMount() {
    super.componentDidMount();
    EmpleadoStore.addGetEmpleadosListener(
      this._onGetEmpleadosListener,
      this.uuidEmpleado
    );
    EmpleadoStore.addDeleteEmpleadoListener(this._onDeleteEmpleadoListener);
    ErrorStore.addErrorListener(this._onErrorListerner);
    this.getDataList();
  }

  componentWillUnmount() {
    EmpleadoStore.removeGetEmpleadosListener(
      this._onGetEmpleadosListener,
      this.uuidEmpleado
    );
    EmpleadoStore.removeDeleteEmpleadoListener(this._onDeleteEmpleadoListener);
    ErrorStore.removeErrorListener(this._onErrorListerner);
  }

  _onGetEmpleadosListener = () => {
    const data = EmpleadoStore.getEmpleados(this.uuidEmpleado);
    this.setState({
      list: data.rows,
      totalRecords: data.count,
      search: null,
      isLoading: false,
    });
  };

  getDataList() {
    const { page, rows, searchServer } = this.state;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        EmpleadoActions.listarEmpleados(
          { page, limit: rows, search: searchServer },
          this.uuidEmpleado
        );
      }
    );
  }

  textQuestionDelete() {
    return `¿Estas seguro de eliminar el empleado: ${
      this.state.rowDelete
        ? `${this.state.rowDelete.nombres} ${this.state.rowDelete.apellidos}`
        : ""
    }?`;
  }

  sendDeleteOk() {
    EmpleadoActions.eliminarEmpleado(this.state.rowDelete[this.attrId]);
  }

  _onDeleteEmpleadoListener = () => {
    const data = EmpleadoStore.getEmpleado();
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: "success",
        detail: "Empleado eliminado",
      });
      this.onSuccessDelete();
    } else {
      this.setState({ disableButtonPopupDelete: false });
      window.growlAdmin.show({
        severity: "error",
        detail: "Error al eliminar el Empleado",
      });
    }
  };

  _onErrorListerner = () => {
    window.growlAdmin.show({
      severity: "error",
      life: 15000,
      detail:
        "Problemas en la comunicación con el servidor, comuníquese con el administrador.",
    });
    this.setState({ isLoading: false });
    this.onCloseDialogForm &&
      this.state.isShowForm === true &&
      this.onCloseDialogForm(true);
  };

  createColumns() {
    return [
      <Column key="0" field="nombres" header="Nombres" />,
      <Column key="1" field="apellidos" header="Apellidos" />,
      <Column key="2" field="documento" header="Cédula" />,
      <Column key="3" field="telefono" header="Telefono" />,
      <Column key="4" field="direccion" header="Dirección" />,
      <Column key="5" field="correo" header="Correo Electrónico" />,
      <Column
        key="6"
        field="tipoEmpleado.descripcion"
        header="Tipo Empleado"
      />,
    ];
  }

  createAdditionalDialog() {
    return this.state.isShowForm ? (
      <EmpleadoFormComponent
        editData={this.state.editData}
        onCloseDialogForm={this.onCloseDialogForm}
      />
    ) : (
      ""
    );
  }
}
