/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let canton = null;
let cantones = [];

const GET_CANTONES_LIST_EVENT = "GET_CANTONES_LIST_EVENT";
const CREATE_CANTON_EVENT = "CREATE_CANTON_EVENT";
const UPDATE_CANTON_EVENT = "UPDATE_CANTON_EVENT";
const DELETE_CANTON_EVENT = "DELETE_CANTON_EVENT";

class CantonStore extends EventEmitter {
  getCanton = () => canton;

  setCanton = (_canton) => {
    canton = _canton;
  };

  getCantones = () => cantones;

  setCantones = (_cantones) => {
    cantones = _cantones;
  };

  emitGetCantones() {
    this.emit(GET_CANTONES_LIST_EVENT);
  }

  addGetCantonesListener(callback) {
    this.on(GET_CANTONES_LIST_EVENT, callback);
  }

  removeGetCantonesListener(callback) {
    this.removeListener(GET_CANTONES_LIST_EVENT, callback);
  }

  emitCreateCanton() {
    this.emit(CREATE_CANTON_EVENT);
  }

  addCreateCantonListener(callback) {
    this.on(CREATE_CANTON_EVENT, callback);
  }

  removeCreateCantonListener(callback) {
    this.removeListener(CREATE_CANTON_EVENT, callback);
  }

  emitUpdateCanton() {
    this.emit(UPDATE_CANTON_EVENT);
  }

  addUpdateCantonListener(callback) {
    this.on(UPDATE_CANTON_EVENT, callback);
  }

  removeUpdateCantonListener(callback) {
    this.removeListener(UPDATE_CANTON_EVENT, callback);
  }

  emitDeleteCanton() {
    this.emit(DELETE_CANTON_EVENT);
  }

  addDeleteCantonListener(callback) {
    this.on(DELETE_CANTON_EVENT, callback);
  }

  removeDeleteCantonListener(callback) {
    this.removeListener(DELETE_CANTON_EVENT, callback);
  }
}

const cantonStore = new CantonStore();

cantonStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.canton.GET_LIST:
      cantonStore.setCantones(action.payload);
      cantonStore.emitGetCantones();
      break;
    case AppConstants.canton.CREATE:
      cantonStore.setCanton(action.payload);
      cantonStore.emitCreateCanton();
      break;
    case AppConstants.canton.UPDATE:
      cantonStore.setCanton(action.payload);
      cantonStore.emitUpdateCanton();
      break;
    case AppConstants.canton.DELETE:
      cantonStore.setCanton(action.payload);
      cantonStore.emitDeleteCanton();
      break;
    default:
  }
});

export default cantonStore;
