/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import {
  getSuperagent,
  postSuperagent,
  putSuperagent,
  deleteSuperagent,
} from "./SuperagentConfig";

class EmpleadoREST {
  listarEmpleados = ({ page, limit, search }) => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent(
        `/api/empleados?page=${page}&limit=${limit}&search=${search}`
      ).end((error, response) => {
        // eslint-disable-next-line no-unused-expressions
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  listarAllEmpleados = () => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent(`/api/empleados`).end((error, response) => {
        // eslint-disable-next-line no-unused-expressions
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  crearEmpleado = ({
    nombres,
    apellidos,
    documento,
    telefono,
    direccion,
    correo,
    tipoempId,
  }) => {
    const promesa = new Promise((resolve, reject) => {
      postSuperagent("/api/empleados", {
        nombres,
        apellidos,
        documento,
        telefono,
        direccion,
        correo,
        tipoempId,
      }).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  actualizarEmpleado = ({
    id,
    nombres,
    apellidos,
    documento,
    telefono,
    direccion,
    correo,
    tipoempId,
  }) => {
    const promesa = new Promise((resolve, reject) => {
      putSuperagent(`/api/empleados/${id}`, {
        nombres,
        apellidos,
        documento,
        telefono,
        direccion,
        correo,
        tipoempId,
      }).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  eliminarEmpleado = (id) => {
    const promesa = new Promise((resolve, reject) => {
      deleteSuperagent(`/api/empleados/${id}`).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };
}

export default new EmpleadoREST();
