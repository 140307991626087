/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from "../AppDispatcher";
import * as AppConstants from "../AppConstants";
import EmpleadoREST from "../api/EmpleadoREST";
import ErrorHandler from "../utilities/ErrorHandler";

class EmpleadoActions {
  listarEmpleados = ({ page, limit, search }, componentUID) => {
    EmpleadoREST.listarEmpleados({ page, limit, search })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.empleado.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  listarAllEmpleados = (componentUID) => {
    EmpleadoREST.listarAllEmpleados()
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.empleado.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearEmpleado = ({
    nombres,
    apellidos,
    documento,
    telefono,
    direccion,
    correo,
    tipoempId,
  }) => {
    EmpleadoREST.crearEmpleado({
      nombres,
      apellidos,
      documento,
      telefono,
      direccion,
      correo,
      tipoempId,
    })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.empleado.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  actualizarEmpleado = ({
    id,
    nombres,
    apellidos,
    documento,
    telefono,
    direccion,
    correo,
    tipoempId,
  }) => {
    EmpleadoREST.actualizarEmpleado({
      id,
      nombres,
      apellidos,
      documento,
      telefono,
      direccion,
      correo,
      tipoempId,
    })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.empleado.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarEmpleado = (id) => {
    EmpleadoREST.eliminarEmpleado(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.empleado.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new EmpleadoActions();
