/**
 * Created by cristianmino on 2/9/18.
 */

module.exports = {
  MSG_400: "Ingrese correctamento sus datos",
  MSG_401: "",
  MSG_404: "",
  MSG_413: "",
  MSG_500: "Ha ocurrido un error, comuníquese con el administrador.",
  MSG_XXX:
    "Problemas en la conexión, por favor intente despues de unos minutos.",
};
