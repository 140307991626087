/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let ruta = null;
const rutas = {};

const GET_RUTAS_LIST_EVENT = "GET_RUTAS_LIST_EVENT";
const CREATE_RUTA_EVENT = "CREATE_RUTA_EVENT";
const UPDATE_RUTA_EVENT = "UPDATE_RUTA_EVENT";
const DELETE_RUTA_EVENT = "DELETE_RUTA_EVENT";

class RutaStore extends EventEmitter {
  getRuta = () => ruta;

  setRuta = (_ruta) => {
    ruta = _ruta;
  };

  getRutas = (componentUID) => {
    const datos = rutas[componentUID];
    delete rutas[componentUID];
    return datos;
  };

  setRutas = (componentUID, _rutas) => {
    rutas[componentUID] = _rutas;
  };

  emitGetRutas(componentUID) {
    this.emit(`${GET_RUTAS_LIST_EVENT}_${componentUID}`);
  }

  addGetRutasListener(callback, componentUID) {
    this.on(`${GET_RUTAS_LIST_EVENT}_${componentUID}`, callback);
  }

  removeGetRutasListener(callback, componentUID) {
    this.removeListener(`${GET_RUTAS_LIST_EVENT}_${componentUID}`, callback);
  }

  emitCreateRuta() {
    this.emit(CREATE_RUTA_EVENT);
  }

  addCreateRutaListener(callback) {
    this.on(CREATE_RUTA_EVENT, callback);
  }

  removeCreateRutaListener(callback) {
    this.removeListener(CREATE_RUTA_EVENT, callback);
  }

  emitUpdateRuta() {
    this.emit(UPDATE_RUTA_EVENT);
  }

  addUpdateRutaListener(callback) {
    this.on(UPDATE_RUTA_EVENT, callback);
  }

  removeUpdateRutaListener(callback) {
    this.removeListener(UPDATE_RUTA_EVENT, callback);
  }

  emitDeleteRuta() {
    this.emit(DELETE_RUTA_EVENT);
  }

  addDeleteRutaListener(callback) {
    this.on(DELETE_RUTA_EVENT, callback);
  }

  removeDeleteRutaListener(callback) {
    this.removeListener(DELETE_RUTA_EVENT, callback);
  }
}

const rutaStore = new RutaStore();

rutaStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.ruta.GET_LIST:
      const { componentUID } = action.payload;
      if (componentUID) {
        rutaStore.setRutas(componentUID, action.payload.data);
        rutaStore.emitGetRutas(componentUID);
      }
      break;
    case AppConstants.ruta.CREATE:
      rutaStore.setRuta(action.payload);
      rutaStore.emitCreateRuta();
      break;
    case AppConstants.ruta.UPDATE:
      rutaStore.setRuta(action.payload);
      rutaStore.emitUpdateRuta();
      break;
    case AppConstants.ruta.DELETE:
      rutaStore.setRuta(action.payload);
      rutaStore.emitDeleteRuta();
      break;
    default:
  }
});

export default rutaStore;
