/**
 * @file Contenedor de Spinner de cargando
 * @author Wilson Guiñanzaca <wilson_g18@hotmail.com>
 * 28-Mar-2019
 */
import React from "react";
import { ProgressSpinner } from "primereact/components/progressspinner/ProgressSpinner";

// eslint-disable-next-line import/prefer-default-export
export const SpinnerLoadingComponent = ({ isDisplay }) => {
  return isDisplay ? (
    <div className="spinner-loading">
      <div className="spinner-center">
        <ProgressSpinner
          style={{ width: "40px", height: "40px" }}
          strokeWidth="8"
          animationDuration=".5s"
        />
      </div>
    </div>
  ) : (
    ""
  );
};
/* (
    <div className="spinner-loading">
        <div className="spinner-center">
            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="8" animationDuration=".5s" />
        </div>
    </div>
); */
