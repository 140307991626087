/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import {
  getSuperagent,
  postSuperagent,
  putSuperagent,
  deleteSuperagent,
} from "./SuperagentConfig";

class ParroquiaREST {
  listarParroquiasPorCanton = ({ cantonId, page, limit, search }) => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent(
        `/api/cantones/${cantonId}/parroquias?page=${page}&limit=${limit}&search=${search}`
      ).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  crearParroquia = ({ descripcion, cantonId }) => {
    const promesa = new Promise((resolve, reject) => {
      postSuperagent("/api/parroquias", { descripcion, cantonId }).end(
        (error, response) => {
          error ? reject(error) : resolve(response.body);
        }
      );
    });
    return promesa;
  };

  actualizarParroquia = ({ id, descripcion, cantonId }) => {
    const promesa = new Promise((resolve, reject) => {
      putSuperagent(`/api/parroquias/${id}`, { descripcion, cantonId }).end(
        (error, response) => {
          error ? reject(error) : resolve(response.body);
        }
      );
    });
    return promesa;
  };

  eliminarParroquia = (id) => {
    const promesa = new Promise((resolve, reject) => {
      deleteSuperagent(`/api/parroquias/${id}`).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };
}

export default new ParroquiaREST();
