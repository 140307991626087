/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import {
  getSuperagent,
  postSuperagent,
  putSuperagent,
  deleteSuperagent,
} from "./SuperagentConfig";

class TipoEmpleadoREST {
  listarTiposEmpleados = ({ page, limit, search }) => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent(
        `/api/tiposempleados?page=${page}&limit=${limit}&search=${search}`
      ).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  listarAllTiposEmpleados = () => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent(`/api/tiposempleados`).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  crearTipoEmpleado = ({ descripcion }) => {
    const promesa = new Promise((resolve, reject) => {
      postSuperagent("/api/tiposempleados", { descripcion }).end(
        (error, response) => {
          error ? reject(error) : resolve(response.body);
        }
      );
    });
    return promesa;
  };

  actualizarTipoEmpleado = ({ id, descripcion }) => {
    const promesa = new Promise((resolve, reject) => {
      putSuperagent(`/api/tiposempleados/${id}`, { descripcion }).end(
        (error, response) => {
          error ? reject(error) : resolve(response.body);
        }
      );
    });
    return promesa;
  };

  eliminarTipoEmpleado = (id) => {
    const promesa = new Promise((resolve, reject) => {
      deleteSuperagent(`/api/tiposempleados/${id}`).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };
}

export default new TipoEmpleadoREST();
