import React from "react";
import _ from "lodash";
import { Button } from "primereact/components/button/Button";
import { SpinnerLoadingComponent } from "../spinner/SpinnerLoadingComponent";
import FormComponent from "./FormComponent";

export default class FormCrudComponent extends FormComponent {
  totalListener = 0;

  countListener = 0;

  attrId = "id";

  idEdit = null;

  classNameForm = "ui-xl-9 ui-lg-11 ui-g-12 ui-md-12 ui-sm-12";

  /**
   * Contructor del componente
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = _.extend({}, this.validationAttr, this.setInitialState(props));
    this.handleInputChange = this.handleInputChange.bind(this);
    this._onSaveClick = this._onSaveClick.bind(this);
    this._onCancelReturnClick = this._onCancelReturnClick.bind(this);
    this.handlesBind && this.handlesBind();
  }

  hideSpinnerLoading() {
    this.countListener === this.totalListener &&
      this.setState({ isLoading: false });
  }

  /**
   * Cancelar y regresar al listado
   */
  _onCancelReturnClick() {
    this.props.history.push(this.urlList);
  }

  /**
   * Click en el boton de guardar
   */
  _onSaveClick() {
    if (this.isValid(true)) {
      this.setState({ disableButton: true }, () => {
        this.sendSaveUpdate && this.sendSaveUpdate();
      });
    } else {
      window.growlAdmin.show({
        severity: "warn",
        detail: "Ingresar los campos requeridos",
      });
    }
  }

  createButtonAction() {
    return (
      <div className="ui-g" style={{ marginTop: "10px" }}>
        <div className="ui-lg-5 ui-g-6 ui-md-12 ui-sm-12">
          <div className="ui-g">
            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12">
              <Button
                className="ui-button-warning"
                label="CANCELAR"
                onClick={this._onCancelReturnClick}
                disabled={this.state.disableButton}
              />
            </div>
            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12">
              <Button
                label="GUARDAR"
                onClick={this._onSaveClick}
                disabled={this.state.disableButton}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Crear componente html
   * @return {object} Componente html
   */
  render() {
    return (
      <div style={{ position: "relative" }}>
        <div className="ui-g ui-fluid">
          <div className={[this.classNameForm, "form-group"]}>
            {this.createForm && this.createForm()}
            {this.createButtonAction()}
          </div>
        </div>
        <SpinnerLoadingComponent isDisplay={this.state.isLoading} />
      </div>
    );
  }
}
