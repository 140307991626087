/**
 * Created by cristianmino on 2/9/18.
 */

import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

const ERROR_NEW_EVENT = "error_new_event";

let error = {
  message: "",
  code: null,
};

class ErrorStore extends EventEmitter {
  getError = () => {
    return error;
  };

  setError = (message, code) => {
    error = {
      message,
      code,
    };
  };

  emitError() {
    this.emit(ERROR_NEW_EVENT);
  }

  addErrorListener(callback) {
    this.on(ERROR_NEW_EVENT, callback);
  }

  removeErrorListener(callback) {
    this.removeListener(ERROR_NEW_EVENT, callback);
  }
}

const errorStore = new ErrorStore();

errorStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.error.BAD_REQUEST:
      errorStore.setError(action.payload.message, action.payload.code);
      errorStore.emitError();
      break;

    case AppConstants.error.AUTHORIZATION_ERROR:
      errorStore.setError(action.payload.message, action.payload.code);
      errorStore.emitError();
      break;

    case AppConstants.error.SERVER_ERROR:
      errorStore.setError(action.payload.message, action.payload.code);
      errorStore.emitError();
      break;

    case AppConstants.error.NOT_FOUND:
      errorStore.setError(action.payload.message, action.payload.code);
      errorStore.emitError();
      break;

    case AppConstants.error.CROSS_DOMAIN:
      errorStore.setError(action.payload.message, action.payload.code);
      errorStore.emitError();
      break;
    case AppConstants.error.EXPIRED_SESSION:
      errorStore.setError(action.payload.message, action.payload.code);
      errorStore.emitError();
      break;
    default:
  }
});

export default errorStore;
