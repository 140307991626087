/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/**
 * @file Componente de Rutas para verificar si esta autenticado
 * @author Wilson Guiñanzaca <wilson_g18@hotmail.com>
 * 12-Mar-2019
 */

import React, { Component } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { ProgressBar } from "primereact/components/progressbar/ProgressBar";
import AuthenticationActions from "./flux/actions/AuthenticationActions";
import AuthenticationStore from "./flux/stores/AuthenticationStore";
import conf from "./conf/conf";

const URL_ROOT = "/admin";
const URL_LOGIN = "/login";
const URL_NOT_LOGIN = [URL_LOGIN];

export default class AuthorizationRoute extends Component {
  /**
   * Inicializar state
   * @param {*} props
   */
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { isAuthenticated: false, pending: true };
    this._onAccountListener = this._onAccountListener.bind(this);
  }

  /**
   * Acciones despues de cargar el componente
   */
  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.titlePage && (document.title = this.props.titlePage);
    AuthenticationStore.addAccountListener(this._onAccountListener);
    if (!conf.NOT_LOGIN && localStorage.getItem(conf.TOKEN_KEY)) {
      AuthenticationActions.account(localStorage.getItem(conf.TOKEN_KEY));
    } else {
      this.setState({ account: null, pending: false });
    }
  }

  /**
   * Dar de baja el componente
   */
  componentWillUnmount() {
    AuthenticationStore.removeAccountListener(this._onAccountListener);
  }

  /**
   * Escuchar el dispatcher
   */
  _onAccountListener() {
    const account = AuthenticationStore.getAccount();
    if (account && account.id) {
      this.setState({ account, pending: false });
    } else {
      localStorage.removeItem(conf.TOKEN_KEY);
      window.location = "/";
    }
  }

  /**
   * Crear componente
   */
  render() {
    // eslint-disable-next-line no-shadow
    const { component: Component, isLogin, ...rest } = this.props;
    const { account, pending } = this.state;
    let element = null;
    if (pending) {
      element = (
        <Route
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          render={() => {
            return (
              <div className="ui-g ui-fluid">
                <div className="ui-sm-12 ui-md-8 ui-g-4 ui-lg-4 margin-auto ">
                  <div className="centrar">
                    <img
                      alt=""
                      src="/assets/layout/images/logo.png"
                      className="img-fluid"
                      style={{ width: "200px" }}
                    />
                  </div>
                  <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
                </div>
              </div>
            );
          }}
        />
      );
    } else if (
      account &&
      isLogin === true &&
      URL_NOT_LOGIN.includes(this.props.location.pathname)
    ) {
      element = <Redirect to={URL_ROOT} />;
    } else if (!account && this.props.location.pathname !== URL_LOGIN) {
      element = (
        <Redirect
          to={{
            pathname: URL_LOGIN,
            state: { from: this.props.location },
          }}
        />
      );
    } else {
      element = <Route {...this.props} component={Component} />;
    }

    return conf.NOT_LOGIN ? (
      <Route {...this.props} component={Component} />
    ) : (
      element
    );
  }
}
AuthorizationRoute.propTypes = {
  isAuth: PropTypes.bool,
  titlePage: PropTypes.string,
};
