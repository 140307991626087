/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let cliente = null;
const clientes = {};

const GET_CLIENTES_LIST_EVENT = "GET_CLIENTES_LIST_EVENT";
const CREATE_CLIENTE_EVENT = "CREATE_CLIENTE_EVENT";
const UPDATE_CLIENTE_EVENT = "UPDATE_CLIENTE_EVENT";
const DELETE_CLIENTE_EVENT = "DELETE_CLIENTE_EVENT";

class ClienteStore extends EventEmitter {
  getCliente = () => cliente;

  setCliente = (_cliente) => {
    cliente = _cliente;
  };

  getClientes = (componentUID) => {
    const datos = clientes[componentUID];
    delete clientes[componentUID];
    return datos;
  };

  setClientes = (componentUID, _clientes) => {
    clientes[componentUID] = _clientes;
  };

  emitGetClientes(componentUID) {
    this.emit(`${GET_CLIENTES_LIST_EVENT}_${componentUID}`);
  }

  addGetClientesListener(callback, componentUID) {
    this.on(`${GET_CLIENTES_LIST_EVENT}_${componentUID}`, callback);
  }

  removeGetClientesListener(callback, componentUID) {
    this.removeListener(`${GET_CLIENTES_LIST_EVENT}_${componentUID}`, callback);
  }

  emitCreateCliente() {
    this.emit(CREATE_CLIENTE_EVENT);
  }

  addCreateClienteListener(callback) {
    this.on(CREATE_CLIENTE_EVENT, callback);
  }

  removeCreateClienteListener(callback) {
    this.removeListener(CREATE_CLIENTE_EVENT, callback);
  }

  emitUpdateCliente() {
    this.emit(UPDATE_CLIENTE_EVENT);
  }

  addUpdateClienteListener(callback) {
    this.on(UPDATE_CLIENTE_EVENT, callback);
  }

  removeUpdateClienteListener(callback) {
    this.removeListener(UPDATE_CLIENTE_EVENT, callback);
  }

  emitDeleteCliente() {
    this.emit(DELETE_CLIENTE_EVENT);
  }

  addDeleteClienteListener(callback) {
    this.on(DELETE_CLIENTE_EVENT, callback);
  }

  removeDeleteClienteListener(callback) {
    this.removeListener(DELETE_CLIENTE_EVENT, callback);
  }
}

const clienteStore = new ClienteStore();

clienteStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.cliente.GET_LIST:
      const { componentUID } = action.payload;
      if (componentUID) {
        clienteStore.setClientes(componentUID, action.payload.data);
        clienteStore.emitGetClientes(componentUID);
      }
      break;
    case AppConstants.cliente.CREATE:
      clienteStore.setCliente(action.payload);
      clienteStore.emitCreateCliente();
      break;
    case AppConstants.cliente.UPDATE:
      clienteStore.setCliente(action.payload);
      clienteStore.emitUpdateCliente();
      break;
    case AppConstants.cliente.DELETE:
      clienteStore.setCliente(action.payload);
      clienteStore.emitDeleteCliente();
      break;
    default:
  }
});

export default clienteStore;
