/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let empleado = null;
const empleados = {};

const GET_EMPLEADOS_LIST_EVENT = "GET_EMPLEADOS_LIST_EVENT";
const CREATE_EMPLEADO_EVENT = "CREATE_EMPLEADO_EVENT";
const UPDATE_EMPLEADO_EVENT = "UPDATE_EMPLEADO_EVENT";
const DELETE_EMPLEADO_EVENT = "DELETE_EMPLEADO_EVENT";

class EmpleadoStore extends EventEmitter {
  getEmpleado = () => empleado;

  setEmpleado = (_empleado) => {
    empleado = _empleado;
  };

  getEmpleados = (componentUID) => {
    const datos = empleados[componentUID];
    delete empleados[componentUID];
    return datos;
  };

  setEmpleados = (componentUID, _empleados) => {
    empleados[componentUID] = _empleados;
  };

  emitGetEmpleados(componentUID) {
    this.emit(`${GET_EMPLEADOS_LIST_EVENT}_${componentUID}`);
  }

  addGetEmpleadosListener(callback, componentUID) {
    this.on(`${GET_EMPLEADOS_LIST_EVENT}_${componentUID}`, callback);
  }

  removeGetEmpleadosListener(callback, componentUID) {
    this.removeListener(
      `${GET_EMPLEADOS_LIST_EVENT}_${componentUID}`,
      callback
    );
  }

  emitCreateEmpleado() {
    this.emit(CREATE_EMPLEADO_EVENT);
  }

  addCreateEmpleadoListener(callback) {
    this.on(CREATE_EMPLEADO_EVENT, callback);
  }

  removeCreateEmpleadoListener(callback) {
    this.removeListener(CREATE_EMPLEADO_EVENT, callback);
  }

  emitUpdateEmpleado() {
    this.emit(UPDATE_EMPLEADO_EVENT);
  }

  addUpdateEmpleadoListener(callback) {
    this.on(UPDATE_EMPLEADO_EVENT, callback);
  }

  removeUpdateEmpleadoListener(callback) {
    this.removeListener(UPDATE_EMPLEADO_EVENT, callback);
  }

  emitDeleteEmpleado() {
    this.emit(DELETE_EMPLEADO_EVENT);
  }

  addDeleteEmpleadoListener(callback) {
    this.on(DELETE_EMPLEADO_EVENT, callback);
  }

  removeDeleteEmpleadoListener(callback) {
    this.removeListener(DELETE_EMPLEADO_EVENT, callback);
  }
}

const empleadoStore = new EmpleadoStore();

empleadoStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.empleado.GET_LIST:
      const { componentUID } = action.payload;
      if (componentUID) {
        empleadoStore.setEmpleados(componentUID, action.payload.data);
        empleadoStore.emitGetEmpleados(componentUID);
      }
      break;
    case AppConstants.empleado.CREATE:
      empleadoStore.setEmpleado(action.payload);
      empleadoStore.emitCreateEmpleado();
      break;
    case AppConstants.empleado.UPDATE:
      empleadoStore.setEmpleado(action.payload);
      empleadoStore.emitUpdateEmpleado();
      break;
    case AppConstants.empleado.DELETE:
      empleadoStore.setEmpleado(action.payload);
      empleadoStore.emitDeleteEmpleado();
      break;
    default:
  }
});

export default empleadoStore;
