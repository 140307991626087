/* eslint-disable class-methods-use-this */
/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from 'react';
import { Column } from 'primereact/components/column/Column';
import { getUUID } from '../../../components/functions/globals';
import DataTableCrudEditComponent from '../../../components/commons/table/DataTableCrudEditComponent';
import ClienteActions from '../../../flux/actions/ClienteActions';
import ClienteStore from '../../../flux/stores/ClienteStore';
import ClienteFormComponent from './ClienteFormComponent';
import ErrorStore from '../../../flux/stores/ErrorStore';

export default class ClienteListComponent extends DataTableCrudEditComponent {
  clienteUUID = getUUID();

  sectionName = 'cliente';

  labelList = 'Listado de Clientes';

  labelNew = 'Nuevo Cliente';

  labelTotal = 'Cliente';

  classNameContentTable = 'ui-xl-10 ui-lg-12 ui-g-12 ui-md-12 ui-sm-12';

  hasPagination = true;

  handlesBind() {}

  componentDidMount() {
    super.componentDidMount();
    ClienteStore.addGetClientesListener(this._onGetClientesListener, this.clienteUUID);
    ClienteStore.addDeleteClienteListener(this._onDeleteClienteListener);
    ErrorStore.addErrorListener(this._onErrorListerner);
    this.getDataList();
  }

  componentWillUnmount() {
    ClienteStore.removeGetClientesListener(this._onGetClientesListener, this.clienteUUID);
    ClienteStore.removeDeleteClienteListener(this._onDeleteClienteListener);
    ErrorStore.removeErrorListener(this._onErrorListerner);
  }

  _onGetClientesListener = () => {
    const data = ClienteStore.getClientes(this.clienteUUID);
    this.setState({
      list: data.rows,
      totalRecords: data.count,
      search: null,
      isLoading: false,
    });
  };

  getDataList() {
    const { page, rows, searchServer } = this.state;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        ClienteActions.listarClientes({ page, limit: rows, search: searchServer }, this.clienteUUID);
      },
    );
  }

  textQuestionDelete() {
    return `¿Estas seguro de eliminar un cliente: ${this.state.rowDelete ? this.state.rowDelete.descripcion : ''}?`;
  }

  sendDeleteOk() {
    ClienteActions.eliminarCliente(this.state.rowDelete[this.attrId]);
  }

  _onDeleteClienteListener = () => {
    const data = ClienteStore.getCliente();
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: 'success',
        detail: 'Cliente eliminado',
      });
      this.onSuccessDelete();
    } else {
      this.setState({ disableButtonPopupDelete: false });
      window.growlAdmin.show({
        severity: 'error',
        detail: 'Error al eliminar el cliente',
      });
    }
  };

  _onErrorListerner = () => {
    window.growlAdmin.show({
      severity: 'error',
      life: 15000,
      detail: 'Problemas en la comunicación con el servidor, comuníquese con el administrador.',
    });
    this.setState({ isLoading: false });
    this.onCloseDialogForm && this.state.isShowForm === true && this.onCloseDialogForm(true);
  };

  createColumns() {
    return [
      <Column key="1" field="nombres" header="nombres" />,
      <Column key="2" field="apellidos" header="apellidos" />,
      <Column key="3" field="rucCi" header="rucCi" />,
      <Column key="4" field="callePrincipal" header="callePrincipal" />,
      <Column key="5" field="calleSecundaria" header="calleSecundaria" />,
      <Column key="6" field="numeroCasa" header="numeroCasa" />,
      <Column key="7" field="telefono" header="telefono" />,
      <Column key="8" field="correo" header="correo" />,
      <Column key="9" field="referencia" header="referencia" />,
    ];
  }

  createAdditionalDialog() {
    return this.state.isShowForm ? <ClienteFormComponent editData={this.state.editData} onCloseDialogForm={this.onCloseDialogForm} /> : '';
  }
}
