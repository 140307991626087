/**
 * Created by cristianmino on 5/8/18.
 */
import Dispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";
import AuthenticationREST from "../api/AuthenticationREST";
import conf from "../../conf/conf";

import ErrorHandler from "../utilities/ErrorHandler";

class AuthenticationActions {
  login = async ({ username, password }) => {
    AuthenticationREST.login({ username, password })
      .then((response) => {
        localStorage.setItem(conf.TOKEN_KEY, response.access_token);
        Dispatcher.dispatch({
          actionType: AppConstants.security.LOGIN,
          payload: {
            token: response.access_token,
          },
        });
      })
      .catch((error) => {
        Dispatcher.dispatch({
          actionType: AppConstants.security.LOGIN,
          payload: {
            token: null,
          },
        });
        ErrorHandler(error);
      });
  };

  /* login = ({ username, password }) => {
        AuthenticationREST.login({ username, password })
            .then((response) => {
                const tokenBearer = response.header.authorization;
                const token = response.header.authorization.substring(7, tokenBearer.length);
                localStorage.setItem(conf.TOKEN_KEY, token);
                Dispatcher.dispatch({
                    actionType: AppConstants.security.LOGIN,
                    payload: {
                        token: token
                    }
                });
            })
            .catch((error) => {
                Dispatcher.dispatch({
                    actionType: AppConstants.security.LOGIN,
                    payload: {
                        token: null
                    }
                });
                ErrorHandler(error);
            });
    }; */

  changePassword = ({ currentPassword, newPassword }) => {
    AuthenticationREST.changePassword({ currentPassword, newPassword })
      .then((response) => {
        Dispatcher.dispatch({
          actionType: AppConstants.security.CHANGE_PASSWORD,
          payload: response.body,
        });
      })
      .catch((error) => {
        // cuando es 400, significa que el password esta incorrecto, ese mensaje tiene que visualizar en la app
        if (error.status === 400) {
          Dispatcher.dispatch({
            actionType: AppConstants.security.CHANGE_PASSWORD,
            payload: {
              error: 400,
              message: "Contraseña Incorrecta",
            },
          });
        } else {
          ErrorHandler(error);
        }
      });
  };

  logout = async () => {
    try {
      localStorage.removeItem("antapp:token");
      // localStorage.removeItem('antapp:account');

      Dispatcher.dispatch({
        actionType: AppConstants.security.LOGOUT,
        payload: "",
      });
    } catch (error) {
      ErrorHandler(error);
    }
  };

  account = (token) => {
    AuthenticationREST.account(token)
      .then((response) => {
        try {
          Dispatcher.dispatch({
            actionType: AppConstants.security.ACCOUNT,
            payload: response.data,
            token,
          });
        } catch (error) {
          // console.log("sssssssssssssss", error);
          // eslint-disable-next-line no-console
          console.error(error);
        }
      })
      .catch(() => {
        // cuando se termina la sesion en account sale error 500
        // AsyncStorage.removeItem('@mensajero:token');
        // ErrorHandler(error);
        // como este metodo es llamado cuando se inicia la app,
        // si retorna error, este tiene que lazanr un evento para que pida de nuevo el login
        // el logout es el metodo que redirecciona al login
        // Dispatcher.dispatch({
        //     actionType: AppConstants.security.LOGOUT,
        //     payload: ""
        // });
        Dispatcher.dispatch({
          actionType: AppConstants.security.ACCOUNT,
          payload: "",
        });
      });
  };
}

export default new AuthenticationActions();
