/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import { getSuperagent, postSuperagent, putSuperagent, deleteSuperagent } from './SuperagentConfig';

class HojaRutaREST {
  listarHojasRutas = ({ hRutaNumero, choferId, ayudanteId, vehiculoId, remitenteId, fechaInicio, fechaFin, page, limit }) => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent(
        `/api/hrutas?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&hRutaNumero=${hRutaNumero}&choferId=${choferId}&ayudanteId=${ayudanteId}&vehiculoId=${vehiculoId}&remitenteId=${remitenteId}&page=${page}&limit=${limit}`,
      ).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  crearHojaRuta = (data) => {
    const promesa = new Promise((resolve, reject) => {
      postSuperagent('/api/hrutas', data).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  actualizarHojaRuta = ({ id, observacion }) => {
    const promesa = new Promise((resolve, reject) => {
      putSuperagent(`/api/hrutas/${id}`, { observacion }).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  eliminarHojaRuta = (id) => {
    const promesa = new Promise((resolve, reject) => {
      deleteSuperagent(`/api/hrutas/${id}`).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };
}

export default new HojaRutaREST();
