import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

import GlobalContext from "../GlobalContext";

const SECURITY_LOGIN_EVENT = "SECURITY_LOGIN_EVENT";
const SECURITY_ACCOUNT_EVENT = "security_account_event";
const SECURITY_LOGOUT_EVENT = "security_logout_event";
const SECURITY_CHANGEPASSWORD_EVENT = "security_changepassword_event";

let logout = {};

let changePasswordResult = {};

class AuthenticationStore extends EventEmitter {
  getToken = () => {
    return GlobalContext.ACCESSTOKEN;
  };

  setToken = (_token) => {
    GlobalContext.ACCESSTOKEN = _token;
  };

  getAccount = () => {
    return GlobalContext.SESSION;
  };

  setAccount = (_account, _token) => {
    GlobalContext.ACCESSTOKEN = _token;
    GlobalContext.SESSION = _account;
    GlobalContext.ROLE_ID = _account ? _account.rolDefecto : null;
  };

  getLogout = () => {
    return logout;
  };

  setLogout = (_logout) => {
    logout = _logout;
  };

  getChangePasswordResult = () => {
    return changePasswordResult;
  };

  setChangePasswordResult = (_changePasswordResult) => {
    changePasswordResult = _changePasswordResult;
  };

  emitLogin = () => {
    this.emit(SECURITY_LOGIN_EVENT);
  };

  addLoginListener = (callback) => {
    this.on(SECURITY_LOGIN_EVENT, callback);
  };

  removeLoginListener = (callback) => {
    this.removeListener(SECURITY_LOGIN_EVENT, callback);
  };

  emitAccount = () => {
    this.emit(SECURITY_ACCOUNT_EVENT);
  };

  addAccountListener = (callback) => {
    this.on(SECURITY_ACCOUNT_EVENT, callback);
  };

  removeAccountListener = (callback) => {
    this.removeListener(SECURITY_ACCOUNT_EVENT, callback);
  };

  emitLogout = () => {
    this.emit(SECURITY_LOGOUT_EVENT);
  };

  addLogoutListener = (callback) => {
    this.on(SECURITY_LOGOUT_EVENT, callback);
  };

  removeLogoutListener = (callback) => {
    this.removeListener(SECURITY_LOGOUT_EVENT, callback);
  };

  emitChangePassword = () => {
    this.emit(SECURITY_CHANGEPASSWORD_EVENT);
  };

  addChangePasswordListener = (callback) => {
    this.on(SECURITY_CHANGEPASSWORD_EVENT, callback);
  };

  removeChangePasswordListener = (callback) => {
    this.removeListener(SECURITY_CHANGEPASSWORD_EVENT, callback);
  };
}

const authenticationStore = new AuthenticationStore();

authenticationStore.dispatchToken = AppDispatcher.register((action) => {
  // const action = payload.actionType;
  switch (action.actionType) {
    case AppConstants.security.LOGIN:
      authenticationStore.setToken(action.payload.token);
      authenticationStore.emitLogin();
      break;
    case AppConstants.security.ACCOUNT:
      authenticationStore.setAccount(action.payload, action.token);
      authenticationStore.emitAccount();
      break;
    case AppConstants.security.LOGOUT:
      authenticationStore.setLogout(action.payload);
      authenticationStore.emitLogout();
      break;
    case AppConstants.security.CHANGE_PASSWORD:
      authenticationStore.setChangePasswordResult(action.payload);
      authenticationStore.emitChangePassword();
      break;
    default:
  }
});

export default authenticationStore;
