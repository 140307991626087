import superagent from "superagent";
import conf from "../../conf/conf";

const baseURL = conf.URL_SERVER;
const urlApi = baseURL + conf.URL_SERVER_API;
const token = localStorage.getItem(conf.TOKEN_KEY);

export const getSuperagent = (url, queryParams, isOnlyBaseUrl) => {
  const urlRequest = (isOnlyBaseUrl ? baseURL : urlApi) + url;
  const superagentRequest = superagent
    .get(urlRequest)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .query(queryParams);
  token && superagentRequest.set("Authorization", conf.TOKEN_STRING + token);

  return superagentRequest;
};

export const postSuperagent = (url, data, isOnlyBaseUrl) => {
  const urlRequest = (isOnlyBaseUrl ? baseURL : urlApi) + url;
  const superagentRequest = superagent
    .post(urlRequest)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json");
  token && superagentRequest.set("Authorization", conf.TOKEN_STRING + token);
  return data ? superagentRequest.send(data) : superagentRequest;
};

export const putSuperagent = (url, data, queryParams, isOnlyBaseUrl) => {
  const urlRequest = (isOnlyBaseUrl ? baseURL : urlApi) + url;
  const superagentRequest = superagent
    .put(urlRequest)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .query(queryParams);
  token && superagentRequest.set("Authorization", conf.TOKEN_STRING + token);
  return superagentRequest.send(data);
};

export const deleteSuperagent = (url, queryParams, isOnlyBaseUrl) => {
  const urlRequest = (isOnlyBaseUrl ? baseURL : urlApi) + url;
  const superagentRequest = superagent
    .del(urlRequest)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .query(queryParams);
  token && superagentRequest.set("Authorization", conf.TOKEN_STRING + token);
  return superagentRequest;
};
