/**
 * Created by cristianmino on 15/8/18.
 */

import Dispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

import ErrorMessage from "./ErrorMessage";

function ErrorHandler(error) {
  const data = error.response ? error.response.body : null;
  if (error.status === 400) {
    Dispatcher.dispatch({
      actionType: AppConstants.error.BAD_REQUEST,
      payload: {
        message: data ? data.message : ErrorMessage.MSG_400,
        code: error.status,
      },
    });
  } else if (error.status === 401) {
    Dispatcher.dispatch({
      actionType: AppConstants.error.AUTHORIZATION_ERROR,
      payload: {
        message: data,
        code: error.status,
      },
    });
  } else if (error.status === 404) {
    Dispatcher.dispatch({
      actionType: AppConstants.error.NOT_FOUND,
      payload: {
        message: data ? data.message : ErrorMessage.MSG_404,
        code: error.status,
      },
    });
  } else if (error.status === 500) {
    Dispatcher.dispatch({
      actionType: AppConstants.error.SERVER_ERROR,
      payload: {
        message: data ? data.message : ErrorMessage.MSG_500,
        code: error.status,
      },
    });
  } else if (error.status === 403) {
    Dispatcher.dispatch({
      actionType: AppConstants.error.EXPIRED_SESSION,
      payload: {
        message: data,
        code: error.status,
      },
    });
  } else {
    Dispatcher.dispatch({
      actionType: AppConstants.error.NOT_FOUND,
      payload: {
        message: data ? data.message : ErrorMessage.MSG_XXX,
        code: error.status,
      },
    });
  }
}

export default ErrorHandler;
