/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from "react";
import { Column } from "primereact/components/column/Column";
import { getUUID } from "../../../components/functions/globals";
import DataTableCrudEditComponent from "../../../components/commons/table/DataTableCrudEditComponent";
import VehiculoActions from "../../../flux/actions/VehiculoActions";
import VehiculoStore from "../../../flux/stores/VehiculoStore";
import VehiculoFormComponent from "./VehiculoFormComponent";
import ErrorStore from "../../../flux/stores/ErrorStore";

export default class VehiculoListComponent extends DataTableCrudEditComponent {
  sectionName = "vehiculo";
  labelList = "Listado de Vehículos";
  labelNew = "Nueva Vehículo";
  labelTotal = "Vehículo";
  classNameContentTable = "ui-xl-10 ui-lg-12 ui-g-12 ui-md-12 ui-sm-12";
  hasPagination = true;
  uuidVehiculo = getUUID();

  handlesBind() {}

  componentDidMount() {
    super.componentDidMount();
    VehiculoStore.addGetVehiculosListener(
      this._onGetVehiculosListener,
      this.uuidVehiculo
    );
    VehiculoStore.addDeleteVehiculoListener(this._onDeleteVehiculoListener);
    ErrorStore.addErrorListener(this._onErrorListerner);
    this.getDataList();
  }

  componentWillUnmount() {
    VehiculoStore.removeGetVehiculosListener(
      this._onGetVehiculosListener,
      this.uuidVehiculo
    );
    VehiculoStore.removeDeleteVehiculoListener(this._onDeleteVehiculoListener);
    ErrorStore.removeErrorListener(this._onErrorListerner);
  }

  _onGetVehiculosListener = () => {
    const data = VehiculoStore.getVehiculos(this.uuidVehiculo);
    this.setState({
      list: data.rows,
      totalRecords: data.count,
      search: null,
      isLoading: false,
    });
  };

  getDataList() {
    const { page, rows, searchServer } = this.state;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        VehiculoActions.listarVehiculos(
          { page, limit: rows, search: searchServer },
          this.uuidVehiculo
        );
      }
    );
  }

  textQuestionDelete() {
    return (
      "¿Estas seguro de eliminar el vehículo: " +
      (this.state.rowDelete ? this.state.rowDelete.placa : "") +
      "?"
    );
  }
  sendDeleteOk() {
    VehiculoActions.eliminarVehiculo(this.state.rowDelete[this.attrId]);
  }

  _onDeleteVehiculoListener = () => {
    const data = VehiculoStore.getVehiculo();
    if (data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: "success",
        detail: "Vehículo eliminado",
      });
      this.onSuccessDelete();
    } else {
      this.setState({ disableButtonPopupDelete: false });
      window.growlAdmin.show({
        severity: "error",
        detail: "Error al eliminar el Vehículo",
      });
    }
  };
  _onErrorListerner = () => {
    window.growlAdmin.show({
      severity: "error",
      life: 15000,
      detail:
        "Problemas en la comunicación con el servidor, comuníquese con el administrador.",
    });
    this.setState({ isLoading: false });
    this.onCloseDialogForm &&
      this.state.isShowForm === true &&
      this.onCloseDialogForm(true);
  };

  createColumns() {
    return [
      <Column key="0" field="descripcion" header="Descripción" />,
      <Column key="1" field="placa" header="Placa" />,
    ];
  }

  createAdditionalDialog() {
    return this.state.isShowForm ? (
      <VehiculoFormComponent
        editData={this.state.editData}
        onCloseDialogForm={this.onCloseDialogForm}
      />
    ) : (
      ""
    );
  }
}
