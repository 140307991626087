/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 27th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 27th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from 'react';
import { Column } from 'primereact/components/column/Column';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Button } from 'primereact/components/button/Button';
import { RadioButton } from 'primereact/components/radiobutton/RadioButton';
import { Calendar } from 'primereact/components/calendar/Calendar';
import { Dialog } from 'primereact/components/dialog/Dialog';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import $ from 'jquery';
import _ from 'lodash';

import DataTableCrudComponent from '../../../components/commons/table/DataTableCrudComponent';
import { localeString, FORMAT_DATE_CALENDAR, FORMAT_DATE_MOMENT, getUUID } from '../../../components/functions/globals';
import ErrorStore from '../../../flux/stores/ErrorStore';
import { URL_SERVER, TOKEN_KEY } from '../../../conf/conf';

import ClienteREST from '../../../flux/api/ClienteREST';

import RutaStore from '../../../flux/stores/RutaStore';
import RutaActions from '../../../flux/actions/RutaActions';
import ProductoStore from '../../../flux/stores/ProductoStore';
import ProductoActions from '../../../flux/actions/ProductoActions';
import EmpleadoStore from '../../../flux/stores/EmpleadoStore';
import EmpleadoActions from '../../../flux/actions/EmpleadoActions';
import HojaRutaStore from '../../../flux/stores/HojaRutaStore';
import HojaRutaActions from '../../../flux/actions/HojaRutaActions';
import VehiculoStore from '../../../flux/stores/VehiculoStore';
import VehiculoActions from '../../../flux/actions/VehiculoActions';

export default class CrearHojaRutaComponent extends DataTableCrudComponent {
  uuidCliente = getUUID();

  uuiRuta = getUUID();

  uuidProducto = getUUID();

  uuidEmpleado = getUUID();

  uuidVehiculo = getUUID();

  sectionName = 'crear-hruta';

  labelList = 'Hoja de Ruta';

  labelNew = '';

  labelTotal = 'Hoja de Ruta Detalle';

  classNameContentTable = 'ui-xl-10 ui-lg-12 ui-g-12 ui-md-12 ui-sm-12';

  hasPagination = true;

  hasEdit = false;

  hasSearch = false;

  hasNew = false;

  initialState() {
    let data = {
      id: null,
      fecha: moment().format(FORMAT_DATE_MOMENT),
      remitente: null,
      remitentes: [],
      cliente: null,
      clientes: [],
      recibeNombre: '',
      recibeCedula: '',
      recibeFecha: moment().toDate(),
      recibeTemperatura: null,
      recibeHumedad: null,
      chofer: null,
      choferes: [],
      ayudante: null,
      ayudantes: [],
      horaSalida: null,
      vehiculo: null,
      vehiculos: [],

      rutaOrigen: null,
      rutaOrigenes: [],

      rutaDestino: null,
      rutaDestinos: [],

      producto: null,
      productos: [],

      cantidad: 0,
      valorUnitario: 0,

      isVisibleDialogGuardar: false,
      hrutaId: '',
      hrutaNumero: null,
      observacion: '',

      imprimirPrecio: true,
      hasDelete: true, // no funciona esa hvda de variable en el this, siempre lee los permisos de conf/security.js
    };

    const hRutaEditar = HojaRutaStore.getHojaRutaSet();
    if (hRutaEditar) {
      const {
        id,
        hrutaNumero,
        fecha,
        horaSalida,
        remitente,
        rutaOrigen,
        hRutasDetalles,
        chofer,
        ayudante,
        vehiculo,
        recibeNombre,
        recibeCedula,
        recibeFecha,
        recibeHumedad,
        recibeTemperatura,
        observacion,
      } = hRutaEditar;
      this.labelList = 'Editar Hoja de Ruta';

      const hRutasDetallesTMP = _.map(hRutasDetalles, (detalleObj) => {
        const { id, numeroDocumento, cantidad, producto, cliente, rutaDestino } = detalleObj;
        const value = {
          ...detalleObj,
          ...{
            id,
            numeroDocumento,
            cantidad,
            cliente: {
              value: cliente.id,
              label: `${cliente.nombres} ${cliente.apellidos}`,
            },
            rutaDestino: {
              value: rutaDestino.id,
              label: rutaDestino.descripcion,
            },
            producto: { value: producto.id, label: producto.descripcion },
          },
        };
        return value;
      });

      data = {
        ...data,
        ...{
          id,
          hrutaNumero,
          fecha: moment(fecha, FORMAT_DATE_MOMENT).format(FORMAT_DATE_MOMENT),
          horaSalida,
          remitente: {
            value: remitente.id,
            label: `${remitente.nombres} ${remitente.apellidos}`,
          },
          rutaOrigen: { value: rutaOrigen.id, label: rutaOrigen.descripcion },
          chofer: {
            value: chofer.id,
            label: `${chofer.nombres} ${chofer.apellidos}`,
          },
          ayudante: {
            value: ayudante.id,
            label: `${ayudante.nombres} ${ayudante.apellidos}`,
          },
          vehiculo: {
            value: vehiculo.id,
            label: `${vehiculo.descripcion} - ${vehiculo.placa}`,
          },
          list: hRutasDetallesTMP,
          totalRecords: hRutasDetallesTMP.length > 20 ? 20 : hRutasDetallesTMP.length,
          // totalRecords: hRutasDetallesTMP.length,
          hasDelete: false,
          hasEdit: false,
          recibeNombre,
          recibeCedula,
          recibeHumedad,
          recibeTemperatura,
          observacion,
          recibeFecha: recibeFecha ? moment.unix(recibeFecha).toDate() : new Date(),
        },
      };
    }
    return data;
  }

  /**
   * no funcia la variable global de hasEdit, hasDelete, simpre toma la validacion de conf/security.js por lo cual se sobrescribio este metodo
   */
  createColumnAction() {
    const { hasDelete, hasEdit } = this.state;
    const actionTemplate = (rowData) => {
      return (
        <div>
          {hasEdit ? <Button type="button" icon="fa fa-pencil-alt" title="Editar" className="ui-button-success" onClick={() => this._onEditColumnClick(rowData)} style={{ marginRight: '5px' }} /> : ''}
          {hasDelete ? <Button type="button" icon="fa fa-trash" title="Eliminar" className="ui-button-danger" onClick={() => this._onShowHideDialogDelete(true, rowData)} /> : ''}
        </div>
      );
    };
    return (
      <Column
        body={actionTemplate}
        style={{
          ...{ textAlign: 'center', width: '120px' },
          ...this.styleColumnAction,
        }}
      />
    );
  }

  componentDidMount() {
    super.componentDidMount();
    // this.getDataList();
    EmpleadoStore.addGetEmpleadosListener(this._onGetEmpleadosListener, this.uuidEmpleado);
    VehiculoStore.addGetVehiculosListener(this._onGetVehiculosListener, this.uuidVehiculo);
    // ClienteStore.addGetClientesListener(this._onGetClientesListener, this.uuidCliente);
    RutaStore.addGetRutasListener(this._onGetRutasListener, this.uuiRuta);
    ProductoStore.addGetProductosListener(this._onGetProductosListener, this.uuidProducto);
    ErrorStore.addErrorListener(this._onErrorListerner);
    HojaRutaStore.addCreateHojaRutaListener(this._onCreateHojaRutaListener);
    HojaRutaStore.addUpdateHojaRutaListener(this._onUpdateHojaRutaListener);
    // solo se ejecuta una vez, y en el listener se llama para los dos (chofer->ayudante, orgien->destino, etc)
    EmpleadoActions.listarAllEmpleados(this.uuidEmpleado);
    VehiculoActions.listarAllVehiculos(this.uuidVehiculo);
    // ClienteActions.listarAllClientes(this.uuidCliente);
    RutaActions.listarAllRutas(this.uuiRuta);
    ProductoActions.listarAllProductos(this.uuidProducto);
  }

  componentWillUnmount() {
    EmpleadoStore.removeGetEmpleadosListener(this._onGetEmpleadosListener, this.uuidEmpleado);
    VehiculoStore.removeGetVehiculosListener(this._onGetVehiculosListener, this.uuidVehiculo);
    // ClienteStore.removeGetClientesListener(this._onGetClientesListener, this.uuidCliente);
    RutaStore.removeGetRutasListener(this._onGetRutasListener, this.uuiRuta);
    ProductoStore.removeGetProductosListener(this._onGetProductosListener, this.uuidProducto);
    ErrorStore.removeErrorListener(this._onErrorListerner);
    HojaRutaStore.removeCreateHojaRutaListener(this._onCreateHojaRutaListener);
    HojaRutaStore.removeUpdateHojaRutaListener(this._onUpdateHojaRutaListener);
  }

  textQuestionDelete() {
    return `¿Estas seguro de eliminar una Hoja de Ruta: ${this.state.rowDelete ? `${this.state.rowDelete.cantidad} ${this.state.rowDelete.detalle}` : ''}?`;
  }

  sendDeleteOk() {
    this.setState((prevState) => {
      const newList = _.remove(prevState.list, (detalle) => {
        return detalle.id !== prevState.rowDelete.id;
      });
      return {
        list: newList,
        totalRecords: newList.length,
        search: null,
        isLoading: false,
      };
    });
    this.onSuccessDelete();
  }

  // ----------------------------------- SELECT EMPLEADOS (chofer, ayudante)
  _onGetEmpleadosListener = () => {
    const data = EmpleadoStore.getEmpleados(this.uuidEmpleado);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: `${item.nombres} ${item.apellidos}`,
    }));
    this.setState({ choferes: dataList, ayudantes: dataList });
  };

  onChangeSelectEmpleadoChofer = (chofer) => {
    this.setState({ chofer });
  };

  onChangeSelectEmpleadoAyudante = (ayudante) => {
    this.setState({ ayudante });
  };

  // ----------------------------------- SELECT VEHICULO
  _onGetVehiculosListener = () => {
    const data = VehiculoStore.getVehiculos(this.uuidVehiculo);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: `${item.descripcion} - ${item.placa}`,
    }));
    this.setState({ vehiculos: dataList });
  };

  onChangeSelectVehiculo = (vehiculo) => {
    this.setState({ vehiculo });
  };

  // ----------------------------------- SELECT CLIENTES (remitente, destinatario)

  loadOptionsRemitente = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      ClienteREST.listarClientes({ search: inputValue })
        .then((respuesta) => {
          if (respuesta.data) {
            const dataList = respuesta.data.rows.map((item) => ({
              value: item.id,
              label: `${item.nombres} ${item.apellidos}`,
            }));
            callback(dataList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  onChangeSelectClienteRemitente = (remitente) => {
    this.setState({ remitente });
  };

  loadOptionsCliente = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      ClienteREST.listarClientes({ search: inputValue })
        .then((respuesta) => {
          if (respuesta.data) {
            const dataList = respuesta.data.rows.map((item) => ({
              value: item.id,
              label: `${item.nombres} ${item.apellidos}`,
            }));
            callback(dataList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  onChangeSelectCliente = (cliente) => {
    this.setState({ cliente });
  };

  // ----------------------------------- SELECT RUTAS (origen, destino)
  _onGetRutasListener = () => {
    const data = RutaStore.getRutas(this.uuiRuta);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: `${item.descripcion} - ${item.via}`,
    }));
    this.setState({ rutaOrigenes: dataList, rutaDestinos: dataList });
  };

  onChangeSelectRutaOrigen = (rutaOrigen) => {
    this.setState({ rutaOrigen });
  };

  onChangeSelectRutaDestino = (rutaDestino) => {
    this.setState({ rutaDestino });
  };

  // ----------------------------------- SELECT PRODUCTO
  _onGetProductosListener = () => {
    const data = ProductoStore.getProductos(this.uuidProducto);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: item.descripcion,
      precio: item.precio,
    }));
    this.setState({ productos: dataList });
  };

  onChangeSelectProducto = (producto) => {
    this.setState({ producto, valorUnitario: producto.precio });
  };

  _onErrorListerner = () => {
    window.growlAdmin.show({
      severity: 'error',
      life: 15000,
      detail: 'Problemas en la comunicación con el servidor, comuníquese con el administrador.',
    });
    this.onCloseDialogForm && this.onCloseDialogForm(true);
    this.setState({ isLoading: false });
  };

  // para manejar el handle event de los textinput
  handleInputChange = (e) => {
    const { target } = e;
    let value = $.trim(target.value).length === 0 ? null : target.value;
    const { name } = target;
    this.inputChange = { [name]: value };
    if (name.lastIndexOf('.') !== -1) {
      const nameAttr = name.substring(name.indexOf('.') + 1);
      const nameParent = name.substring(0, name.indexOf('.'));
      value = { ...this.state[nameParent], ...{ [nameAttr]: value } };
    }
    this.setStateChange(name, value, target);
  };

  setStateChange = (name, value, target) => {
    if (name.lastIndexOf('.') !== -1) {
      name = name.substring(0, name.indexOf('.'));
    }
    this.setState({ [name]: value }, () => {});
  };

  // agregar un detalle
  onClickAgregarDetalle = () => {
    const { numeroDocumento, cliente, producto, cantidad, rutaDestino } = this.state;
    if (numeroDocumento && cliente && producto && cantidad && rutaDestino) {
      const data = {
        id: getUUID(),
        numeroDocumento,
        clienteId: cliente.value,
        cliente,
        productoId: producto.value,
        producto,
        cantidad,
        rutaDestinoId: rutaDestino.value,
        rutaDestino,
      };
      const dataList = [...this.state.list, data];
      this.setState({
        list: dataList,
        totalRecords: dataList.length,
        search: null,
        isLoading: false,
        numeroDocumento: '',
        cliente: null,
        producto: null,
        cantidad: '',
        rutaDestino: null,
      });
    } else {
      window.growlAdmin.show({
        severity: 'warn',
        life: 15000,
        detail: 'Ingrese el nro de documento, cliente, producto, cantidad, y destino.',
      });
    }
  };

  // guardar y enviar al servidor
  onClickGuardar = () => {
    if (this.state.id === null) {
      // cuando no es edicion
      const { horaSalida, chofer, ayudante, vehiculo, remitente, rutaOrigen, observacion } = this.state;
      if (!chofer || !ayudante || !vehiculo || !remitente || !rutaOrigen) {
        window.growlAdmin.show({
          severity: 'warn',
          life: 15000,
          detail: 'Ingrese el chofer, ayudante, vehículo, remitente, y ruta de origen.',
        });
        return;
      }

      if (this.state.list.length < 1) {
        window.growlAdmin.show({
          severity: 'warn',
          life: 15000,
          detail: 'Ingrese el detalle de la Hoja de Ruta.',
        });
        return;
      }

      const hRutasDetalles = _.map(this.state.list, (item) => {
        const { numeroDocumento, clienteId, productoId, cantidad, rutaDestinoId } = item;
        return {
          numeroDocumento,
          clienteId,
          productoId,
          cantidad,
          rutaDestinoId,
        };
      });

      const data = {
        horaSalida,
        choferId: chofer.value,
        ayudanteId: ayudante.value,
        vehiculoId: vehiculo.value,
        remitenteId: remitente.value,
        rutaOrigenId: rutaOrigen.value,
        observacion,
        hRutasDetalles,
      };

      this.setState({ isLoading: true }, () => {
        HojaRutaActions.crearHojaRuta(data);
      });
    } else {
      const { observacion, id } = this.state;
      if (observacion) {
        this.setState({ isLoading: true }, () => {
          HojaRutaActions.actualizarHojaRuta({ id, observacion });
        });
      } else {
        window.growlAdmin.show({
          severity: 'warn',
          life: 15000,
          detail: 'Ingrese alguna observación.',
        });
      }
    }
  };

  _onCreateHojaRutaListener = () => {
    const hRuta = HojaRutaStore.getHojaRuta();
    // console.log('guia creada', guia)
    this.setState({
      hrutaId: hRuta.id,
      hrutaNumero: hRuta.hrutaNumero,
      isLoading: false,
    });
    this._onVisibleDialogGuardar(true);
  };

  _onUpdateHojaRutaListener = () => {
    let that = this;
    const hRuta = HojaRutaStore.getHojaRuta();
    this.setState(
      {
        hrutaId: hRuta.id,
        hrutaNumero: hRuta.hrutaNumero,
        isLoading: false,
      },
      () => {
        window.growlAdmin.show({
          severity: 'success',
          life: 15000,
          detail: 'Hoja de Ruta actualizada correctamente',
        });
        that.props.history.push('/admin/listar-hruta');
      },
    );
  };

  _onClickOkImprimir = () => {
    this.setState({ isLoading: true, isVisibleDialogGuardar: false });
    fetch(`${URL_SERVER}/api/hrutas/${this.state.hrutaId}/imprimir?imprimirPrecio=${this.state.imprimirPrecio}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Credentials': '*',
        'Content-type': 'application/pdf',
        'Content-Disposition': 'attachment; filename="downloaded.pdf',
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
      responseType: 'blob',
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
        window.open(url);
        window.URL.revokeObjectURL(url);
        // const url = window.URL.createObjectURL(new Blob([response]));
        // const a = document.createElement("a");
        // a.download = `hoja_ruta_${this.state.hrutaNumero}.pdf`;
        // a.target = "_blank";
        // a.href = url;
        // a.click();
        this.setState({ isLoading: false });
        this._onClickLimpiar();
      });
  };

  _onVisibleDialogGuardar = (estado) => {
    this.setState({ isVisibleDialogGuardar: estado });
  };

  createPopupGuardar = () => {
    const footerDialog = (
      <div>
        <Button label="SI" icon="fa fa-check" onClick={(e) => this._onClickOkImprimir()} disabled={this.state.disableButtonPopupDelete} />
        <Button
          label="NO"
          className="ui-button-warning"
          icon="fa fa-times"
          onClick={(e) => {
            this._onVisibleDialogGuardar(false);
            this._onClickLimpiar();
          }}
        />
      </div>
    );
    return (
      <Dialog header="Imprimir" footer={footerDialog} visible={this.state.isVisibleDialogGuardar} style={{ width: '400px' }} modal closable={false} onHide={() => this._onVisibleDialogGuardar(false)}>
        Hoja de ruta creada correctamente, desea imprimir?
        <div style={{ margin: 10 }}>
          <RadioButton inputId="cb1" onChange={(e) => this.setState({ imprimirPrecio: e.checked })} checked={this.state.imprimirPrecio} /> Imprimir con el precio
        </div>
      </Dialog>
    );
  };
  _onClickLimpiar = () => {
    HojaRutaActions.setHojaRuta(null);
    this.setState(
      {
        id: null,
        hrutaNumero: null,
        fecha: moment().format(FORMAT_DATE_MOMENT),
        list: [],
        totalRecords: 0,
        chofer: null,
        ayudante: null,
        vehiculo: null,
        remitente: null,
        rutaOrigen: null,
        producto: null,
        observacion: '',
        horaSalida: '',
        cantidad: 0,
        cliente: null,
        rutaDestino: null,
        isVisibleDialogGuardar: false,
        hrutaId: '',
        imprimirPrecio: true,
        hasDelete: true,
      },
      () => {
        this.props.history.push('/admin/listar-hruta');
      },
    );
  };

  createFilter() {
    const disabled = this.state.id !== null;
    return (
      <div>
        <div
          className="ui-fluid"
          style={{
            border: ' 1px solid #D3D3D3',
            borderRadius: '5px',
            margin: '8px',
          }}
        >
          <div className="ui-grid ui-fluid padding-corto">
            <div className="ui-grid">
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Fecha:</label>
                </div>
                <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                  <InputText id="fecha" name="fecha" type="text" value={this.state.fecha || ''} maxLength="50" readOnly disabled />
                </div>
              </div>
              {this.state.id !== null && (
                <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12">
                  <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                    <label>Nro de Hoja de Ruta:</label>
                  </div>
                  <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                    <InputText id="hrutaNumero" name="hrutaNumero" type="text" value={this.state.hrutaNumero || ''} maxLength="50" readOnly disabled />
                  </div>
                </div>
              )}
            </div>

            <div className="ui-grid">
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Chofer:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <Select
                    cacheOptions
                    defaultOptions
                    value={this.state.chofer}
                    options={this.state.choferes}
                    onChange={(option) => this.onChangeSelectEmpleadoChofer(option)}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay choferes'}
                    placeholder="Buscar chofer..."
                    isDisabled={disabled}
                  />
                </div>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Ayudante:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <Select
                    cacheOptions
                    defaultOptions
                    value={this.state.ayudante}
                    options={this.state.ayudantes}
                    onChange={(option) => this.onChangeSelectEmpleadoAyudante(option)}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay ayudantes'}
                    placeholder="Buscar ayudante..."
                    isDisabled={disabled}
                  />
                </div>
              </div>
            </div>

            <div className="ui-grid">
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Vehículo:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <Select
                    cacheOptions
                    defaultOptions
                    value={this.state.vehiculo}
                    options={this.state.vehiculos}
                    onChange={(option) => this.onChangeSelectVehiculo(option)}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay vehículos'}
                    placeholder="Buscar vehículo..."
                    isDisabled={disabled}
                  />
                </div>
              </div>

              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Remitente:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <AsyncSelect
                    cacheOptions
                    value={this.state.remitente}
                    onChange={this.onChangeSelectClienteRemitente}
                    loadOptions={this.loadOptionsRemitente}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay remitentes'}
                    placeholder="Buscar remitente..."
                    isDisabled={disabled}
                  />
                </div>
              </div>
            </div>

            <div className="ui-grid">
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Origen:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <Select
                    cacheOptions
                    defaultOptions
                    value={this.state.rutaOrigen}
                    options={this.state.rutaOrigenes}
                    onChange={(option) => this.onChangeSelectRutaOrigen(option)}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay rutas origen'}
                    placeholder="Buscar ruta origen..."
                    isDisabled={disabled}
                  />
                </div>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Hora de salida:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <InputText
                    id="horaSalida"
                    name="horaSalida"
                    type="text"
                    value={this.state.horaSalida || ''}
                    maxLength="5"
                    onChange={this.handleInputChange}
                    style={{ marginLeft: '8px' }}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>

            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Observaciones:</label>
              </div>
              <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                <InputText id="observacion" name="observacion" type="text" value={this.state.observacion || ''} maxLength="200" onChange={this.handleInputChange} style={{ marginLeft: '8px' }} />
              </div>
            </div>

            {
              // preguntar si no se necesita, para borrar
              this.state.id !== null && false && (
                <div>
                  <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                    <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                      <label>Recibe Nombre:</label>
                    </div>
                    <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                      <InputText
                        id="recibeNombre"
                        name="recibeNombre"
                        type="text"
                        value={this.state.recibeNombre || ''}
                        maxLength="100"
                        onChange={this.handleInputChange}
                        style={{ marginLeft: '8px' }}
                      />
                    </div>
                  </div>
                  <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                    <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                      <label>CI:</label>
                    </div>
                    <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                      <InputText
                        id="recibeCedula"
                        name="recibeCedula"
                        type="text"
                        value={this.state.recibeCedula || ''}
                        maxLength="50"
                        onChange={this.handleInputChange}
                        keyfilter={/(\d)+/g}
                        style={{ marginLeft: '8px' }}
                      />
                    </div>
                  </div>
                  <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                    <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                      <label>Fecha:</label>
                    </div>
                    <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                      <Calendar
                        value={this.state.recibeFecha}
                        onChange={(e) => this.setState({ recibeFecha: e.value })}
                        showIcon
                        locale={localeString('es')}
                        showTime
                        showSeconds
                        icon="fa fa-calendar"
                        hourFormat="24"
                        touchUI
                        monthNavigator
                        yearNavigator
                        yearRange={`2010:${moment().get('year')}`}
                        dateFormat={FORMAT_DATE_CALENDAR}
                        placeholder={FORMAT_DATE_MOMENT}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                    <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                      <label>Temperatura:</label>
                    </div>
                    <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                      <InputText
                        id="recibeTemperatura"
                        name="recibeTemperatura"
                        type="text"
                        value={this.state.recibeTemperatura || ''}
                        maxLength="10"
                        onChange={this.handleInputChange}
                        keyfilter={/(\d)+/g}
                        style={{ marginLeft: '8px' }}
                      />
                    </div>
                  </div>
                  <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                    <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                      <label>Humedad:</label>
                    </div>
                    <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                      <InputText
                        id="recibeHumedad"
                        name="recibeHumedad"
                        type="text"
                        value={this.state.recibeHumedad || ''}
                        maxLength="10"
                        onChange={this.handleInputChange}
                        keyfilter={/(\d)+/g}
                        style={{ marginLeft: '8px' }}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
        <label style={{ margin: '10px' }}>Detalle de la Hoja de Ruta</label>
        {this.state.id === null && (
          <div
            className="ui-fluid"
            style={{
              border: '0.5px solid #D3D3D3',
              borderRadius: '5px',
              margin: '8px',
            }}
          >
            <div className="ui-grid ui-fluid padding-corto">
              <div className="ui-grid">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                    <label>Nro. doc:</label>
                  </div>
                  <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                    <InputText
                      ref={(select) => {
                        this.numeroDocumentoRef = select;
                      }}
                      id="numeroDocumento"
                      name="numeroDocumento"
                      type="text"
                      value={this.state.numeroDocumento || ''}
                      maxLength="50"
                      onChange={this.handleInputChange}
                      disabled={disabled}
                    />
                  </div>
                </div>

                <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                  <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                    <label>Cliente:</label>
                  </div>
                  <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                    <AsyncSelect
                      cacheOptions
                      value={this.state.cliente}
                      onChange={this.onChangeSelectCliente}
                      loadOptions={this.loadOptionsCliente}
                      className="select-custom"
                      classNamePrefix="select-react"
                      noOptionsMessage={() => 'No hay clientes'}
                      placeholder="Buscar clientes..."
                      isDisabled={disabled}
                    />
                  </div>
                </div>

                <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                  <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                    <label>Producto:</label>
                  </div>
                  <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                    <Select
                      cacheOptions
                      defaultOptions
                      value={this.state.producto}
                      options={this.state.productos}
                      onChange={(option) => this.onChangeSelectProducto(option)}
                      className="select-custom"
                      classNamePrefix="select-react"
                      noOptionsMessage={() => 'No hay producto'}
                      placeholder="Buscar producto..."
                      isDisabled={disabled}
                    />
                  </div>
                </div>
              </div>

              <div className="ui-grid">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                    <label>Cantidad:</label>
                  </div>
                  <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                    <InputText id="cantidad" name="cantidad" type="text" value={this.state.cantidad || ''} maxLength="3" onChange={this.handleInputChange} keyfilter={/\d+/g} disabled={disabled} />
                  </div>
                </div>

                <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                  <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                    <label>Destino:</label>
                  </div>
                  <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                    <Select
                      cacheOptions
                      defaultOptions
                      value={this.state.rutaDestino}
                      options={this.state.rutaDestinos}
                      onChange={(option) => this.onChangeSelectRutaDestino(option)}
                      className="select-custom"
                      classNamePrefix="select-react"
                      noOptionsMessage={() => 'No hay destinos'}
                      placeholder="Buscar destino..."
                      isDisabled={disabled}
                    />
                  </div>
                </div>

                <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
                  <div className="ui-lg-8 ui-g-8 ui-md-8 ui-sm-8 padding-corto">
                    <Button label="Agregar" onClick={this.onClickAgregarDetalle} disabled={disabled} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  createColumns() {
    return [
      <Column key="0" field="numeroDocumento" header="Nro. Doc" />,
      <Column key="1" field="cliente.label" header="Cliente" />,
      <Column key="2" field="producto.label" header="Producto" />,
      <Column key="3" field="cantidad" header="Cantidad" />,
      <Column key="4" field="rutaDestino.label" header="Destino" />,
    ];
  }

  createAdditionalAfterTable = () => {
    return (
      <div>
        <Button label={this.state.id === null ? 'Guardar' : 'Actualizar'} onClick={this.onClickGuardar} />
        <Button label="Cancelar" onClick={this._onClickLimpiar} />
        {this.createPopupGuardar()}
      </div>
    );
  };
}
