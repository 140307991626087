/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from "../AppDispatcher";
import * as AppConstants from "../AppConstants";
import ParroquiaREST from "../api/ParroquiaREST";
import ErrorHandler from "../utilities/ErrorHandler";

class ParroquiaActions {
  listarParroquiasPorCanton = ({ cantonId, page, limit, search }) => {
    ParroquiaREST.listarParroquiasPorCanton({ cantonId, page, limit, search })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.parroquia.GET_LIST,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearParroquia = ({ descripcion, cantonId }) => {
    ParroquiaREST.crearParroquia({ descripcion, cantonId })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.parroquia.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  actualizarParroquia = ({ id, descripcion, cantonId }) => {
    ParroquiaREST.actualizarParroquia({ id, descripcion, cantonId })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.parroquia.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarParroquia = (id) => {
    ParroquiaREST.eliminarParroquia(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.parroquia.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new ParroquiaActions();
