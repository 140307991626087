/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import { getSuperagent, postSuperagent, putSuperagent, deleteSuperagent } from './SuperagentConfig';

class ProvinciaREST {

    listarProvincias = ({page, limit, search}) => {
        const promesa = new Promise((resolve, reject) => {
          getSuperagent(`/api/provincias?page=${page}&limit=${limit}&search=${search}`).end((error, response) => {
                error ? reject(error) : resolve(response.body);
            });
        });
        return promesa;
    };

    crearProvincia = ({descripcion}) => {
      const promesa = new Promise((resolve, reject) => {
        postSuperagent('/api/provincias', {descripcion}).end((error, response) => {
              error ? reject(error) : resolve(response.body);
          });
      });
      return promesa;
    };

    actualizarProvincia = ({id, descripcion}) => {
      const promesa = new Promise((resolve, reject) => {
        putSuperagent(`/api/provincias/${id}`, {descripcion}).end((error, response) => {
              error ? reject(error) : resolve(response.body);
          });
      });
      return promesa;
    };

    eliminarProvincia = (id) => {
      const promesa = new Promise((resolve, reject) => {
        deleteSuperagent(`/api/provincias/${id}`).end((error, response) => {
              error ? reject(error) : resolve(response.body);
          });
      });
      return promesa;
    };

}

export default new ProvinciaREST();