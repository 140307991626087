/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import { getSuperagent, postSuperagent, putSuperagent, deleteSuperagent } from './SuperagentConfig';

class GuiaREST {
  listarGuias = ({ guiaNumero, remitenteId, destinatarioId, rutaOrigenId, rutaDestinoId, fechaInicio, fechaFin, page, limit }) => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent(
        `/api/guias?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&guiaNumero=${guiaNumero}&remitenteId=${remitenteId}&destinatarioId=${destinatarioId}&rutaOrigenId=${rutaOrigenId}&rutaDestinoId=${rutaDestinoId}&page=${page}&limit=${limit}`,
      ).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  crearGuia = (data) => {
    const promesa = new Promise((resolve, reject) => {
      postSuperagent('/api/guias', data).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  actualizarGuia = ({ id, recibeNombre, recibeCedula, recibeFecha, recibeHumedad, recibeTemperatura, observacion, isCredito }) => {
    const promesa = new Promise((resolve, reject) => {
      putSuperagent(`/api/guias/${id}`, {
        recibeNombre,
        recibeCedula,
        recibeFecha,
        recibeHumedad,
        recibeTemperatura,
        observacion,
        isCredito,
      }).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  eliminarGuia = (id) => {
    const promesa = new Promise((resolve, reject) => {
      deleteSuperagent(`/api/guias/${id}`).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };
}

export default new GuiaREST();
