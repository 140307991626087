/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let parroquia = null;
let parroquias = [];

const GET_PARROQUIAS_LIST_EVENT = "GET_PARROQUIAS_LIST_EVENT";
const CREATE_PARROQUIA_EVENT = "CREATE_PARROQUIA_EVENT";
const UPDATE_PARROQUIA_EVENT = "UPDATE_PARROQUIA_EVENT";
const DELETE_PARROQUIA_EVENT = "DELETE_PARROQUIA_EVENT";

class ParroquiaStore extends EventEmitter {
  getParroquia = () => parroquia;

  setParroquia = (_parroquia) => {
    parroquia = _parroquia;
  };

  getParroquias = () => parroquias;

  setParroquias = (_parroquias) => {
    parroquias = _parroquias;
  };

  emitGetParroquias() {
    this.emit(GET_PARROQUIAS_LIST_EVENT);
  }

  addGetParroquiasListener(callback) {
    this.on(GET_PARROQUIAS_LIST_EVENT, callback);
  }

  removeGetParroquiasListener(callback) {
    this.removeListener(GET_PARROQUIAS_LIST_EVENT, callback);
  }

  emitCreateParroquia() {
    this.emit(CREATE_PARROQUIA_EVENT);
  }

  addCreateParroquiaListener(callback) {
    this.on(CREATE_PARROQUIA_EVENT, callback);
  }

  removeCreateParroquiaListener(callback) {
    this.removeListener(CREATE_PARROQUIA_EVENT, callback);
  }

  emitUpdateParroquia() {
    this.emit(UPDATE_PARROQUIA_EVENT);
  }

  addUpdateParroquiaListener(callback) {
    this.on(UPDATE_PARROQUIA_EVENT, callback);
  }

  removeUpdateParroquiaListener(callback) {
    this.removeListener(UPDATE_PARROQUIA_EVENT, callback);
  }

  emitDeleteParroquia() {
    this.emit(DELETE_PARROQUIA_EVENT);
  }

  addDeleteParroquiaListener(callback) {
    this.on(DELETE_PARROQUIA_EVENT, callback);
  }

  removeDeleteParroquiaListener(callback) {
    this.removeListener(DELETE_PARROQUIA_EVENT, callback);
  }
}

const parroquiaStore = new ParroquiaStore();

parroquiaStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.parroquia.GET_LIST:
      parroquiaStore.setParroquias(action.payload);
      parroquiaStore.emitGetParroquias();
      break;
    case AppConstants.parroquia.CREATE:
      parroquiaStore.setParroquia(action.payload);
      parroquiaStore.emitCreateParroquia();
      break;
    case AppConstants.parroquia.UPDATE:
      parroquiaStore.setParroquia(action.payload);
      parroquiaStore.emitUpdateParroquia();
      break;
    case AppConstants.parroquia.DELETE:
      parroquiaStore.setParroquia(action.payload);
      parroquiaStore.emitDeleteParroquia();
      break;
    default:
  }
});

export default parroquiaStore;
