/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 27th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 27th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import React from 'react';
import { Column } from 'primereact/components/column/Column';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Button } from 'primereact/components/button/Button';
import moment from 'moment';
import Select from 'react-select';
import $ from 'jquery';
import _ from 'lodash';
import { getUUID } from '../../../components/functions/globals';
import DataTableCrudComponent from '../../../components/commons/table/DataTableCrudComponent';
import ClienteStore from '../../../flux/stores/ClienteStore';
import ClienteActions from '../../../flux/actions/ClienteActions';
import RutaStore from '../../../flux/stores/RutaStore';
import RutaActions from '../../../flux/actions/RutaActions';
// import ProductoStore from '../../../flux/stores/ProductoStore';
import ProductoActions from '../../../flux/actions/ProductoActions';
import EmpleadoStore from '../../../flux/stores/EmpleadoStore';
import EmpleadoActions from '../../../flux/actions/EmpleadoActions';
import VehiculoStore from '../../../flux/stores/VehiculoStore';
import VehiculoActions from '../../../flux/actions/VehiculoActions';
import HojaRutaStore from '../../../flux/stores/HojaRutaStore';
import HojaRutaActions from '../../../flux/actions/HojaRutaActions';
import CalendarComponent from '../../../components/commons/form/CalendarComponent';
import { URL_SERVER, TOKEN_KEY } from '../../../conf/conf';
import { localeString, FORMAT_DATE_CALENDAR, FORMAT_DATE_MOMENT } from '../../../components/functions/globals';
import ErrorStore from '../../../flux/stores/ErrorStore';

export default class ListarHojaRutaComponent extends DataTableCrudComponent {
  uuidCliente = getUUID();
  uuidEmpleado = getUUID();
  uuidRuta = getUUID();
  uuidVehiculo = getUUID();

  sectionName = 'listar-hruta';
  labelList = 'Lista de Hojas de Ruta';
  labelNew = '';
  labelTotal = 'Hoja de Ruta Detalle';
  classNameContentTable = 'ui-xl-10 ui-lg-12 ui-g-12 ui-md-12 ui-sm-12';
  hasPagination = true;
  hasEdit = true;
  hasSearch = false;
  hasNew = false;

  initialState() {
    return {
      fechaInicio: moment().subtract(7, 'd').toDate(),
      fechaFin: new Date(),
      hrutaDetalle: [],
      chofer: null,
      choferes: [],
      ayudante: null,
      ayudantes: [],
      vehiculo: null,
      vehiculos: [],
      remitente: null,
      remitentes: [],
      rutaOrigen: null,
      rutaOrigenes: [],
      horaSalida: null,

      hrutaId: '',
      hrutaNumero: null,

      isVisibleDialogGuardar: false,

      imprimirPrecio: true,
    };
  }

  handlesBind() {}

  componentDidMount() {
    super.componentDidMount();
    // this.getDataList();
    EmpleadoStore.addGetEmpleadosListener(this._onGetEmpleadosListener, this.uuidEmpleado);
    VehiculoStore.addGetVehiculosListener(this._onGetVehiculosListener, this.uuidVehiculo);
    ClienteStore.addGetClientesListener(this._onGetClientesListener, this.uuidCliente);
    RutaStore.addGetRutasListener(this._onGetRutasOrigenListener, this.uuidRuta);
    // ProductoStore.addGetProductosListener(this._onGetProductosListener, this.uuidProducto);
    HojaRutaStore.addDeleteHojaRutaListener(this._onDeleteHojaRutaListener);
    HojaRutaStore.addGetHojasRutasListener(this._onGetHojasRutasListener);
    ErrorStore.addErrorListener(this._onErrorListerner);

    EmpleadoActions.listarAllEmpleados(this.uuidEmpleado);
    VehiculoActions.listarAllVehiculos(this.uuidVehiculo);
    ClienteActions.listarAllClientes(this.uuidCliente);
    RutaActions.listarAllRutas(this.uuidRuta);
    ProductoActions.listarAllProductos(this.uuidProducto);
    this.onClickListar();
  }

  componentWillUnmount() {
    EmpleadoStore.removeGetEmpleadosListener(this._onGetEmpleadosListener, this.uuidEmpleado);
    VehiculoStore.removeGetVehiculosListener(this._onGetVehiculosListener, this.uuidVehiculo);
    ClienteStore.removeGetClientesListener(this._onGetClientesListener, this.uuidCliente);
    RutaStore.removeGetRutasListener(this._onGetRutasOrigenListener, this.uuidRuta);
    // ProductoStore.removeGetProductosListener(this._onGetProductosListener, this.uuidProducto);
    HojaRutaStore.removeDeleteHojaRutaListener(this._onDeleteHojaRutaListener);
    HojaRutaStore.removeGetHojasRutasListener(this._onGetHojasRutasListener);
    ErrorStore.removeErrorListener(this._onErrorListerner);
  }

  getDataList = () => {
    this.onClickListar();
  };

  _onGetHojasRutasListener = () => {
    const data = HojaRutaStore.getHojasRutas();
    const rows = _.map(data.rows, (hruta) => {
      const value = {
        ...hruta,
        fecha: moment.unix(hruta.fecha).format(FORMAT_DATE_MOMENT),
        choferNombres: `${hruta.chofer.nombres} ${hruta.chofer.apellidos}`,
        ayudanteNombres: `${hruta.chofer.nombres} ${hruta.chofer.apellidos}`,
        vehiculoNombres: `${hruta.vehiculo.descripcion} - ${hruta.vehiculo.placa}`,
        remitenteNombres: `${hruta.remitente.nombres} ${hruta.remitente.apellidos}`,
        hrutaNumero: hruta.hrutaNumero,
        origenNombre: hruta.rutaOrigen.descripcion,
        hrutaId: hruta.id,
      };
      return value;
    });
    this.setState({
      list: rows,
      totalRecords: data.count,
      search: null,
      isLoading: false,
    });
  };

  textQuestionDelete() {
    return '¿Estas seguro de eliminar la Hoja de Ruta Nro: ' + (this.state.rowDelete ? this.state.rowDelete.hrutaNumero : '') + '?';
  }

  sendDeleteOk() {
    HojaRutaActions.eliminarHojaRuta(this.state.rowDelete[this.attrId]);
  }

  _onDeleteHojaRutaListener = () => {
    const data = HojaRutaStore.getHojaRuta();
    if (data && data && data[this.attrId]) {
      window.growlAdmin.show({
        severity: 'success',
        detail: 'Hoja de Ruta eliminada',
      });
      this.onSuccessDelete();
    } else {
      this.setState({ disableButtonPopupDelete: false });
      window.growlAdmin.show({
        severity: 'error',
        detail: 'Error al eliminar la Hoja de Ruta',
      });
    }
  };

  // ----------------------------------- SELECT EMPLEADOS (chofer, ayudante)
  _onGetEmpleadosListener = () => {
    const data = EmpleadoStore.getEmpleados(this.uuidEmpleado);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: `${item.nombres} ${item.apellidos}`,
    }));
    this.setState({ choferes: dataList, ayudantes: dataList });
  };
  onChangeSelectEmpleadoChofer = (chofer) => {
    this.setState({ chofer });
  };
  onChangeSelectEmpleadoAyudante = (ayudante) => {
    this.setState({ ayudante });
  };
  // ----------------------------------- SELECT VEHICULO
  _onGetVehiculosListener = () => {
    const data = VehiculoStore.getVehiculos(this.uuidVehiculo);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: `${item.descripcion} - ${item.placa}`,
    }));
    this.setState({ vehiculos: dataList });
  };
  onChangeSelectVehiculo = (vehiculo) => {
    this.setState({ vehiculo });
  };
  // ----------------------------------- SELECT CLIENTES (remitente)
  _onGetClientesListener = () => {
    const data = ClienteStore.getClientes(this.uuidCliente);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: `${item.nombres} ${item.apellidos}`,
    }));
    this.setState({ remitentes: dataList });
  };
  onChangeSelectClienteRemitente = (remitente) => {
    this.setState({ remitente });
  };
  // ----------------------------------- SELECT ORIGEN
  _onGetRutasOrigenListener = () => {
    const data = RutaStore.getRutas(this.uuidRuta);
    const dataList = data.rows.map((item) => ({
      value: item.id,
      label: `${item.descripcion} - ${item.via}`,
    }));
    this.setState({ rutaOrigenes: dataList });
  };
  onChangeSelectRutaOrigen = (rutaOrigen) => {
    this.setState({ rutaOrigen });
  };

  // handleInputProductoChange = (newValue) => {
  //   const inputValue = newValue.replace(/\W/g, '');
  //   if (!this.state.isSearchProducto && inputValue && inputValue.length > 2) {
  //     this.setState({ isSearchProducto: true }, () => {
  //         ProductoActions.listarProductos({ page: 0, limit: 100, search: inputValue }, this.uuidProducto);
  //     });
  //   }
  //   return inputValue;
  // }

  // onChangeSelectProducto = (producto) => {
  //   this.setState({ producto, valorUnitario: producto.precio });
  // }

  _onErrorListerner = () => {
    window.growlAdmin.show({
      severity: 'error',
      life: 15000,
      detail: 'Problemas en la comunicación con el servidor, comuníquese con el administrador.',
    });
    this.onCloseDialogForm && this.onCloseDialogForm(true);
  };

  // para manejar el handle event de los textinput
  handleInputChange = (e) => {
    let target = e.target;
    let value = $.trim(target.value).length === 0 ? null : target.value;
    let name = target.name;
    this.inputChange = { [name]: value };
    if (name.lastIndexOf('.') !== -1) {
      let nameAttr = name.substring(name.indexOf('.') + 1);
      let nameParent = name.substring(0, name.indexOf('.'));
      value = { ...this.state[nameParent], ...{ [nameAttr]: value } };
    }
    this.setStateChange(name, value, target);
  };
  setStateChange = (name, value, target) => {
    if (name.lastIndexOf('.') !== -1) {
      name = name.substring(0, name.indexOf('.'));
    }
    this.setState({ [name]: value }, () => {});
  };

  // agregar un detalle
  // onClickAgregarDetalle = () =>{
  //   const { producto, cantidad, valorUnitario } = this.state;
  //   const valorTotal = (cantidad && parseInt(cantidad)) * (valorUnitario && parseFloat(valorUnitario))
  //   const data = { id: getUUID(),productoId: producto.value ,cantidad, detalle: producto.label, valorUnitario,  valorTotal}
  //   const dataList = [...this.state.list, data]
  //   this.setState({ list: dataList,
  //     totalRecords: dataList.length,
  //     search: null,
  //     isLoading: false,
  //     producto: null,
  //     cantidad: '',
  //     valorUnitario: ''
  //   });
  //   this.selectProducto && this.selectProducto.focus()
  // }

  // guardar y enviar al servidor
  // onClickGuardar = () =>{
  //   const guiasDetalles= _.map(this.state.list, (item) =>{
  //     const { productoId, cantidad, valorUnitario} = item
  //     return { productoId, cantidad, valorUnitario}
  //   });
  //   const { remitente, destinatario, origen, destino, direccionOrigen, direccionDestino } = this.state
  //   const data = {
  //     remitenteId: remitente.value,
  //     destinatarioId: destinatario.value,
  //     rutaOrigenId: origen.value,
  //     rutaDestinoId: destino.value,
  //     direccionOrigen,
  //     direccionDestino,
  //     guiasDetalles
  //   };
  //   GuiaActions.crearGuia(data)
  // };

  // _onCreateGuiaListener = () =>{
  //   const guia = GuiaStore.getGuia()
  //   // console.log('guia creada', guia.id)
  //   this.setState({guiaId: guia.id})
  //   this._onVisibleDialogGuardar(true)
  // }

  _onClickOkImprimir = (rowData) => {
    // console.log(rowData);
    this.setState({ isLoading: true, isVisibleDialogGuardar: false });

    fetch(`${URL_SERVER}/api/hrutas/${rowData.id}/imprimir?imprimirPrecio=${this.state.imprimirPrecio}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Credentials': '*',
        'Content-type': 'application/pdf',
        'Content-Disposition': 'attachment; filename="downloaded.pdf',
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
      responseType: 'blob',
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
        // const a = document.createElement('a');
        // console.log(url);
        window.open(url);
        // a.style = 'display: none';
        // a.href = url;
        // a.download = `hoja_ruta_${rowData.hrutaNumero}.pdf`;
        // a.target = '_blank';
        // a.click();
        window.URL.revokeObjectURL(url);
        this.setState({ isLoading: false });
      });
  };

  _onVisibleDialogGuardar = (estado) => {
    this.setState({ isVisibleDialogGuardar: estado });
  };

  onClickListar = () => {
    const { fechaInicio, fechaFin, chofer, ayudante, vehiculo, remitente, hrutaNumero, rutaOrigen, page, rows } = this.state;

    //falta el filtro por rutaOrigen, ya me dio pereza
    // console.log(rutaOrigen);

    const fechaInicioUNIX = moment(fechaInicio).startOf('day').unix();
    const fechaFinUNIX = moment(fechaFin).startOf('day').unix();
    const remitenteId = remitente && remitente.value;
    const choferId = chofer && chofer.value;
    const ayudanteId = ayudante && ayudante.value;
    const vehiculoId = vehiculo && vehiculo.value;

    if (fechaInicioUNIX && fechaFinUNIX) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          HojaRutaActions.listarHojasRutas({
            fechaInicio: fechaInicioUNIX,
            fechaFin: fechaFinUNIX,
            remitenteId,
            choferId,
            ayudanteId,
            vehiculoId,
            hrutaNumero,
            page,
            limit: rows,
          });
        },
      );
    } else {
      window.growlAdmin.show({
        severity: 'error',
        life: 15000,
        detail: 'Ingrese la fecha de inicio y la fecha de fin',
      });
    }
  };

  onClickBorrarFiltro = () => {
    this.setState(
      {
        fechaInicio: moment().subtract(7, 'd').toDate(),
        fechaFin: new Date(),
        hrutaDetalle: [],
        chofer: null,
        ayudante: null,
        vehiculo: null,
        remitente: null,
        rutaOrigen: null,
        horaSalida: null,
        hrutaId: '',
        hrutaNumero: null,
        isVisibleDialogGuardar: false,
        imprimirPrecio: true,
      },
      () => {
        this.onClickListar();
      },
    );
  };

  _onClickEditGuia = (rowData) => {
    HojaRutaActions.setHojaRuta(rowData);
    this.props.history.push('/admin/crear-hruta');
  };

  createColumnAction() {
    let actionTemplate = (rowData) => {
      return (
        <div>
          <Button type="button" icon="fa fa-print" title="Imprimir" onClick={(e) => this._onClickOkImprimir(rowData)} style={{ marginRight: '3px' }}></Button>
          <Button type="button" icon="fa fa-pencil-alt" title="Editar" className="ui-button-success" onClick={(e) => this._onClickEditGuia(rowData)} style={{ marginRight: '3px' }}></Button>
          {this.hasDelete ? <Button type="button" icon="fa fa-trash" title="Eliminar" className="ui-button-danger" onClick={(e) => this._onShowHideDialogDelete(true, rowData)}></Button> : ''}
        </div>
      );
    };
    return (
      <Column
        body={actionTemplate}
        style={{
          ...{ textAlign: 'center', width: '160px' },
          ...this.styleColumnAction,
        }}
      />
    );
  }

  createFilter() {
    return (
      <div>
        <div
          className="ui-fluid"
          style={{
            border: ' 1px solid #D3D3D3',
            borderRadius: '5px',
            margin: '10px',
          }}
        >
          <div className="ui-grid ui-fluid">
            <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Nro. de Hoja de Ruta:</label>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <InputText id="hrutaNumero" name="hrutaNumero" type="text" value={this.state.hrutaNumero || ''} maxLength="50" keyfilter={/(\d)+/g} onChange={this.handleInputChange} />
              </div>
            </div>
            <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Fecha Inicio:</label>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <CalendarComponent
                  value={this.state.fechaInicio}
                  onChange={(e) => this.setState({ fechaInicio: e.value })}
                  showIcon={true}
                  locale={localeString('es')}
                  monthNavigator={true}
                  yearNavigator={true}
                  icon="fa fa-calendar"
                  yearRange={`2010:${moment().get('year')}`}
                  dateFormat={FORMAT_DATE_CALENDAR}
                  placeholder={FORMAT_DATE_MOMENT}
                  readOnlyInput
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12 padding-corto">
              <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                <label>Fecha Fin:</label>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <CalendarComponent
                  value={this.state.fechaFin}
                  onChange={(e) => this.setState({ fechaFin: e.value })}
                  showIcon={true}
                  locale={localeString('es')}
                  monthNavigator={true}
                  yearNavigator={true}
                  icon="fa fa-calendar"
                  yearRange={`2010:${moment().get('year')}`}
                  dateFormat={FORMAT_DATE_CALENDAR}
                  placeholder={FORMAT_DATE_MOMENT}
                  readOnlyInput
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="ui-grid">
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Chofer:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <Select
                    cacheOptions
                    defaultOptions
                    value={this.state.chofer}
                    options={this.state.choferes}
                    onChange={(option) => this.onChangeSelectEmpleadoChofer(option)}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay choferes'}
                    placeholder="Buscar chofer..."
                  />
                </div>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Ayudante:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <Select
                    cacheOptions
                    defaultOptions
                    value={this.state.ayudante}
                    options={this.state.ayudantes}
                    onChange={(option) => this.onChangeSelectEmpleadoAyudante(option)}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay ayudantes'}
                    placeholder="Buscar ayudante..."
                  />
                </div>
              </div>
            </div>

            <div className="ui-grid">
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Vehículo:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <Select
                    cacheOptions
                    defaultOptions
                    value={this.state.vehiculo}
                    options={this.state.vehiculos}
                    onChange={(option) => this.onChangeSelectVehiculo(option)}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay vehículos'}
                    placeholder="Buscar vehículo..."
                  />
                </div>
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <div className="ui-lg-3 ui-g-3 ui-md-3 ui-sm-12 padding-corto">
                  <label>Remitente:</label>
                </div>
                <div className="ui-lg-9 ui-g-9 ui-md-9 ui-sm-12 padding-corto">
                  <Select
                    cacheOptions
                    defaultOptions
                    value={this.state.remitente}
                    options={this.state.remitentes}
                    onChange={(option) => this.onChangeSelectClienteRemitente(option)}
                    className="select-custom"
                    classNamePrefix="select-react"
                    noOptionsMessage={() => 'No hay remitentes'}
                    placeholder="Buscar remitente..."
                  />
                </div>
              </div>
            </div>

            <div className="ui-lg-4 ui-g-4 ui-md-4 ui-sm-12">
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <Button label="Buscar" onClick={this.onClickListar} />
              </div>
              <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12 padding-corto">
                <Button label="Borrar filtro" onClick={this.onClickBorrarFiltro} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  createColumns() {
    return [
      <Column key="0" style={{ width: '80px' }} field="hrutaNumero" header="Nro Hoja" />,
      <Column key="1" style={{ width: '100px' }} field="fecha" header="Fecha" />,
      <Column key="2" field="remitenteNombres" header="Remitente" />,
      <Column key="3" field="choferNombres" header="Chofer" />,
      <Column key="4" field="vehiculoNombres" header="Vehículo" />,
      <Column key="5" field="origenNombre" header="Origen" />,
      <Column key="6" field="observacion" header="Observación" />,
    ];
  }
}
