/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from "../AppDispatcher";
import * as AppConstants from "../AppConstants";
import TipoEmpleadoREST from "../api/TipoEmpleadoREST";
import ErrorHandler from "../utilities/ErrorHandler";

class TipoEmpleadoActions {
  listarTiposEmpleados = ({ page, limit, search }, componentUID) => {
    TipoEmpleadoREST.listarTiposEmpleados({ page, limit, search })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.tipoempleado.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  listarAllTiposEmpleados = (componentUID) => {
    TipoEmpleadoREST.listarAllTiposEmpleados()
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.tipoempleado.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearTipoEmpleado = ({ descripcion }) => {
    TipoEmpleadoREST.crearTipoEmpleado({ descripcion })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.tipoempleado.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  actualizarTipoEmpleado = ({ id, descripcion }) => {
    TipoEmpleadoREST.actualizarTipoEmpleado({ id, descripcion })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.tipoempleado.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarTipoEmpleado = (id) => {
    TipoEmpleadoREST.eliminarTipoEmpleado(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.tipoempleado.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new TipoEmpleadoActions();
