/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import EventEmitter from "events";
import AppDispatcher from "../AppDispatcher";
import AppConstants from "../AppConstants";

let vehiculo = null;
const vehiculos = {};

const GET_VEHICULOS_LIST_EVENT = "GET_VEHICULOS_LIST_EVENT";
const CREATE_VEHICULO_EVENT = "CREATE_VEHICULO_EVENT";
const UPDATE_VEHICULO_EVENT = "UPDATE_VEHICULO_EVENT";
const DELETE_VEHICULO_EVENT = "DELETE_VEHICULO_EVENT";

class VehiculoStore extends EventEmitter {
  getVehiculo = () => vehiculo;

  setVehiculo = (_vehiculo) => {
    vehiculo = _vehiculo;
  };

  getVehiculos = (componentUID) => {
    const datos = vehiculos[componentUID];
    delete vehiculos[componentUID];
    return datos;
  };

  setVehiculos = (componentUID, _vehiculos) => {
    vehiculos[componentUID] = _vehiculos;
  };

  emitGetVehiculos(componentUID) {
    this.emit(`${GET_VEHICULOS_LIST_EVENT}_${componentUID}`);
  }

  addGetVehiculosListener(callback, componentUID) {
    this.on(`${GET_VEHICULOS_LIST_EVENT}_${componentUID}`, callback);
  }

  removeGetVehiculosListener(callback, componentUID) {
    this.removeListener(
      `${GET_VEHICULOS_LIST_EVENT}_${componentUID}`,
      callback
    );
  }

  emitCreateVehiculo() {
    this.emit(CREATE_VEHICULO_EVENT);
  }

  addCreateVehiculoListener(callback) {
    this.on(CREATE_VEHICULO_EVENT, callback);
  }

  removeCreateVehiculoListener(callback) {
    this.removeListener(CREATE_VEHICULO_EVENT, callback);
  }

  emitUpdateVehiculo() {
    this.emit(UPDATE_VEHICULO_EVENT);
  }

  addUpdateVehiculoListener(callback) {
    this.on(UPDATE_VEHICULO_EVENT, callback);
  }

  removeUpdateVehiculoListener(callback) {
    this.removeListener(UPDATE_VEHICULO_EVENT, callback);
  }

  emitDeleteVehiculo() {
    this.emit(DELETE_VEHICULO_EVENT);
  }

  addDeleteVehiculoListener(callback) {
    this.on(DELETE_VEHICULO_EVENT, callback);
  }

  removeDeleteVehiculoListener(callback) {
    this.removeListener(DELETE_VEHICULO_EVENT, callback);
  }
}

const vehiculoStore = new VehiculoStore();

vehiculoStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case AppConstants.vehiculo.GET_LIST:
      const { componentUID } = action.payload;
      if (componentUID) {
        vehiculoStore.setVehiculos(componentUID, action.payload.data);
        vehiculoStore.emitGetVehiculos(componentUID);
      }
      break;
    case AppConstants.vehiculo.CREATE:
      vehiculoStore.setVehiculo(action.payload);
      vehiculoStore.emitCreateVehiculo();
      break;
    case AppConstants.vehiculo.UPDATE:
      vehiculoStore.setVehiculo(action.payload);
      vehiculoStore.emitUpdateVehiculo();
      break;
    case AppConstants.vehiculo.DELETE:
      vehiculoStore.setVehiculo(action.payload);
      vehiculoStore.emitDeleteVehiculo();
      break;
    default:
  }
});

export default vehiculoStore;
