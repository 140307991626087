/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import {
  getSuperagent,
  postSuperagent,
  putSuperagent,
  deleteSuperagent,
} from "./SuperagentConfig";

class TipoClienteREST {
  listarTiposClientes = ({ page, limit, search }) => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent(
        `/api/tiposclientes?page=${page}&limit=${limit}&search=${search}`
      ).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  listarAllTiposClientes = () => {
    const promesa = new Promise((resolve, reject) => {
      getSuperagent("/api/tiposclientes").end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };

  crearProvincia = ({ descripcion }) => {
    const promesa = new Promise((resolve, reject) => {
      postSuperagent("/api/tiposclientes", { descripcion }).end(
        (error, response) => {
          error ? reject(error) : resolve(response.body);
        }
      );
    });
    return promesa;
  };

  actualizarProvincia = ({ id, descripcion }) => {
    const promesa = new Promise((resolve, reject) => {
      putSuperagent(`/api/tiposclientes/${id}`, { descripcion }).end(
        (error, response) => {
          error ? reject(error) : resolve(response.body);
        }
      );
    });
    return promesa;
  };

  eliminarProvincia = (id) => {
    const promesa = new Promise((resolve, reject) => {
      deleteSuperagent(`/api/tiposclientes/${id}`).end((error, response) => {
        error ? reject(error) : resolve(response.body);
      });
    });
    return promesa;
  };
}

export default new TipoClienteREST();
