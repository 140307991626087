import React from "react";
import { Dialog } from "primereact/components/dialog/Dialog";
import { Button } from "primereact/components/button/Button";
import FormCrudComponent from "./FormCrudComponent";

export default class FormDialogComponent extends FormCrudComponent {
  titleDialog = "";

  width = "500px";

  _onCancelReturnClick(isReloadList) {
    this.props.onCloseDialogForm(isReloadList);
  }

  createButtonAction() {
    return (
      <div className="ui-g" style={{ marginTop: "10px" }}>
        <div className="ui-lg-12 ui-g-12 ui-md-12 ui-sm-12">
          <div className="ui-g">
            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12">
              <Button
                className="ui-button-warning"
                label="CANCELAR"
                onClick={() => this._onCancelReturnClick()}
                disabled={false}
              />
            </div>
            <div className="ui-lg-6 ui-g-6 ui-md-6 ui-sm-12">
              <Button
                label="GUARDAR"
                onClick={this._onSaveClick}
                disabled={this.state.disableButton}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Crear componente html
   * @return {object} Componente html
   */
  render() {
    return (
      <Dialog
        header={this.state.titleDialog}
        visible
        style={{ width: this.width }}
        modal
        onHide={() => this.props.onCloseDialogForm()}
        contentStyle={{ maxHeight: "500px", overflow: "auto" }}
      >
        <div style={{ position: "relative" }}>
          <div className="ui-g ui-fluid">
            <div className="ui-xl-12 ui-lg-12 ui-g-12 ui-md-12 ui-sm-12 form-group">
              {this.createForm && this.createForm()}
              {this.createButtonAction()}
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
