import React from "react";
import ReactDOM from "react-dom";
import { Calendar } from "primereact/components/calendar/Calendar";
import { InputText } from "primereact/components/inputtext/InputText";

export default class CalendarComponent extends Calendar {
  /**
   * Crear inputText
   */
  renderInputText() {
    if (!this.props.inline) {
      return (
        <InputText
          // eslint-disable-next-line react/no-find-dom-node
          ref={(el) => (this.inputfield = ReactDOM.findDOMNode(el))}
          defaultValue={this.getInputFieldValue(this.props.value)}
          type="text"
          required={this.props.required}
          onFocus={this.onInputFocus}
          onKeyDown={this.onInputKeydown}
          onClick={this.onInputClick}
          onBlur={this.onInputBlur}
          onInput={this.onUserInput}
          onMouseDown={this.props.onMouseDown}
          onKeyUp={this.props.onKeyUp}
          onKeyPress={this.props.onKeyPress}
          onContextMenu={this.props.onContextMenu}
          style={this.props.inputStyle}
          className={this.props.inputClassName}
          readOnly={this.props.readOnlyInput}
          placeholder={this.props.placeholder || ""}
          disabled={this.props.disabled}
          tabIndex={this.props.tabindex}
          maxLength={this.props.maxLength || 255}
        />
      );
    }
    return null;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.value !== this.props.value) {
      const date = this.getMonthYearDate(nextProps.value);
      const month = date.getMonth();
      const year = date.getFullYear();

      if (
        month !== this.state.currentMonth ||
        year !== this.state.currentYear ||
        this.props.minDate !== nextProps.minDate ||
        this.props.maxDate !== nextProps.maxDate
      ) {
        this.setState({
          currentMonth: month,
          currentYear: year,
          dates: this.createMonth(
            month,
            year,
            nextProps.minDate,
            nextProps.maxDate
          ),
        });
      }
    }
    if (
      this.props.minDate !== nextProps.minDate ||
      this.props.maxDate !== nextProps.maxDate
    ) {
      const date = this.getMonthYearDate(nextProps.value);
      const month = date.getMonth();
      const year = date.getFullYear();
      this.setState({
        dates: this.createMonth(
          month,
          year,
          nextProps.minDate,
          nextProps.maxDate
        ),
      });
    }
  };
}
