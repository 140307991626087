/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/prefer-default-export */
/**
 * @file Componente de Ingreso
 * @author Wilson Guiñanzaca <wilson_g18@hotmail.com>
 * 13-Mar-2019
 */

import React from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from 'primereact/components/button/Button';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Message } from 'primereact/components/message/Message';
import { Growl } from 'primereact/components/growl/Growl';
import { ProgressSpinner } from 'primereact/components/progressspinner/ProgressSpinner';
import AuthenticationActions from '../../flux/actions/AuthenticationActions';
import AuthenticationStore from '../../flux/stores/AuthenticationStore';
import FormComponent from '../../components/commons/form/FormComponent';

export class LoginComponent extends FormComponent {
  /**
   * Inicializar state con atributos
   */
  setInitialState() {
    return {
      username: '',
      password: '',
      disableButton: false,
      validation: {
        username: {
          required: true,
          msg: 'Ingresar el usuario',
        },
        password: {
          required: true,
          msg: 'Ingresar la contraseña',
        },
      },
    };
  }

  /**
   * Agregar bind de eventos
   */
  handlesBind() {
    this._onLoginClick = this._onLoginClick.bind(this);
    this._onLoginListener = this._onLoginListener.bind(this);
  }

  componentDidMount() {
    AuthenticationStore.addLoginListener(this._onLoginListener);
  }

  componentWillUnmount() {
    AuthenticationStore.removeLoginListener(this._onLoginListener);
  }

  _onLoginClick(e) {
    e.preventDefault();
    if (this.isValid(true)) {
      this.setState({ disableButton: true }, () => {
        AuthenticationActions.login(this.getAttributes());
      });
    } else {
      this.growl.show({
        severity: 'warn',
        detail: 'Ingresar el usuario y contraseña',
      });
    }
  }

  _onLoginListener() {
    const token = AuthenticationStore.getToken();
    this.setState({ disableButton: false });
    if (!token) {
      this.growl.show({
        severity: 'error',
        detail: 'Usuario o contraseña incorrecta',
      });
    } else {
      window.location = '/';
    }
  }

  /**
   * Crear componente html
   * @return {object} Componente html
   */
  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { error, redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }
    return (
      <div className="ui-g ui-fluid">
        <Growl ref={(el) => (this.growl = el)} />
        <div className="ui-sm-12 ui-md-8 ui-g-4 ui-lg-4 margin-auto ">
          <div className="card card-w-title" style={{ border: '1px solid #c1c1c1' }}>
            <div className="cab-login centrar">
              <img alt="" src="/assets/layout/images/logo.png" className="img-fluid" style={{ width: '200px' }} />
            </div>
            <form className="ui-g form-group" onSubmit={this._onLoginClick}>
              <div className="ui-lg-12 ui-g-12 ui-md-12 ui-sm-12">
                <div className="ui-fluid">
                  <label htmlFor="inputUsuario">Usuario</label>
                </div>
                <div className="ui-fluid">
                  <InputText
                    id="inputUsuario"
                    name="username"
                    className={error.username ? 'ui-state-error' : ''}
                    type="text"
                    value={this.state.username || ''}
                    maxLength="128"
                    onChange={this.handleInputChange}
                  />
                  {error.username ? <Message severity="error" text={error.username} /> : ''}
                </div>
              </div>
              <div className="ui-g-12">
                <div className="ui-fluid">
                  <label htmlFor="inputPassword">Contraseña</label>
                </div>
                <div className="ui-fluid">
                  <InputText id="inputPassword" name="password" type="password" value={this.state.password || ''} maxLength="128" onChange={this.handleInputChange} />
                  {error.password ? <Message severity="error" text={error.password} /> : ''}
                </div>
              </div>
              <div className="ui-g-12 ui-md-6 margin-auto">
                <div>
                  <Button
                    className="ui-button ui-widget"
                    label="INGRESAR"
                    type="submit"
                    disabled={!(this.state.username && this.state.username.length > 0 && this.state.password && this.state.password.length > 0) || this.state.disableButton}
                  />
                </div>
                <div style={{ textAlign: 'center', marginTop: '5px' }}>
                  {this.state.disableButton ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="6" animationDuration=".5s" /> : ''}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
