/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from "../AppDispatcher";
import * as AppConstants from "../AppConstants";
import VehiculoREST from "../api/VehiculoREST";
import ErrorHandler from "../utilities/ErrorHandler";

class VehiculoActions {
  listarVehiculos = ({ page, limit, search }, componentUID) => {
    VehiculoREST.listarVehiculos({ page, limit, search })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.vehiculo.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  listarAllVehiculos = (componentUID) => {
    VehiculoREST.listarAllVehiculos()
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.vehiculo.GET_LIST,
          payload: { data: respuesta.data, componentUID },
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearVehiculo = ({ descripcion, placa }) => {
    VehiculoREST.crearVehiculo({ descripcion, placa })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.vehiculo.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  actualizarVehiculo = ({ id, descripcion, placa }) => {
    VehiculoREST.actualizarVehiculo({ id, descripcion, placa })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.vehiculo.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarVehiculo = (id) => {
    VehiculoREST.eliminarVehiculo(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.vehiculo.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new VehiculoActions();
