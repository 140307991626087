/**
 * @author Cristian Miño <cristian.mino@icloud.com>
 * @fileCreated 26th May 2020
 * -----
 * @modifiedBy Cristian Miño <cristian.mino@icloud.com>
 * @lastModified 26th May 2020
 * -----
 * Copyright 2018 - 2020 ONIM
 */
import AppDispatcher from "../AppDispatcher";
import * as AppConstants from "../AppConstants";
import TipoClienteREST from "../api/TipoClienteREST";
import ErrorHandler from "../utilities/ErrorHandler";

class TipoClienteActions {
  listarTiposClientes = ({ page, limit, search }) => {
    TipoClienteREST.listarTiposClientes({ page, limit, search })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.tipocliente.GET_LIST,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  listarAllTiposClientes = () => {
    TipoClienteREST.listarAllTiposClientes()
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.tipocliente.GET_LIST,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  crearTipoCliente = ({ descripcion }) => {
    TipoClienteREST.crearTipoCliente({ descripcion })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.tipocliente.CREATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  actualizarTipoCliente = ({ id, descripcion }) => {
    TipoClienteREST.actualizarTipoCliente({ id, descripcion })
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.tipocliente.UPDATE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  eliminarTipoCliente = (id) => {
    TipoClienteREST.eliminarTipoCliente(id)
      .then((respuesta) => {
        AppDispatcher.dispatch({
          actionType: AppConstants.tipocliente.DELETE,
          payload: respuesta.data,
        });
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };
}
export default new TipoClienteActions();
